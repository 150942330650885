.aboutSection {
  max-width: 1440px;
  margin: auto;
  padding: 0 61px 72px 57px;
}
.title {
  font-family: var(--text-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(242, 241, 237, 0.7);
  margin-bottom: 24px;
}
.firstContainer,
.secondContainer {
  display: flex;
  align-items: center;
}
.firstContainer {
  column-gap: 30px;
}
.secondContainer {
  column-gap: 116px;
  flex-direction: row-reverse;
  align-items: flex-start;
}
.aboutSection p {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  color: #f2f1ed;
}
.firstParagragh {
  font-size: 48px;
  line-height: 56px;
  max-width: 759px;
}
.arrow {
  width: 132.35px;
  height: 145.21px;
  margin-top: 20px;
}
.secondContainer .secondParagraph {
  font-size: 20px;
  line-height: 27px;
  white-space: pre-wrap;
  overflow: auto;
  max-width: 427px;
  color: rgba(242, 241, 237, 0.7);
  padding-left: 10px;
}
.secondParagraph::-webkit-scrollbar {
  display: none;
}
.secondParagraph a {
  color: rgba(242, 241, 237, 0.7);
}
.aboutImg {
  border-radius: 62px 132px 76px;
  width: 648px;
  height: 264px;
  margin-top: 95px;
}
.RightContainer {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: flex-start;
}
.down {
  height: 153px;
}
@media (max-width: 1245px) {
  .firstParagragh {
    font-size: 28px;
    line-height: 36px;
    max-width: 509px;
  }
  .arrow {
    width: 82.35px;
    height: 95.21px;
    margin-top: 20px;
  }
  .secondContainer {
    column-gap: 56px;
  }
  .down {
    height: 103px;
  }
}
@media (max-width: 1000px) {
  .aboutSection {
    padding: 0 21px 72px 22px;
  }
  .firstParagragh {
    font-size: 20px;
    line-height: 28px;
    max-width: 409px;
  }
  .arrow {
    width: 62.35px;
    height: 75.21px;
    margin-top: 10px;
  }

  .secondContainer .secondParagraph {
    font-size: 16px;
    line-height: 20px;
    max-width: 417px;
  }
}
@media (max-width: 650px) {
  .firstParagragh {
    font-size: 18px;
    line-height: 24px;
    max-width: 309px;
  }
  .arrow {
    display: none;
  }

  .secondContainer .secondParagraph {
    font-size: 11px;
    line-height: 16px;
    max-width: 367px;
  }
  .aboutImg {
    border-radius: 62px 132px 76px;
    width: 188px;
    height: 134px;
    margin-top: 30px;
  }
  .secondContainer {
    column-gap: 16px;
    margin-top: 20px;
  }
  .down {
    height: 50px;
  }
}
