.tryObws {
  width: 100%;
  background-color: var(--black);
  border-radius: 0px 0px 0px 219.5px;
}
.authenticated {
  padding: 154px 20px 242px;
  margin-bottom: 311px;
}
.authenticated > .tryObwsContainer {
  padding: 0 155px;
  position: relative;
}
.notAuthenticated {
  padding: 30px 20px 151px;
  margin-bottom: 28px;
}
.notAuthenticated > .tryObwsContainer {
  max-width: 1192px;
  margin: auto;
}

.tryObwsContainer {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}
@media (max-width: 1400px) {
  .authenticated > .tryObwsContainer {
    position: initial;
    padding: 0 100px;
  }
  .tryObws {
    position: relative;
  }
}
@media (max-width: 1200px) {
  .notAuthenticated > .tryObwsContainer {
    max-width: 600px;
  }
}
@media (max-width: 1162px) {
  .authenticated {
    padding: 56px 20px 58px;
    margin-bottom: 28px;
  }
}
@media (max-width: 710px) {
  .tryObws {
    border-radius: 0px 0px 0px 111px;
  }
}
@media (max-width: 570px) {
  .authenticated > .tryObwsContainer {
    padding: 0 20px;
  }
  .notAuthenticated > .tryObwsContainer {
    padding: 0;
  }
}
