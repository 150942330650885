.container {
  display: flex;
  row-gap: 20px;
  column-gap: 23.67px;
  flex-wrap: wrap;
  margin: 59px 51.3px 38.21px 52px;
  max-height: 500px;
  overflow: auto;
}
.category {
  width: 238.18px;
  height: 139.06px;
  background: linear-gradient(0deg, #f2f1ed, #f2f1ed);
  border-radius: 22.1907px;
  text-align: center;
  cursor: pointer;
}
.name {
  font-family: var(--title-font-family);
  font-size: 21.525px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--black);
  font-weight: 400;
  padding-top: 8.88px;
}
.active {
  border: 4px solid #1977f3;
}
