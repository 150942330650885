.headerContainer {
  width: 100%;
  margin-bottom: 59px;
  display: flex;
  padding: 106px 0 104px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.whiteBg {
  position: absolute;
  top: 0;
  left: -10%;
  width: 120%;
  height: 98%;
  border-bottom-left-radius: 50% 45%;
  border-bottom-right-radius: 50% 45%;
  background-color: var(--lighter-gray-color);
}

.profileAvatar {
  width: 318px;
  height: 318px;
  border-radius: 50%;
  background-color: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 98px;
  text-transform: uppercase;
  font-family: var(--title-font-family);
  margin-right: 46px;
  position: relative;
}
.userName {
  font-family: var(--title-font-family);
  font-size: 36.99px;
  line-height: 46px;
  position: relative;
  text-transform: capitalize;
}
@media (max-width: 650px) {
  .headerContainer {
    padding: 60px 0 42px;
    justify-content: flex-start;
    margin-left: 31px;
  }

  .whiteBg {
    display: none;
  }
  .profileAvatar {
    width: 71px;
    height: 71px;
    margin-right: 12px;
    font-size: 28px;
  }
  .userName {
    font-size: 28px;
  }
}
