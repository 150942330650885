.requestsSection {
  display: flex;
  flex-direction: column;
}
.titleWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 23px 23px 26px;
}
.viewDeclined {
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  background-color: var(--black);
  border-radius: 10px;
  padding: 16px;
  width: 149px;
  height: 42px;
  display: flex;
  align-items: center;
  align-items: center;
}
.requestsSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 23px 30px 26px;
  background: #f3f2ee;
  border-radius: 34px;
}
.infoCircleWrapper {
  display: flex;
  align-items: center;
}
.infoCircle {
  width: 31px;
  height: 31px;
  margin-right: 26px;
}
.summary {
  font-family: var(--title-font-family);
  font-size: 28px;
  line-height: 46px;
  color: var(--black);
}
.reviewNowBtn {
  width: 149px;
  height: 42px;
  background: #1977f3;
  border-radius: 10px;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #f9f8f4;
  cursor: pointer;
  border: none;
}
.loader::after {
  background-color: #f3f2ee;
}
