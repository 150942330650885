.header {
  padding: 85px 68px 0 57px;
}
.firstRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchInp {
  width: 523px;
  position: relative;
  display: flex;
  z-index: 3;
}
.searchInp input {
  width: 100%;
  height: 56px;
  border: 1.5px solid rgba(10, 10, 10, 0.25);
  border-radius: 200px;
  font-family: var(--title-font-family);
  font-weight: 400;
  color: #020202;
  padding-left: 56px;
  font-size: 20px;
}
.searchInp input:focus {
  border: 1.5px solid #0a0a0a;
}
.toggleContainer {
  margin: 72px 0 61px;
  display: inline-block;
  border-bottom: solid 2px #c4c4c4;
}
.labelBtn {
  font-family: var(--title-font-family);
  font-size: 28px;
  line-height: 33px;
  font-weight: 400;
  outline: none;
  background-color: transparent;
  padding-bottom: 8px;
  border: none;
  cursor: pointer;
}
.labelBtn:not(:last-child) {
  margin-right: 44px;
}
.notActive {
  color: rgba(10, 10, 10, 0.4);
}
.active {
  border-bottom: solid 2px var(--black);
  color: var(--black);
}
.labelBtn:hover {
  border-bottom: solid 2px var(--black);
  color: var(--black);
}
