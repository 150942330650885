.header {
  font-size: 48px;
  line-height: 52px;
  color: var(--black);
  font-weight: 400;
  font-family: var(--title-font-family);
  width: 100%;
  padding: 10px 0 57px;
}
.headingDescription {
  font-family: var(--text-font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 48px;
  display: block;
}
.businessHeaderWrapper {
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.businessHeaderWrapper .businessHeader {
  width: 340px;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-weight: normal;
  font-size: 48px;
  line-height: 52px;
  margin-bottom: 16px;
}
.businessHeaderWrapper .businessDescription {
  width: 650px;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}
.firstList,
.secondList {
  margin-left: 28px;
  font-family: var(--text-font-family);
  color: var(--black);
}
.firstList li:not(:last-child) {
  margin-bottom: 6px;
}
.firstList {
  margin-top: 10px;
}
.secondList {
  margin-top: 8px;
}

@media (max-width: 400px) {
  .header {
    font-size: 36px;
  }
  .continueBtn {
    width: 100%;
    height: 56px;
    font-size: 12px;
  }
}
