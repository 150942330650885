.test {
  width: 100%;
  height: 56px;
  outline: none;
  border: 1.5px solid rgba(10, 10, 10, 0.25);
  border-radius: 200px;
  padding-left: 32px;
  padding-bottom: 4px;
  font-size: 24px;
  font-family: var(--title-font-family);
  font-weight: 400;
  color: rgba(2, 2, 2, 0.4);
}
.inpContainer {
  max-width: 1143px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
}
.searchIcon {
  width: 28px;
  position: absolute;
  right: 26px;
  cursor: pointer;
}
.adminSearchIcon {
  width: 23px;
  position: absolute;
  left: 22px;
  top: 18px;
  height: initial;
}
.test::placeholder {
  font-size: 24px;
  font-family: var(--title-font-family);
  font-weight: 400;
  color: rgba(2, 2, 2, 0.4);
}
.searchBarTooltip {
  top: 55px;
  left: 190px;
}
