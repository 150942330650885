.loginBtn {
  text-decoration: none;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-family: var(--text-font-family);
  font-weight: 400;
  text-transform: uppercase;
}
.container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}
.regWidth {
  width: 255px;
}
.awardsWidth {
  width: 335px;
}
.awardsWidth > .brdr {
  background-color: #f2f1ed;
}
.brdr {
  width: 2px;
  height: 15px;
  background-color: var(--black);
}
.regColor {
  color: #000000;
}
.landingColor {
  color: #f2f1ed;
}
.regColor:hover {
  color: #010302;
}
@media (max-width: 998px) {
  .loginBtn {
    padding-left: 16px;
  }
  .landingColor {
    color: rgba(242, 241, 237, 0.7);
  }
  .regColor {
    color: rgba(1, 3, 2, 0.7);
  }
  .container {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 24px;
  }
  .brdr {
    display: none;
  }
}
