.tryObws {
  width: 100%;
  background-color: var(--black);
  border-radius: 0px 0px 0px 168.741px;
}
.authenticated {
  padding: 118px 0 186px;
  margin-bottom: 211px;
}
.authenticated > .tryObwsContainer {
  padding: 0 155px;
  position: relative;
}
.notAuthenticated {
  padding: 30px 20px 151px;
  margin-bottom: 28px;
}
.notAuthenticated > .tryObwsContainer {
  max-width: 1192px;
  margin: auto;
}

.tryObwsContainer {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}
