.text {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #f2f1ed;
}
span.announcement,
a.announcement {
  color: var(--black);
}
.container {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.container img {
  width: 48px;
}
.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  color: #f2f1ed;
  display: block;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.placeholder {
  min-width: 82px;
  min-height: 5px;
}
@media (max-width: 800px) {
  .day {
    font-size: 8px;
    line-height: 12px;
  }
  .text {
    font-size: 10px;
    line-height: 16px;
  }
  .container img {
    width: 15px;
  }
  .container {
    column-gap: 6px;
  }
  span.announcement,
  a.announcement {
    font-size: 8.2px;
    line-height: 12px;
  }
}
