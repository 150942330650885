.list {
  list-style: none;
}
.list li {
  font-family: "Arial";
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  padding: 28px 0 24px;
  cursor: pointer;
}
.delete {
  color: #bf3e35 !important;
}
.brdr {
  width: 100%;
  height: 1px;
  background-color: #f2f1ed;
}
.disabled {
  color: gray !important;
  cursor: not-allowed !important;
}
