.registerProfilePicture {
  width: 100%;
  text-align: center;
}
.header {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: var(--black);
  padding: 68px 0 28px;
}
.inpContainer {
  width: 409px;
  height: 315px;
  margin: auto;
}
.inpLabel {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #0a0a0a;
  padding-bottom: 48px;
}
.changeImg {
  display: none;
}
.inpContainer input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 315px;
  height: 315px;
  border-radius: 157.5px;
  cursor: pointer;
}
.inpContainer label {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 315px;
  height: 315px;
  border: 1px dashed transparent;
  background: linear-gradient(#f9f8f4, #f9f8f4) padding-box padding-box,
    repeating-conic-gradient(
        var(--c) 0 calc(360deg / var(--n) - var(--d)),
        transparent 0 calc(360deg / var(--n))
      )
      border-box;
  --d: 4deg;
  --n: 30;
  --c: #000;
  border-radius: 157.5px;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  color: #0a0a0a;
  cursor: pointer;
}
.invalid label {
  background: linear-gradient(#f9f8f4, #f9f8f4) padding-box padding-box,
    repeating-conic-gradient(
        var(--c) 0 calc(360deg / var(--n) - var(--d)),
        transparent 0 calc(360deg / var(--n))
      )
      border-box;
  --d: 4deg;
  --n: 30;
  --c: #ee4655;
  color: #ee4655;
}
.profileImage {
  margin: auto;
  width: 315px;
  height: 315px;
  border-radius: 157.5px;
  display: block;
}
.btns {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 315px;
  margin-top: 62px;
}
.uploadBtn,
.nextBtn {
  width: 313px;
  height: 60px;
  border-radius: 50px;
  font-size: 12px;
  line-height: 16.92px;
}
.uploadBtn {
  border: 1px solid var(--black);
  outline: none;
  background-color: var(--lighter-gray-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--text-font-family);
}
.nextBtn {
  margin-top: 20px;
}
@media (max-width: 500px) {
  .header {
    font-size: 27px;
    line-height: 32px;
  }
  .inpLabel {
    font-size: 14px;
    line-height: 16px;
  }
  .nextBtn {
    max-width: 100%;
    height: 52px;
  }
  .inpContainer {
    max-width: 100%;
  }
  .inpContainer input[type="file"],
  .inpContainer label,
  .profileImage {
    width: 270px;
    height: 270px;
  }
}
