.sideDrawer {
  width: 342px;
  height: 100%;
  position: fixed;
  flex-wrap: wrap;
  z-index: 11;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 24px 24px;
  transition: transform 0.3s ease-out;
  overflow: auto;
}
.darkBg {
  background-color: var(--black);
}
.lightBg {
  background-color: #ffffff;
}
.sideDrawer::-webkit-scrollbar {
  display: none;
}
.open {
  transform: translateX(0);
}
.close {
  transform: translateX(-150%);
}
.logoContainer {
  width: 100%;
  text-align: center;
}
.logo {
  width: 72px;
  height: 72px;
  margin: 0 auto 48px;
}
.inpContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.searchBar {
  width: 100%;
  height: 56px;
  outline: none;
  border: 1.5px solid rgba(10, 10, 10, 0.25);
  border-radius: 200px;
  padding-left: 32px;
  padding-bottom: 4px;
  font-size: 24px;
  font-family: var(--title-font-family);
  font-weight: 400;
  color: rgba(2, 2, 2, 0.4);
}

@media (min-width: 999px) {
  .sideDrawer {
    display: none;
  }
}
@media (max-width: 450px) {
  .sideDrawer {
    width: 100%;
    top: 84px;
  }
  .logo {
    display: none;
  }
}
