.inpContainer {
  width: 235px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  position: relative;
}
.inpContainer label {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-left: 10px;
  color: var(--black);
}
.inpContainer input {
  opacity: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.inpContainer span {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(10, 10, 10, 0.39);
  background-color: transparent;
}
.checkMark {
  background-color: var(--black) !important;
}
.checkMark::before {
  content: "";
  position: absolute;
  display: block;
  left: 5px;
  top: 0px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.relativeStyle {
  position: relative;
}
.placesContainerClass {
  width: 100%;
  position: absolute;
  top: 100px;
  left: 0;
  max-height: 230px;
  overflow: auto;
  border-radius: 7px;
}
