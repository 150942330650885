.mainDashboard {
  padding: 86px 0 108px 0;
  text-align: center;
  width: 100%;
}
.title {
  font-family: var(--title-font-family);
  font-size: 48px;
  font-weight: 400;
  line-height: 72px;
  color: #0a0a0a;
  padding-bottom: 68px;
}
.allApproved {
  padding-bottom: 20px;
}
.businessesContainer {
  display: flex;
  margin: 0 auto 91px;
  flex-wrap: wrap;
  column-gap: 79px;
  row-gap: 30px;
  text-align: center;
}
.adminContainer {
  max-width: 1150px;
}
.businessContainer {
  max-width: 1300px;
  justify-content: center;
}
.business {
  text-decoration: none;
  position: relative;
}
.awaiting {
  padding: 7px 22px 6px 21px;
  background: rgba(10, 10, 10, 0.2);
  border-radius: 250px;
  font-family: var(--title-font-family);
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #faf9f5;
  margin-bottom: 24px;
}
.approved {
  margin-top: 54px;
}
.businessImg {
  width: 207px;
  height: 300px;
  margin-bottom: 35px;
  object-fit: cover;
}
.name {
  font-family: var(--title-font-family);
  font-size: 28px;
  line-height: 46px;
  color: #020202;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 227px;
}
.addBtn {
  padding: 14px 23px 13px 22px;
  text-decoration: none;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border-radius: 10px;
}
.noBusiness {
  padding-top: 50px;
  font-size: 38px;
  font-weight: 400;
  font-family: var(--text-font-family);
}

.dimmed {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  pointer-events: none;
}
.regBtn {
  background-color: var(--black);
  color: #faf9f5;
}
.businessImage {
  width: 207px;
  height: 300px;
  border-radius: 385.312px;
  margin-bottom: 35px;
}
.invalidIdLabel,
.declinedLabel {
  padding: 7px 22px 6px 21px;
  border-radius: 250px;
  font-family: var(--title-font-family);
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #faf9f5;
  margin-bottom: 24px;
  background: rgba(245, 6, 6, 0.6);
}
.declinedLabel {
  background: #ee4655;
}

.imageOverlay {
  width: 207px;
  height: 300px;
  border-radius: 385.312px;
  position: absolute;
  bottom: 85px;
  margin-left: 9px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}
.uploadIdBtn {
  border: none;
  background-color: transparent;
  font-family: var(--title-font-family);
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #faf9f5;
  display: flex;
  align-items: center;
  z-index: 3;
  cursor: pointer;
}
.arrow {
  width: 18px;
  height: 20px;
  margin-left: 9px;
}
.pageLoader {
  width: 80px;
  height: 80px;
}
.pageLoader::after {
  background-color: #f2f1ed;
}
.support {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--black);
}
