.container {
  width: 100%;
  min-height: calc(100vh -119px);
  display: flex;
  flex-direction: column;
  max-width: 1296px;
  margin: auto;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-family: var(--title-font-family);
  font-size: 48px;
  line-height: 72px;
  font-weight: 300;
  font-weight: unset;
  margin: 32px 0;
}
.tiersContainer {
  display: flex;
  flex-direction: row;
  gap: 72px;
}
.buttonsWrapper {
  margin: 48px 0;
}
.hint {
  font-family: var(--title-font-family);
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: rgba(10, 10, 10, 0.6);
  margin: 24px;
}
.radioContainer {
  display: flex;
  gap: 25px;
}

.checkboxContainer {
  align-items: center;
  display: flex;
  margin: auto;
  position: relative;
  margin-bottom: 5px;
}
.checkboxContainer:nth-of-type(1) {
  width: 120px;
}
.checkboxContainer:nth-of-type(2) {
  width: 180px;
}
.checkboxContainer label {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.02em;
  position: absolute;
  left: 20px;
}
.checkboxContainer input {
  opacity: 0;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.labelActive label {
  color: var(--black);
}
.notActiveLabel label {
  color: rgba(10, 10, 10, 0.5);
}

.checkboxContainer span {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid rgba(10, 10, 10, 0.39);
  background-color: transparent;
}

.checkMark {
  background-color: var(--black) !important;
}
.inpContainer {
  position: relative;
}
