.businessAppContainer {
  width: 100%;
  height: 100%;
  padding: 44px 0;
}
.viewingText {
  font-family: var(--title-font-family);
  font-size: 24px;
  line-height: 52px;
  color: var(--black);
  margin-left: 63px;
  margin-bottom: 22px;
}
.avatarWrapper {
  display: flex;
  margin-left: 63px;
  align-items: center;
}
.avatar {
  width: 71px;
  height: 71px;
  margin-left: 26px;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 63px 58px 63px;
  border-bottom: 8px solid #f2f1ed;
}
.group {
  display: flex;
  flex-direction: column;
  margin: 32px 0;
}
.legend {
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(1, 3, 2, 0.7);
  margin-bottom: 16px;
}
.textField label {
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(1, 3, 2, 0.7);
  margin-bottom: 16px;
  display: block;
}
.textField input,
.textField select {
  width: 100%;
  max-width: 662px;
  height: 56px;
  padding: 13px 0 15px 24px;
  border: 1.5px solid #0a0a0a;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: var(--title-font-family);
  font-size: 20px;
  line-height: 28px;
  background-color: transparent;
}
.halfWidthField {
  margin-right: 20px;
}
.description {
  font-family: var(--title-font-family);
  font-size: 20px;
  line-height: 32px;
  color: var(--black);
  margin: 18px 0 10px 0;
}

.flexWrapper {
  display: flex;
  align-items: baseline;
}
.inpContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.inpContainer label {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-left: 10px;
  color: var(--black);
}
.inpContainer input {
  opacity: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.inpContainer span {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(10, 10, 10, 0.39);
  background-color: transparent;
}
.checkMark {
  background-color: var(--black) !important;
}
.checkMark::before {
  content: "";
  position: absolute;
  display: block;
  left: 5px;
  top: 0px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.dropZone {
  max-width: 662px;
}
.dropZone > label {
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.buttonsWrapper {
  display: flex;
  padding: 71px 0 72px 63px;
}
.btn {
  margin-right: 16px;
  padding: 10px 28px 9px 30px;
  border-radius: 10px;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}
.saveBtn {
  background-color: var(--black);
  color: white;
}
.approveBtn {
  background-color: #1977f3;
  color: white;
}
.declineBtn {
  background-color: transparent;
  border: 2px solid #ee4655;
  color: #ee4655;
}
.btn:disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
  border: none;
}
.previewsContainer {
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  gap: 10px;
}

.previewsContainer > .imageContainer {
  position: relative;
}

.previewsContainer > .imageContainer > img {
  width: 120px;
  height: 120px;
  border: 1px solid var(--black);
}

.previewsContainer > .imageContainer > svg {
  cursor: pointer;
  position: absolute;
  right: -25px;
  top: -20px;
}
.idDescContainer {
  margin-top: 50px;
}
.idDescription {
  font-family: var(--title-font-family);
  font-size: 18px;
  color: var(--black);
  font-weight: 400;
}

.imageContainer {
  width: 699px;
  height: 444px;
  border: 1px solid rgba(10, 10, 10, 0.1);
  padding: 14px 17px;
  margin-bottom: 48px;
}
.idImage {
  width: 100%;
  height: 100%;
}

.oldUserId {
  font-family: var(--title-font-family);
  font-size: 21.525px;
  line-height: 30px;
  color: rgba(1, 3, 2, 0.7);
  font-weight: 400;
  letter-spacing: 0.02em;
}
