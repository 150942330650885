.commentRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 39px 0 48px 0;
  border-bottom: 3px solid #f2f1ed;
  max-width: 522px;
}
.rowTitle {
  font-family: var(--title-font-family);
  font-size: 27.7927px;
  line-height: 33px;
  color: rgba(0, 0, 0, 0.5);
}
.imageContainer {
  display: flex;
  margin-top: 16px;
}
.image {
  width: 110px;
  height: 110px;
  margin-right: 18px;
}
.commentWrapper {
  display: flex;
  max-width: 347px;
  position: relative;
}
.comment {
  font-family: var(--title-font-family);
  font-size: 22.2342px;
  line-height: 38px;
  letter-spacing: -0.02em;
  max-width: 347px;
  height: 118px;
  word-break: break-all;
}
.longComment {
  font-family: var(--text-font-family);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.showAllComment {
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  overflow: unset;
  height: unset;
}
.showMoreBtn {
  font-family: var(--text-font-family);
  font-size: 22.2342px;
  color: #fc986d;
  background-color: transparent;
  border: none;
  line-height: 38px;
  letter-spacing: -0.02em;
  cursor: pointer;
  white-space: nowrap;
  position: absolute;
  right: 0px;
  bottom: -28px;
}
@media (max-width: 600px) {
  .rowTitle {
    font-size: 17.8191px;
  }
  .image {
    width: 70.32px;
    height: 70.32px;
  }
  .comment {
    font-size: 14.2553px;
    line-height: 24px;
  }
}
