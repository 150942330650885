.profile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 32px;
}
.imgNameContainer {
  display: flex;
  align-items: center;
}
.name {
  font-family: var(--inter-semi-bold);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.profilePicture {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 50%;
}
.toggle {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.menuPosition {
  top: 40px;
  right: 0;
}
