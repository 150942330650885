.updateBtn {
  width: 227px;
  height: 68px;
  border-radius: 10px;
  border: 1px solid #000000;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #0a0a0a;
}

.updateBtn:hover {
  background-color: #000000;
  color: #faf9f5;
}
