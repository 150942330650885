.address {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 15.4125px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: var(--black);
}
.noEdit {
  margin: 10.79px 0 31.4px;
}
.edit {
  margin: 10.79px 0 31.4px;
  background: #faf9f5;
  border: 1px solid #000000;
  border-radius: 4px;
  z-index: 2;
  position: relative;
  cursor: pointer;
}
.highZ {
  z-index: 3 !important;
}

@media (max-width: 500px) {
  .address {
    font-size: 15.4125px;
    line-height: 21px;
    padding: 8px 0 19px;
  }
}
