.placeholder {
  width: 126px;
  height: 165.25px;
}
.placeholder {
  border: 1.5px solid rgba(10, 10, 10, 0.2);
  border-radius: 61.32px 35.28px 63px 48.72px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.container {
  position: relative;
}
.layer {
  position: absolute;
  width: 103%;
  height: 103%;
  z-index: 2;
  top: -3px;
  background: linear-gradient(
    0deg,
    rgba(250, 249, 245, 0.7),
    rgba(250, 249, 245, 0.7)
  );
}
.btn {
  width: 24px;
  height: 24px;
  outline: none;
  border: 1px solid #faf9f5;
  border-radius: 50%;
  position: absolute;
  top: 36px;
  right: 8px;
  z-index: 2;
}
.activeBtn {
  background: #536eff;
}
.notActiveBtn {
  background: rgba(227, 226, 222, 0.5);
}
