.signOut {
  width: 87px;
  height: 38px;
  border-radius: 10px;
  border: 1px solid #000000;
  font-family: var(--title-font-family);
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0a0a0a;
  background-color: #ffffff;
  cursor: pointer;
  margin: 0 10px;
}

.signOut:hover {
  background-color: #000000;
  color: #faf9f5;
}
