.links {
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 9.73px;
}
.noEdit {
  top: 0;
  right: 32px;
}
.edit {
  top: 30px;
  right: 30px;
}
.icon {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  cursor: pointer;
}
.social {
  position: relative;
}
.tooltip {
  position: absolute;
  top: -20px;
  right: 50px;
  border-radius: 20px !important;
}
.tooltip::before {
  top: 20px;
  right: -20px;
}
@media (max-width: 1080px) {
  .links {
    top: 60px;
  }
}
@media (max-width: 500px) {
  .links {
    position: initial;
    flex-direction: row;
    justify-content: initial;
    margin: 20px 30px 0;
  }
}
