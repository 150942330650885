.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1440px;
  margin: auto;
  justify-content: space-between;
}
.linkContainer {
  width: 100%;
  margin-top: 57px;
}
.businessRegisterLink {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: var(--black);
}
.brdr {
  width: 1px;
  height: 788px;
  background-color: rgba(10, 10, 10, 0.1);
  margin: 17px 0 0 0;
}
.authOptions {
  margin-top: 280px;
}
@media (max-width: 1300px) {
  .container {
    justify-content: center;
    flex-direction: column;
  }
  .brdr {
    display: none;
  }
  .authOptions {
    margin-top: 70px;
  }
}
