.addContainer {
  padding: 33px 15px 37px;
}
.title {
  font-family: var(--text-font-family);
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  font-weight: 400;
}
.noSelect {
  padding-bottom: 37px;
}
.selected {
  font-family: var(--text-font-family);
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #536eff;
  padding: 12px 0 8px;
  font-weight: 400;
}
.collections {
  width: 100%;
  display: flex;
  gap: 26px;
  flex-wrap: wrap;
  min-height: 198px;
  max-height: 430px;
  overflow: auto;
}
.collection {
  text-align: left;
  cursor: pointer;
  max-width: 150px;
}
.singleItem {
  margin: auto;
}
.businessName,
.itemName {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: -0.02em;
  max-width: 100%;
  position: relative;
  z-index: 2;
  text-transform: capitalize;
}
.price {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
}
.businessName {
  color: #afaeab;
}
.itemName {
  color: var(--black);
}
.errorMessage {
  font-size: 22px;
}
.loader {
  width: 70px;
  height: 70px;
}
.collectionImage {
  width: 150px;
  height: 197px;
}
.update {
  display: block;
  margin: 30px auto 0;
  outline: none;
  border: none;
  cursor: pointer;
  color: #000000;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 14px;
  background-color: transparent;
}
.btnLoader {
  width: 21px;
  height: 21px;
}
.disabled {
  color: gray !important;
  cursor: not-allowed !important;
}

@media (max-width: 430px) {
  .collections {
    justify-content: center;
    gap: 31px;
  }
}
