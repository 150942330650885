.businessPage {
  background-color: #f2f1ed;
  min-height: calc(100vh - 161px);
  margin-left: 10px;
}
.container {
  display: flex;
  gap: 20px;
}
.middleContainer {
  display: flex;
  gap: 10px;
}
.aboutOwner {
  width: 56%;
}

.rightContainer {
  width: 43%;
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
}

.aboutSection {
  max-width: 860px;
  padding: 71.9px 64px 102.17px;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 28px;
}
.comments {
  background-color: var(--lighter-gray-color);
  width: 100%;
}

.whatWeOfferSection {
  width: 100%;
  background-color: #f9f8f4;
  padding: 89px 0 90.5px;
  margin-top: 20px;
}
@media (max-width: 1180px) {
  .aboutOwner,
  .rightContainer {
    width: 100%;
  }
  .rightContainer {
    margin-bottom: 0;
  }
  .aboutSection {
    margin: 0 auto;
    max-width: 1440px;
    padding: 71.9px 64px 102.17px;
  }
  .middleContainer {
    flex-wrap: wrap;
    gap: 0;
  }
  .comments {
    margin-top: 28px;
  }
}
@media (max-width: 650px) {
  .aboutSection {
    padding: 71.9px 32px 102.17px;
  }
}
