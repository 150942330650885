.aboutBusiness {
  min-width: 43%;
  background: #faf9f5;
  border-radius: 0px 170px 0px 0px;
  padding: 45px 64px 112px;
}

.title {
  font-family: var(--text-font-family);
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(1, 3, 2, 0.7);
}
.businessName {
  font-family: var(--title-font-family);
  font-weight: 300;
  font-size: 49.1547px;
  line-height: 61px;
  color: #020202;
  padding: 24px 0 11px;
}
.address {
  font-family: var(--text-font-family);
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: var(--black);
  opacity: 0.5;
}
.desc {
  font-family: var(--title-font-family);
  font-weight: 300;
  font-size: 20.6805px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: var(--black);
  margin: 17.44px 0 44.39px;
  max-height: 163px;
  overflow: auto;
  width: 100%;
}
.desc p {
  max-width: 491px;
}
.visit {
  text-decoration: none;
}
.visitBtn {
  border: none;
  outline: none;
  width: 267px;
  height: 68px;
  border-radius: 50px;
  background-color: var(--black);
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
}
.loader {
  width: 100px;
  height: 100px;
  margin-top: 50px;
}

@media (max-width: 1180px) {
  .desc {
    max-height: initial;
  }
  .desc p {
    max-width: 641px;
  }
}
@media (max-width: 650px) {
  .aboutSection {
    border-radius: 0px 90px 0px 0px;
  }
  .aboutBusiness {
    padding: 71.9px 32px 102.17px;
    border-radius: 0px 90px 0px 0px;
  }
  .businessName {
    font-size: 32px;
    line-height: 36px;
    padding: 40px 0 11px;
  }
  .desc {
    font-size: 12px;
    line-height: 24px;
  }
  .visitBtn {
    max-width: 100%;
  }
}
