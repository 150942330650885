.tooltipContainer {
  z-index: 99;
  position: absolute;
  background: white;
  min-width: 335px;
  min-height: 48px;
  border-radius: 39px;
  pointer-events: initial !important;
}
.tooltipContainer:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 30px solid white;
}
.arrowDownTooltip:before {
  border-top: 30px solid white;
  border-bottom: none;
}
.closeIcon {
  position: absolute;
  top: 30.94px;
  right: 23.87px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.tooltipBody {
  width: 100%;
  height: 100%;
  padding: 30px 20px 30px 20px;
}
.topAnchor {
  top: 40px;
  left: -130px;
}
.topAnchor:before {
  top: -20px;
  left: 140px;
}
.topRightAnchor:before {
  top: -20px;
  left: 270px;
}
.bottomAnchor {
  bottom: 100px;
  left: -8px;
}
.bottomAnchor:before {
  bottom: -30px;
  left: 140px;
}
@media (max-width: 998px) {
  .tooltipContainer {
    display: none;
  }
}
