.headerSection {
  width: 100%;
  height: 720px;
  position: relative;
  background-color: #f2f1ed;
}
.headerContainer {
  width: 100%;
  max-width: 1440px;
  height: 720px;
  margin: auto;
  display: flex;
  align-items: center;
}
.headerImg {
  width: 826px;
  max-width: 100%;
  height: 968px;
  position: absolute !important;
  right: 0;
  top: -59px;
}
.headerLoader {
  border-radius: 430.392px 117.626px 0px 470.505px;
  background: #e9e8e8 !important;
}
.smallerImg {
  display: none;
}
.text {
  font-family: var(--title-font-family);
  font-size: 80px;
  width: 542px;
  font-weight: 400;
  margin-left: 105px;
  color: #020202;
}
.hideText {
  display: none;
}
@media (max-width: 1440px) {
  .text {
    margin-left: 70px;
    font-size: 70px;
  }
  .headerImg {
    width: 706px;
    max-width: 100%;
    height: 868px;
    position: absolute !important;
    right: 0;
    top: -59px;
  }
}
@media (max-width: 1265px) {
  .headerSection,
  .headerContainer {
    height: 660px;
  }

  .headerImg {
    width: 600px;
    height: 800px;
  }
}
@media (max-width: 1098px) {
  .headerSection,
  .headerContainer {
    height: 530px;
  }

  .headerImg {
    width: 500px;
    height: 700px;
  }
}
@media (max-width: 998px) {
  .headerSection,
  .headerContainer {
    height: 530px;
  }
  .headerImg {
    top: -40px;
  }
  .text {
    font-size: 50px;
    width: 300px;
  }
}
@media (max-width: 890px) {
  .headerSection,
  .headerContainer {
    height: 470px;
  }

  .headerImg {
    width: 370px;
    height: 570px;
  }
}

@media (max-width: 750px) {
  .text {
    font-size: 30px;
    width: 200px;
  }
}
@media (max-width: 710px) {
  .headerSection,
  .headerContainer {
    height: 485px;
  }
  .headerImg {
    top: -39px;
  }
}
@media (max-width: 660px) {
  .bgBlack {
    background: linear-gradient(183.35deg, #1b082f 11.7%, #240d2a 64.55%);
    position: absolute;
    top: 0;
    min-height: 600px;
    width: 100%;
  }
  .loader {
    width: 100%;
    height: 100vh;
  }
  .headerSection,
  .headerContainer {
    height: auto;
  }
  .headerContainer {
    display: block;
    border-radius: 0 0 279.54px 410px;
  }
  .headerImg {
    display: none;
  }
  .smallerImg {
    display: initial;
    width: 100%;
    z-index: 2;
    position: relative;
  }
  .smallPlaceholder {
    height: 390px;
  }
  .smallerImgLoader {
    border-radius: 0 0 470.505px 470.505px;
    background: transparent !important;
  }

  .text {
    color: var(--lighter-gray-color);
    font-size: 40px;
    width: 255px;
    margin-left: 40px;
    z-index: 2;
    position: relative;
  }
  .hideText {
    display: initial;
    max-width: 250px;
  }

  .headerSection {
    padding-top: 52px;
  }
}
