.nominationsSection {
  width: 100%;
  background: rgba(242, 241, 237, 0.2);
  border-radius: 0px 170px 0px 0px;
  position: relative;
}
section.blackBg {
  background: var(--black);
}
.content {
  max-width: 1440px;
  margin: auto;
  padding: 48px 64px 88px;
}
.title,
.businessContainer span {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
}
.content span {
  display: block;
}
.mainTitle {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(242, 241, 237, 0.7);
}
.subTitle {
  color: #f2f1ed;
  font-size: 48px;
  line-height: 56px;
}
.nominations {
  display: flex;
  column-gap: 50px;
  row-gap: 78px;
  flex-wrap: wrap;
}
.businessContainer {
  width: 268px;
}
.businessContainer a {
  text-decoration: none;
}
.businessImg {
  width: 100%;
  height: 340px;
  object-fit: cover;
}
.businessInfo {
  text-align: center;
  width: 100%;
}
.name {
  font-size: 16px;
  line-height: 19px;
  color: rgba(242, 241, 237, 0.7);
  letter-spacing: -0.02em;
  text-transform: capitalize;
  margin: 5px 0 8px;
}
.categories,
.city {
  color: #f2f1ed;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}
.imgContainer {
  position: relative;
}
.checkboxContainer {
  position: relative;
}
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 68px;
  width: 68px;
  z-index: 2;
}
.checkboxContainer span,
.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 68px;
  width: 68px;
  border-radius: 50%;
  background: #f2f1ed;
}
.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 20px;
  height: 20px;
}
.loader::after {
  background: #f2f1ed;
}
.checkboxContainer:hover input ~ span {
  background-color: #ccc;
}

.checkboxContainer input:checked ~ span {
  background-color: #ff6e26;
}

.checkboxContainer span:after {
  content: "VOTE";
  position: absolute;
  display: block;
  left: 14px;
  bottom: 25px;
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--black);
}
.checkboxContainer input:checked ~ span::after {
  content: "";
  left: 28px;
  top: 20px;
  width: 8px;
  height: 19px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.btnContainer {
  position: absolute;
  right: 68px;
  bottom: 68px;
}
.dimmedBusiness {
  opacity: 0.5;
}
.winnerBadge {
  position: absolute;
  top: -35px;
  right: -23.98px;
}
.winnerBadge img {
  width: 191.97px;
  height: 191.97px;
}
.winnersSubTitle {
  display: flex;
  column-gap: 16px;
  align-items: center;
  margin: 24px 0 82px;
}
.badge {
  border: 1px solid #f2f1ed;
  border-radius: 40px;
  padding: 4px 16px;
}
.badge span {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #f2f1ed;
}
@media (max-width: 720px) {
  .nominationsSection {
    border-radius: 0px 90px 0px 0px;
  }
  .content {
    padding: 48px 30px 88px;
  }

  .mainTitle {
    font-size: 10px;
    line-height: 15px;
  }
  .subTitle {
    font-size: 14px;
    line-height: 19px;
  }
  .winnersSubTitle {
    margin: 14px 0 82px;
  }
  .badge span {
    font-size: 8px;
    line-height: 14px;
  }
}
