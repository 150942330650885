.container {
  width: 100%;
  margin: auto;
}
.loader::after {
  background-color: #f2f1ed;
}
.backContainer {
  padding: 50px 50px 0 50px;
  display: inline-block;
  cursor: pointer;
}
