.inpContainer {
  width: 201px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  position: relative;
}
.inpContainer label {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
}
.inpContainer input {
  opacity: 0;
  width: 23px;
  height: 23px;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.inpContainer span {
  position: absolute;
  left: 0;
  width: 23px;
  height: 23px;
  border-radius: 4px;
  border: 1px solid rgba(10, 10, 10, 0.39);
  background-color: transparent;
}
.checkMark {
  background-color: var(--black) !important;
}
.checkMark::before {
  content: "";
  position: absolute;
  display: block;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
