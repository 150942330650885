.trySection {
  max-width: 316px;
}
.optionTitle {
  font-size: 36.9px;
  line-height: 55px;
  font-family: var(--title-font-family);
  color: #f9f8f4;
  font-weight: 400;
  padding-bottom: 13px;
}
.optionDesc {
  font-size: 15.375px;
  line-height: 22px;
  font-family: var(--text-font-family);
  color: #f9f8f4;
  font-weight: 400;
  width: 242.93px;
}
.btnImg {
  position: absolute;
  top: -61px;
  right: 199px;
}
.businessImg {
  position: relative;
  background-image: url(../../../../../assets/images/tryBusinessAuth.png);
  background-size: cover;
  width: 433.29px;
  height: 562.49px;
  border-radius: 168.589px 234.159px 292.699px 292.699px;
}
.learn {
  position: absolute;
  top: 309.81px;
  right: -64.68px;
  width: 138.38px;
  height: 138.38px;
  color: var(--black);
  background-color: #f9f8f4;
  text-decoration: none;
  font-size: 15.375px;
  line-height: 18px;
  z-index: 2;
  border-radius: 126.437px 82.1839px 139.08px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--text-font-family);
  border: none;
  outline: none;
  cursor: pointer;
}
