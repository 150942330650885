.registerPage {
  background-color: #f9f8f4;
  width: 100%;
  min-height: calc(100vh - 161px);
}
.registerContainer {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 31px 151px 32px;
  display: flex;
  flex-wrap: wrap;
}
.btnContainer {
  width: 100%;
  text-align: left;
}
.backBtn {
  width: 50px;
  height: 20px;
  margin-top: 40px;
  cursor: pointer;
}

@media (max-width: 1370px) {
  .registerContainer {
    flex-direction: column;
    justify-content: center;
  }
}
