.timerContainer {
  display: flex;
  align-items: center;
  column-gap: 4px;
}
.timerContainer span,
.text {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #f2f1ed;
}
.count {
  border: 1px solid rgba(242, 241, 237, 0.2);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timerText {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
@media (max-width: 800px) {
  .timerContainer span,
  .text {
    font-size: 8px;
    line-height: 10px;
  }
  .count {
    width: 15px;
    height: 15px;
  }
  .timerText {
    column-gap: 6px;
  }
}
