.loader {
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  background: #f2f1ed;
  float: right;
  margin-left: 20px;
}
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100px);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  animation: loading 0.8s infinite;
}
.lightBg {
  background-color: #f9f8f4;
  border-radius: 50%;
}
