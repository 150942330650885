.requestsContainer{
    width:100%;
    height: 100%;
    overflow: auto;
    padding:62px 63px 62px 53px;
    min-width: 900px;
}
.requestsTable{
    width:100%;
    margin-top: 67px;
}
.spinnerWrapper{
    margin-top:100px;
}

.searchField{
    width: 100%;
    max-width: 523px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 56px;
    
  }
  .header{
      width: 100%;
      display: flex;
      justify-content: space-between;
  }
  .searchField input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding-left: 55px;
    font-size: 20px;
    font-family: var(--title-font-family);
    font-weight: 400;
    color: var(--black);
    border: 1.5px solid rgba(10, 10, 10, 0.25);
    border-radius: 200px;
  }
  .searchField input ::placeholder{
    font-size: 20px;
    font-family: var(--title-font-family);
    font-weight: 400;
    color: rgba(2, 2, 2, 0.4);
    }

.paginatorRef {
    width: 100%;
    height: 20px;
 }
      