.resultsContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 68px;
  row-gap: 68px;
  padding-right: 20px;
  max-height: 2500px;
  overflow: auto;
}
.resultsSection {
  margin-top: 58px;
}
.withMap {
  max-width: 609px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 35px;
  padding-right: 20px;
  max-height: 2500px;
  overflow: auto;
}
.loader {
  width: 100px;
  height: 100px;
  margin: 50px auto 0;
}
.loading {
  width: 100%;
}
.empty {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}
@media (max-width: 720px) {
  .resultsContainer,
  .withMap {
    padding-right: 0;
    column-gap: 25px;
    row-gap: 24px;
    justify-content: flex-start;
  }
  .resultsSection {
    padding: 0 31px;
  }
}
@media (max-width: 600px) {
  .resultsContainer,
  .withMap {
    justify-content: center;
  }
}
