.popUp {
  width: 375px;
  top: 20%;
}
.add {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.popUpHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 28px;
  line-height: 34px;
  font-family: var(--title-font-family);
  color: #020202;
  font-weight: 400;
}

.brdr {
  width: 100%;
  height: 1px;
  background: #f2f1ed;
  margin: 16px 0 57px;
}
.errorMessage {
  font-size: 22px;
}
.addForm {
  width: 100%;
}
.inpContainer input {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: rgba(10, 10, 10, 0.8);
  font-size: 28px;
  font-family: var(--text-font-family);
  font-weight: 400;
}
.inpContainer label {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(10, 10, 10, 0.5);
  padding-bottom: 15px;
}
.btnContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.confirmBtn {
  width: 68px;
  height: 68px;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 54px;
}
.confirmBtn:disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
}
.confirmBtn:disabled path {
  stroke: #f9f8f4;
}
.regularBg {
  background-color: var(--black);
  border: none;
}
.regularBg path {
  stroke: #f9f8f4;
}
.loadingBg {
  border: 1px solid var(--black);
  background-color: var(--lighter-gray-color);
}
.loader {
  width: 30px;
  height: 30px;
}
@media (max-width: 500px) {
  .popUp {
    max-width: 90%;
  }
}
