.aboutSection {
  background-color: var(--lighter-gray-color);
  width: 100%;
  border-radius: 0px 0px 0px 155.784px;
  position: relative;
}
.title {
  font-family: var(--title-font-family);
  font-weight: 300;
  font-size: 47.9335px;
  line-height: 60px;
  color: #020202;
}
.title:not(.spec) {
  max-width: 370px;
}
.editTitle {
  background: #faf9f5;
  border: 1px solid #000000;
  z-index: 2;
}
.user {
  display: flex;
  align-items: center;
  margin: 37.95px 0 24.96px;
}
.userName {
  font-family: var(--text-font-family);
  font-weight: 300;
  font-size: 17.975px;
  line-height: 21px;
  color: #000000;
}
.userInfo {
  padding-left: 6.99px;
}
.userRole {
  font-family: var(--text-font-family);
  font-weight: 300;
  font-size: 17.975px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.64);
}
.desc {
  max-width: 100%;
  max-height: 224px;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 19.9723px;
  line-height: 32px;
  color: var(--black);
  white-space: pre-wrap;
  overflow: auto;
}
.desc p {
  max-width: 641px;
}
.secondTitle {
  margin: 59.92px 0 31.96px;
}
.loader {
  width: 100px;
  height: 100px;
  margin-top: 50px;
}
.edit,
.highlight {
  background: #faf9f5;
  border: 1px solid #000000;
  border-radius: 4px;
  z-index: 2;
  position: relative;
}
.edit {
  cursor: pointer;
}
.about {
  position: relative;
}
.inpContainer {
  width: 291px;
}

.inpContainer label {
  font-family: var(--title-font-family);
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: rgba(10, 10, 10, 0.4);
  display: block;
}
.inpContainer label:not(.first label) {
  padding: 30px 0 4px;
}
.inpContainer input,
.inpContainer textarea {
  width: 100%;
  outline: none;
  border: 1px solid #000000;
  border-radius: 4px;
  padding-left: 5px;
}
.inpContainer input {
  height: 46px;
}
.inpContainer textarea {
  height: 200px;
  resize: none;
  font-size: 14px;
}
.container {
  position: relative;
}
.nameTooltip {
  position: absolute;
  top: 43px;
  left: -30px;
}
.nameTooltip::before {
  top: -20px;
  left: 15%;
}
.descTooltip {
  position: absolute;
  top: -40px;
  left: 104%;
}
.descTooltip::before {
  left: -20px;
  top: 50px;
}
.editBtn {
  position: absolute;
  top: 70px;
  right: 10px;
  z-index: 5;
}

@media (max-width: 1180px) {
  .desc {
    max-height: initial;
  }
}
@media (max-width: 650px) {
  .aboutSection {
    border-radius: 0px 0px 0px 90px;
  }
  .title {
    font-size: 32px;
    line-height: 36px;
  }
  .desc {
    font-size: 12px;
    line-height: 24px;
  }
}
