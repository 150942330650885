.infoContainer {
  position: relative;
  max-width: 350px;
}
.tagsContainer {
  display: flex;
  gap: 6.16px;
  flex-wrap: wrap;
  margin-bottom: 30.41px;
}
.businessName {
  font-family: var(--text-font-family);
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(1, 3, 2, 0.7);
  font-size: 12.2px;
  line-height: 15px;
  padding-bottom: 17px;
}
.title {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  color: #020202;
}
.price {
  font-family: var(--text-font-family);
  font-weight: 400;
  letter-spacing: -0.02em;
  color: var(--black);
  font-size: 15px;
  line-height: 21px;
  padding: 6px 0 26px;
}
.btnContainer {
  display: flex;
  align-items: center;
  gap: 24.97px;
}
.upVote,
.addToCollection,
.shareBtn {
  width: 86.03px;
  height: 86.03px;
  background-color: #f2f1ed;
  border: 1.2652px solid #deddd9 !important;
  border-radius: 70.8515px;
  cursor: pointer;
}
.upVote p {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 20.1033px;
  line-height: 28px;
  color: #0a0a0a;
}
.shareBtn svg {
  width: 23px;
  height: 23px;
}
.addToCollection svg {
  width: 32px;
  height: 32px;
}
@media (max-width: 1180px) {
  .tagsContainer {
    margin-bottom: 20.41px;
  }
  .businessName {
    font-size: 12.2px;
    line-height: 15px;
    padding-bottom: 17px;
  }
  .title {
    font-family: var(--title-font-family);
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    color: #020202;
  }
  .price {
    font-size: 15px;
    line-height: 21px;
    padding: 6px 0 26px;
  }
}
@media (max-width: 800px) {
  .tagsContainer {
    margin-bottom: 20.41px;
  }
  .businessName {
    padding-bottom: 13px;
  }
  .title {
    font-family: var(--title-font-family);
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    color: #020202;
  }
  .price {
    padding: 6px 0 26px;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 40px;
    line-height: 50px;
  }
  .upVote,
  .addToCollection,
  .shareBtn {
    width: 66.03px;
    height: 66.03px;
  }
}
