.profileNavigation {
  position: relative;
}
.profilePicture {
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 20px;
}
.menuPosition {
  right: 0;
  top: 57px;
}
@media (max-width: 998px) {
  .profilePicture {
    margin-left: 0;
  }
}
