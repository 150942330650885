.popularSection {
  width: 100%;
  background-color: #f9f8f4;
  border-radius: 0px 0px 261px 0px;
  padding: 89px 0 90.5px;
}
.shardSection {
  margin-bottom: 28px;
}
.popularContainer {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 0 31px;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 51px;
}

.sectionTitle {
  font-family: var(--title-font-family);
  font-size: 48px;
  color: var(--black);
  line-height: 72px;
  font-weight: 400;
}
.items {
  max-width: 1279px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 1050px;
}
.notLoaded {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  justify-content: space-between;
  width: 100%;
}
.item {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.item0 {
  order: 1;
  flex: 0 0 100%;
  margin-right: 100px;
}
.item1 {
  order: 2;
  flex: 0 0 50%;
}
.item2 {
  order: 2;
  flex: 0 0 50%;
}
.item3 {
  order: 3;
  flex: 0 0 50%;
  margin-left: 60px;
}
.item4 {
  order: 3;
  flex: 0 0 50%;
  margin-left: 60px;
}
.product,
.business,
.price {
  font-family: var(--text-font-family);
  font-weight: 400;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
}

.itemImg {
  width: 262px;
  height: 341px;
  object-fit: cover;
}
.item1,
.item2,
.item3,
.item4 {
  width: 262px;
  height: 341px;
}
.firstItemImg,
.item0 {
  width: 527px;
  height: 692.13px;
  object-fit: cover;
}

.business {
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  padding-top: 20px;
}
.product {
  padding: 8px 0 5px;
  font-size: 20px;
  line-height: 27px;
  color: var(--black);
  text-transform: capitalize;
}
.price {
  color: var(--black);
  font-size: 20px;
  line-height: 25px;
}
.imgContainer {
  position: relative;
}
.upVote {
  width: 49px;
  height: 49px;
  border-radius: 56px;
  backdrop-filter: blur(4px);
  border: 1px solid #deddd9 !important;
  position: absolute;
  right: -10px;
  bottom: -9px;
}
.firstUpVote {
  width: 74px;
  height: 74px;
  backdrop-filter: blur(6.04082px);
  border: 1px solid #deddd9;
  border-radius: 84.5714px;
  position: absolute;
  bottom: 0;
  right: -20px;
}
.addToCollection {
  width: 28px;
  height: 28px;
  backdrop-filter: blur(1.64706px);
  border-radius: 20.5882px;
  border: 1px solid #deddd9;
  position: absolute;
  right: -10px;
  bottom: 48px;
}
.firstAddToCollection {
  width: 42.29px;
  height: 42.29px;
  border: 1px solid #deddd9;
  backdrop-filter: blur(2.48739px);
  border-radius: 31.0924px;
  position: absolute;
  right: -20px;
  bottom: 85.84px;
}

@media (max-width: 1300px) {
  .popularContainer {
    max-width: 1100px;
  }

  .items {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 46px;
    height: auto;
  }
  .item0 {
    margin-right: 0;
  }
  .firstItemImg {
    width: 262px;
    height: 341px;
  }
  .firstUpVote {
    width: 49px;
    height: 49px;
    border-radius: 56px;
    backdrop-filter: blur(4px);
    border: 1px solid #deddd9 !important;
    position: absolute;
    right: -10px;
    bottom: -9px;
  }
  .firstAddToCollection {
    width: 28px;
    height: 28px;
    backdrop-filter: blur(1.64706px);
    border-radius: 20.5882px;
    border: 1px solid #deddd9;
    position: absolute;
    right: -10px;
    bottom: 48px;
  }
  .item0,
  .item1,
  .item2,
  .item3,
  .item4 {
    width: initial;
    height: initial;
    flex: initial;
  }
  .item3,
  .item4 {
    margin-left: 0;
  }
}
@media (max-width: 850px) {
  .items {
    column-gap: 25px;
    row-gap: 24px;
  }
  .itemImg,
  .firstItemImg {
    width: 150px;
    height: 197px;
  }
  .business,
  .product,
  .price {
    width: 159px;
  }
  .business {
    padding-top: 9px;
  }
  .product {
    padding: 0;
    font-size: 16px;
    line-height: 27px;
  }
  .price {
    font-size: 14px;
    line-height: 21px;
  }
}
@media (max-width: 720px) {
  .sectionTitle {
    font-size: 32px;
  }
  .popularSection {
    border-radius: 0px 0px 111px 0px;
  }
}
@media (max-width: 605px) {
  .items {
    justify-content: center;
  }
}
