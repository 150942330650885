.test {
  width: 262px;
  height: 341px;
}
.shape0 {
  border-radius: 129.822px 46.736px 86.5482px 129.822px;
}
.shape1 {
  border-radius: 51.9289px 31.1574px 146.266px 112.513px;
}
.shape2 {
  border-radius: 80.5267px 53.1133px 116.507px 92.52px;
}
.shape3 {
  border-radius: 129.822px 31.1574px 146.266px 112.513px;
}
.shape4 {
  border-radius: 126.533px 72.8px 130px 100.533px;
}
.shape5 {
  border-radius: 77.4px 36.12px 116.96px 104.92px;
}
.shape6 {
  border-radius: 51.9289px 129.822px 146.266px 112.513px;
}
.shape7 {
  border-radius: 41.5431px 19.0406px 117.706px 112.513px;
}
.shape8 {
  border-radius: 50px 200px 30px 80px;
}
.shape9 {
  border-radius: 20px 50px 84px 200px;
}
.shape10 {
  border-radius: 200px 80px 200px 50px;
}
