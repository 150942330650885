.list {
  max-width: 245px;
  min-width: 155px;
  background-color: #ffffff;
  border-radius: 9px;
  position: absolute;
  top: 30px;
  left: 64px;
}
.listItems {
  padding: 16px 10px 0px 20px;
}
.link {
  display: block;
  text-transform: capitalize;
  text-decoration: none;
}
.linkContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.text {
  font-family: var(--text-font-family);
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;
  text-decoration: none;
  padding: 7px 0 9px;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notActive {
  color: rgba(0, 0, 0, 0.4);
}
.activeText {
  color: #000000;
}
.brdr,
.fullBrdr {
  width: 100%;
  height: 1px;
  background-color: #f2f1ed;
}
.notActive:hover {
  color: #000000;
}
.fullBrdr {
  margin-top: 8px;
}
.addLink {
  display: block;
  width: 98px;
  text-decoration: none;
  margin: 13px auto;
  font-family: var(--title-font-family);
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.dimmed {
  color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
}
.regBtn {
  color: var(--black);
}
