.tooltip {
  position: absolute;
  top: 50px;
  right: 20px;
}
.tooltip::before {
  top: -20px;
  right: 30px;
}
.relativeStyle {
  position: relative;
}
.placesContainerClass {
  width: 100%;
  position: absolute;
  top: 100px;
  left: 0;
  max-height: 230px;
  overflow: auto;
  border-radius: 7px;
}
.fullWidth {
  width: 291px;
}
.fullWidth label {
  font-family: var(--title-font-family);
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: rgba(10, 10, 10, 0.4);
  display: block;
}
.fullWidth label:not(.relativeStyle label) {
  padding: 30px 0 4px;
}
.fullWidth input,
.fullWidth select {
  width: 100%;
  height: 46px;
  outline: none;
  border: 1px solid #000000;
  border-radius: 4px;
  padding-left: 5px;
}
