.exploreSection {
  width: 100%;
  background-color: #f9f8f4;
  margin: 21.53px 0;
  border-radius: 0px 152.981px 0px 169.894px;
  padding: 75px 0 109px;
}
.exploreContainer {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  padding: 0 31px;
}
.sectionTitle {
  font-family: var(--title-font-family);
  font-size: 36.9px;
  color: var(--black);
  line-height: 55px;
  font-weight: 400;
  padding-bottom: 51px;
}
.items {
  max-width: 1100px;
  height: 800px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.item {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.item0 {
  order: 1;
  flex: 0 0 100%;
  margin-right: 10px;
}
.item1 {
  order: 2;
  flex: 0 0 50%;
}
.item2 {
  order: 2;
  flex: 0 0 50%;
}
.item3 {
  order: 3;
  flex: 0 0 50%;
}
.item4 {
  order: 3;
  flex: 0 0 50%;
}

.itemImg {
  width: 199.88px;
  height: 259.84px;
  margin-bottom: 15px;
}
.item1,
.item2,
.item3,
.item4 {
  width: 242px;
  height: 341px;
}
.firstItemImg,
.item0 {
  width: 405.13px;
  height: 526.67px;
}
.firstItemImg {
  margin-bottom: 15px;
}
.title {
  color: var(--black);
  font-size: 21.5px;
  line-height: 25.2px;
  font-family: var(--title-font-family);
}
.subTitle {
  max-width: 316px;
  padding: 8px 0;
  font-size: 15.38px;
  line-height: 22.29px;
  color: rgba(10, 10, 10, 0.6);
  font-family: var(--text-font-family);
  margin: auto;
  letter-spacing: -0.02em;
}
.imgContainer {
  position: relative;
}
