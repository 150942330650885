.name {
  font-size: 37px;
  line-height: 51px;
  color: #f2f1ed;
  font-weight: 300;
  margin: 40px 0 8px;
  text-transform: capitalize;
}
.winnerTitle {
  display: block;
  max-width: 316px;
}
.winnerTitle,
.link {
  font-size: 20px;
  line-height: 27px;
  color: rgba(242, 241, 237, 0.7);
  font-weight: 300;
}
.aboutWinner {
  margin-bottom: 55vh;
}
.header {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.descMargin {
  margin: 8px 0 24px;
}
.businessPage {
  display: inline-block;
  text-decoration: none;
  padding: 24px 32px 25px;
  background: #f2f1ed;
  border-radius: 34px;
  font-family: var(--text-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--black);
}
@media (max-width: 980px) {
  .name {
    font-size: 28px;
    line-height: 41px;
  }
  .winnerTitle,
  .link {
    font-size: 16px;
    line-height: 19px;
  }
  .aboutWinner {
    margin-bottom: 70vh;
  }
}
@media (max-width: 630px) {
  .name {
    font-size: 16px;
    line-height: 26px;
    margin: 20px 0 8px;
  }
  .winnerTitle,
  .link {
    font-size: 10px;
    line-height: 12px;
  }
  .aboutContainer {
    max-width: 186px;
  }
  .header img {
    width: 40px;
  }
  .businessPage {
    padding: 14px 22px 15px;
    background: #f2f1ed;
    border-radius: 24px;
    font-size: 10px;
    line-height: 14px;
  }
  .aboutWinner {
    max-width: 150px;
  }
  .aboutWinner {
    margin-bottom: 80vh;
  }
}
