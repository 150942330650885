.editContainer {
  padding: 34px 18.58px 24px 18px;
}
.title {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}
.collectionImage {
  width: 118px;
  height: 155px;
  margin: 50px auto 0;
}
.changeBtn {
  display: block;
  margin: auto;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #1977f3;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.inpIcon {
  width: 100%;
  position: relative;
}
.delete {
  position: absolute;
  width: 25px;
  height: 25px;
  right: 0;
  top: 24px;
  cursor: pointer;
}
.inpContainer {
  text-align: left;
  margin: 39px 0 95px;
}
.inpContainer input {
  width: 91%;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--black);
  font-size: 22px;
  font-family: var(--text-font-family);
  font-weight: 400;
}
.inpContainer label {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(10, 10, 10, 0.5);
  padding-bottom: 15px;
}
.confirmBtn {
  width: 100%;
  height: 68px;
  outline: none;
  border: none;
  color: #ffffff;
  border-radius: 50px;
  cursor: pointer;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.confirmBtn:disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
}
.confirmBtn:disabled path {
  stroke: #f9f8f4;
}
.regularBg {
  background-color: var(--black);
  border: none;
}
.regularBg path {
  stroke: #f9f8f4;
}
.loadingBg {
  border: 1px solid var(--black);
  background-color: var(--lighter-gray-color);
}
.loader {
  padding: 20px;
}
.errorMessage {
  padding: 20px 0;
  font-size: 22px;
}
