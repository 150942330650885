.addItem {
  background-color: #f2f1ed;
  min-height: calc(100vh - 161px);
  margin-left: 10px;
  position: relative;
  overflow-y: auto;
  width: 100%;
}
.content {
  background-color: #f9f8f4;
}
.adminPadding {
  padding-top: 50px;
}
