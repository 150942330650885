.trySection {
  max-width: 316px;
}
.optionTitle {
  font-size: 48px;
  font-family: var(--title-font-family);
  color: #f9f8f4;
  line-height: 72px;
}
.optionDesc {
  font-size: 20px;
  font-family: var(--text-font-family);
  color: #f9f8f4;
  padding-top: 19px;
}
.btnImg {
  position: absolute;
  top: -74px;
  right: 260px;
}
.businessImg {
  position: relative;
  background-image: url(../../../../../assets/images/tryBusinessAuth.png);
  background-size: cover;
  width: 563.63px;
  height: 731.69px;
  border-radius: 219.303px 304.597px 380.746px 380.746px;
}
.learn {
  position: absolute;
  top: 403px;
  right: -90px;
  width: 180px;
  height: 180px;
  color: var(--black);
  background-color: #f9f8f4;
  text-decoration: none;
  font-size: 20px;
  z-index: 2;
  border-radius: 126.437px 82.1839px 139.08px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--text-font-family);
  border: none;
  outline: none;
  cursor: pointer;
}
.toastContainer {
  display: flex;
  align-items: center;
}
.signOut {
  width: 87px;
  height: 38px;
  border-radius: 10px;
  border: 1px solid #000000;
  font-family: var(--title-font-family);
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0a0a0a;
  background-color: #ffffff;
  cursor: pointer;
  margin: 0 10px;
}

.signOut:hover {
  background-color: #000000;
  color: #faf9f5;
}
.loader {
  width: 25px !important;
  height: 25px !important;
}
@media (max-width: 1400px) {
  .btnImg {
    top: 80px;
    right: 120px;
  }
}
@media (max-width: 1162px) {
  .btnImg {
    position: initial;
  }
  .btnImg,
  .trySection {
    margin: auto;
  }
  .optionDesc {
    padding-bottom: 32px;
  }
  .businessImg {
    width: 275px;
    height: 357px;
  }
  .learn {
    width: 108px;
    height: 108px;
    top: 0;
    right: -54px;
    font-size: 12px;
  }
}
@media (max-width: 570px) {
  .trySection {
    max-width: 250px;
  }
  .optionTitle {
    font-size: 32px;
  }
  .optionDesc {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .businessImg {
    width: 215px;
    height: 297px;
  }
  .learn {
    width: 78px;
    height: 78px;
    top: 0;
    right: -24px;
    font-size: 10px;
  }
}
