.apply {
  border-radius: 50px;
  outline: none;
  width: 144px;
  height: 48px;
  font-family: var(--title-font-family);
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #faf9f5;
  cursor: pointer;
  display: block;
  margin: 29px auto 0;
}
.apply:disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
}
.regularBg {
  background-color: var(--black);
  border: none;
}
.loadingBg {
  border: 1px solid var(--black);
  background-color: var(--lighter-gray-color) !important;
}
.loader {
  width: 25px;
  height: 25px;
}
