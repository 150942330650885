.shareBtn {
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.shareImg {
  width: 14.71px;
  height: 14.71px;
}
.onBoardingBtn {
  z-index: 11;
  pointer-events: none;
}
@media (hover: hover) and (pointer: fine) {
  .shareBtn:hover {
    background-color: rgba(10, 10, 10, 0.9);
  }
  .shareBtn:hover path {
    stroke: var(--light-gray-color);
  }
}
