.collectionCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 10px 83px 10px 0;
}

.name {
    font-family: var(--text-font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
    color: var(--black);
    padding-top: 7.75px;
    text-align: center;
  }
  .errorMessage {
    font-size: 22px;
  }
  .collectionImage {
    width: 260px;
    height: 341px;
    object-fit: cover;
  }
  