.browseAllContainer {
  width: 100%;
  background-color: #f9f8f4;
  margin-bottom: 28px;
  border-radius: 0px 128.38px 0px 0px;
  padding: 61px 0 69px;
}
.browseAllSection {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  padding: 0 31px;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 33px;
}
.sectionTitle {
  font-family: var(--title-font-family);
  font-size: 36.9px;
  color: var(--black);
  line-height: 55px;
  font-weight: 400;
}
.smallTitle {
  display: none;
}
.categories {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 26px;
}
.category {
  width: 238.18px;
  height: 139px;
  border-radius: 22.19px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}
.categoryImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f2f1ed;
  transition: all 0.5s;
}
.categoryTitle {
  font-family: var(--text-font-family);
  font-size: 21.52px;
  line-height: 39.94px;
  color: white;
  position: relative;
  letter-spacing: -0.02em;
  padding-top: 12px;
  z-index: 1;
  font-weight: 400;
}
.loader {
  width: 100px !important;
  height: 100px !important;
  margin: 100px auto;
}
