.editorPage {
  background-color: #f9f8f4;
  padding: 81px 0 110px;
}
.btn {
  outline: none;
  font-weight: 400;
  font-family: var(--text-font-family);
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  width: 267px;
  height: 68px;
  border-radius: 50px;
  font-size: 16px;
  margin: 23px 0 0 57px;
}
.btn:disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
}
.regularBg {
  background-color: var(--black);
  border: none;
}
.loadingBg {
  border: 1px solid var(--black);
  background-color: var(--lighter-gray-color);
}
.errorMessage {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ee4655;
  padding: 10px 0 0 26px;
  text-transform: uppercase;
}
