.userForm {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 62px 63px;
}
.form {
  display: flex;
  flex-direction: column;
  margin-top: 41px;
  width: 100%;
}
.group {
  display: flex;
  flex-direction: column;
  max-width: 662px;
  width: 100%;
  border-bottom: 4px solid #f2f1ed;
  padding: 27px 0 24px 0;
}
.textField input {
  width: 100%;
  max-width: 662px;
  height: 56px;
  padding: 13px 0 15px 24px;
  border: 1.5px solid #0a0a0a;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: var(--title-font-family);
  font-size: 20px;
  line-height: 28px;
  background-color: transparent;
}
.textField label {
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.06em;
  color: rgba(10, 10, 10, 0.4);
  margin-bottom: 20px;
  display: block;
}
.submitBtnWrapper {
  margin-top: 27px;
}
