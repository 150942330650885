.listSection {
  max-width: 1440px;
  margin: auto;
  padding: 0 16px 16px;
}
.card {
  width: 100%;
  height: 677px;
  background: linear-gradient(0deg, #ff6e26, #ff6e26);
  border-radius: 0px 0px 76px 0px;
  background-blend-mode: overlay, normal;
  overflow: hidden;
  position: relative;
  padding: 104px 45px 0 44px;
}

.overlay {
  background: url(../../../assets/images/listOverlay.png);
  width: 1984.3px;
  height: 1630.51px;
  position: absolute;
  top: 0;
  left: 0;
}
.mainText {
  position: relative;
  z-index: 2;
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 125px;
  line-height: 125px;
}
.wanna {
  display: block;
  color: #f2f1ed;
}
.textContainer {
  width: 100%;
  text-align: right;
}
.list {
  color: #f2f1ed;
}
.textContainer {
  position: relative;
}
.business::after,
.business::before,
.textContainer::after,
.textContainer::before,
.extraAfter::after {
  position: absolute;
  content: "business?";
  right: 0;
  overflow: hidden;
  clip-path: polygon(0 94px, 100% 94px, 100% 100%, 0 100%);
}
.business::after {
  top: 20px;
}
.business::before {
  top: 40px;
}
.textContainer::before {
  top: 60px;
}
.textContainer::after {
  top: 80px;
}
.extraAfter::after {
  top: 100px;
}
.bottomContainer {
  position: relative;
  text-align: center;
  margin: 95px 0 0 auto;
  display: flex;
  column-gap: 20px;
  align-items: center;
  width: 50%;
}
.desc {
  font-family: var(--text-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--black);
  padding-bottom: 24px;
}
.btnTextContainer {
  transform: translateX(-50%);
}
.show {
  display: block;
}
.register {
  padding: 24px 32px 25px;
  background: #f2f1ed;
  border-radius: 34px;
  border: none;
  outline: none;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-family: var(--text-font-family);
  cursor: pointer;
}
.arrow {
  transform: translateX(-50%);
  height: 115px;
  margin-top: 5px;
}
@media (max-width: 1050px) {
  .mainText {
    font-size: 85px;
    line-height: 85px;
  }
  .business::after,
  .business::before,
  .textContainer::after,
  .textContainer::before,
  .extraAfter::after {
    position: absolute;
    content: "business?";
    right: 0;
    overflow: hidden;
    clip-path: polygon(0 60px, 100% 60px, 100% 100%, 0 100%);
  }
}
@media (max-width: 870px) {
  .register {
    padding: 14px 22px 15px;
    border-radius: 34px;
    font-size: 12px;
    line-height: 16px;
  }
  .desc {
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 14px;
  }
  .arrow {
    height: 85px;
  }
}
@media (max-width: 750px) {
  .card {
    height: 410px;
    padding: 54px 25px 0 24px;
  }

  .mainText {
    font-size: 55px;
    line-height: 55px;
  }
  .business::after,
  .business::before,
  .textContainer::after,
  .textContainer::before,
  .extraAfter::after {
    position: absolute;
    content: "business?";
    right: 0;
    overflow: hidden;
    clip-path: polygon(0 40px, 100% 40px, 100% 100%, 0 100%);
  }
  .business::after {
    top: 10px;
  }
  .business::before {
    top: 20px;
  }
  .textContainer::before {
    top: 30px;
  }
  .textContainer::after {
    top: 40px;
  }
  .extraAfter::after {
    top: 50px;
  }
  .bottomContainer {
    margin: 55px 0 0 auto;
    column-gap: 10px;
  }
  .register {
    padding: 8px 12px 9px;
    border-radius: 34px;
    font-size: 8px;
    line-height: 16px;
  }
  .desc {
    font-size: 10px;
    line-height: 13px;
    padding-bottom: 10px;
  }
  .arrow {
    height: 55px;
  }
}
@media (max-width: 500px) {
  .card {
    height: 310px;
    padding: 34px 25px 0 24px;
  }

  .mainText {
    font-size: 35px;
    line-height: 35px;
  }
  .business::after,
  .business::before,
  .textContainer::after,
  .textContainer::before,
  .extraAfter::after {
    position: absolute;
    content: "business?";
    right: 0;
    overflow: hidden;
    clip-path: polygon(0 25px, 100% 25px, 100% 100%, 0 100%);
  }
  .business::after {
    top: 10px;
  }
  .business::before {
    top: 20px;
  }
  .textContainer::before {
    top: 30px;
  }
  .textContainer::after {
    top: 40px;
  }
  .extraAfter::after {
    top: 50px;
  }
  .bottomContainer {
    margin: 55px 0 0 auto;
    column-gap: 10px;
    width: 70%;
  }
  .register {
    padding: 8px 12px 9px;
    border-radius: 34px;
    font-size: 8px;
    line-height: 16px;
  }
  .desc {
    font-size: 10px;
    line-height: 13px;
    padding-bottom: 10px;
  }
  .arrow {
    height: 55px;
  }
}
