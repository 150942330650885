.container {
  width: 100%;
  background-color: #383837;
}
.processContainer {
  border-top: 1px solid var(--black);
}
.firstSection {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
.secondSection {
  position: sticky;
  position: -webkit-sticky;
  top: 90px;
  z-index: 2;
  background-color: #383837;
}
.thirdSection {
  position: sticky;
  position: -webkit-sticky;
  top: 180px;
  z-index: 3;
  background-color: #383837;
}
.title {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--black);
  padding: 40px 8px 64px;
}
.content {
  max-width: 558px;
  margin: auto;
}
.mainDesc {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  color: #f2f1ed;
  padding: 18px 0 22px;
}
.content h3:not(:last-of-type) {
  border-bottom: 1px solid var(--black);
}
.content h3:last-of-type {
  padding-bottom: 89px;
}
.mainDesc span {
  display: block;
}
.subDesc,
.subDesc a {
  font-size: 26px;
  line-height: 38px;
  color: rgba(242, 241, 237, 0.7);
}
.textLogoContainer {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
@media (max-width: 750px) {
  .title {
    font-size: 10px;
    line-height: 15px;
    padding: 20px 8px 44px;
  }
  .content {
    max-width: 308px;
    margin: auto;
  }

  .mainDesc {
    font-size: 22px;
    line-height: 36px;
  }
  .subDesc,
  .subDesc a {
    font-size: 12px;
    line-height: 15px;
    color: rgba(242, 241, 237, 0.7);
  }
  .secondSection {
    top: 60px;
  }
}
