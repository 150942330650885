.commentContainer {
  min-height: 400px;
  background: #faf9f5;
  padding: 78px 61px 97px 33px;
  border-radius: 0px 173.759px 0px 0px;
}

.fullWidth {
  width: 100%;
}
.partialWidth {
  width: 45%;
  max-height: 1320px;
  overflow: auto;
}
.content {
  max-width: 1440px;
  margin: auto;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: var(--title-font-family);
  font-size: 47.9335px;
  line-height: 60px;
}
.noItemsText {
  font-size: 24px;
  font-family: var(--title-font-family);
  text-align: center;
  margin-top: 100px;
}
.commentsGrid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.loader {
  width: 70px;
  height: 70px;
  margin: 50px auto;
}
@media (max-width: 1300px) {
  .commentContainer {
    width: 100%;
    margin: 16px;
  }
}
@media (max-width: 600px) {
  .title {
    font-size: 28px;
  }
}
