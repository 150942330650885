.selectContainer {
  width: 274px;
  position: relative;
}
.selectContainer select {
  width: 100%;
  height: 56px;
  outline: none;
  border: 1.5px solid var(--black);
  border-radius: 10px;
  background-color: transparent;
  appearance: none;
  padding-left: 24px;
  font-family: var(--title-font-family);
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.07em;
}
.selectContainer label {
  padding-bottom: 20px;
  display: block;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.06em;
  color: rgba(10, 10, 10, 0.4);
}
.down {
  position: absolute;
  right: 16px;
  bottom: 24px;
  pointer-events: none;
}
