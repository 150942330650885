.optionsContainer {
  width: 484px;
  margin: auto;
  text-align: center;
}
.header {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: var(--black);
}
.optionsContainer button {
  width: 100%;
  height: 56px;
  border-radius: 200px;
  outline: none;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
}
.google {
  border: 1.5px solid rgba(10, 10, 10, 0.1);
  background-color: var(--white);
  color: var(--black);
  margin: 43px 0 23px;
}
.fb {
  background-color: #1977f3;
  color: var(--white);
  border: none;
}
.googleIcon {
  position: absolute;
  left: 17px;
  top: 12px;
  width: 32px;
  height: 29px;
}
.fbIcon {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 27px;
  height: 27px;
}
.register {
  font-size: 16px;
  color: var(--black);
}
.loadingState {
  pointer-events: none;
}
.whiteBg {
  background-color: #ffffff;
}
.loader {
  width: 30px;
  height: 30px;
}
.loader::after {
  background-color: #ffffff;
}
@media (max-width: 550px) {
  .optionsContainer {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .optionsContainer button {
    font-size: 12px;
    padding-left: 20px;
  }
}
