.modal {
  background-color: var(--lighter-gray-color);
  padding: 12px 24px 40px 26px;
  border-radius: 0px 34px 0px 0px;
  margin: 0 auto;
  position: relative;
  top: 20%;
  z-index: 11;
}
@media (max-width: 500px) {
  .modal {
    padding: 12px 20px 20px 20px;
  }
}
