.popularSection {
  width: 100%;
  background-color: #f9f8f4;
  margin: 21.53px 0;
  border-radius: 0px 152.981px 0px 0;
  padding: 75px 0 109px;
}
.popularContainer {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  padding: 0 31px;
}
.sectionTitle {
  font-family: var(--title-font-family);
  font-size: 36.9px;
  color: var(--black);
  line-height: 55px;
  font-weight: 400;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36.9px;
}

.items {
  max-width: 1100px;
  height: 800px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.item {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.item0 {
  order: 1;
  flex: 0 0 100%;
  margin-right: 10px;
}
.item1 {
  order: 2;
  flex: 0 0 50%;
}
.item2 {
  order: 2;
  flex: 0 0 50%;
}
.item3 {
  order: 3;
  flex: 0 0 50%;
}
.item4 {
  order: 3;
  flex: 0 0 50%;
}
.product,
.business,
.price {
  font-family: var(--text-font-family);
  font-weight: 400;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.itemImg {
  width: 199.88px;
  height: 259.84px;
  object-fit: cover;
}
.item1,
.item2,
.item3,
.item4 {
  width: 199.88px;
  height: 259.84px;
}
.firstItemImg,
.item0 {
  width: 405.13px;
  height: 526.67px;
  object-fit: cover;
}
.title {
  color: var(--black);
  font-size: 21.5px;
  line-height: 25.2px;
  font-family: var(--title-font-family);
}
.subTitle {
  max-width: 316px;
  padding: 8px 0;
  font-size: 15.38px;
  line-height: 22.29px;
  color: rgba(10, 10, 10, 0.6);
  font-family: var(--text-font-family);
  margin: auto;
  letter-spacing: -0.02em;
}
.imgContainer {
  position: relative;
}
.business {
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  padding-top: 15px;
}
.product {
  padding: 8px 0 4px;
  font-size: 20px;
  line-height: 27px;
  color: var(--black);
  text-transform: capitalize;
}
.price {
  color: var(--black);
  font-size: 20px;
  line-height: 25px;
}
.imgContainer {
  position: relative;
}
.upVote {
  width: 37.67px;
  height: 37.67px;
  border-radius: 56px;
  backdrop-filter: blur(4px);
  border: 1px solid #deddd9 !important;
  position: absolute;
  right: -7.69px;
  bottom: -6.92px;
}
.firstUpVote {
  width: 56.89px;
  height: 56.89px;
  backdrop-filter: blur(6.04082px);
  border: 1px solid #deddd9;
  border-radius: 84.5714px;
  position: absolute;
  bottom: 0;
  right: -15.83px;
}
.addToCollection {
  width: 21.52px;
  height: 21.52px;
  backdrop-filter: blur(1.64706px);
  border-radius: 20.5882px;
  border: 1px solid #deddd9;
  position: absolute;
  right: -7.69px;
  bottom: 36.9px;
}
.firstAddToCollection {
  width: 32.51px;
  height: 32.51px;
  border: 1px solid #deddd9;
  backdrop-filter: blur(2.48739px);
  border-radius: 31.0924px;
  position: absolute;
  right: -15.83px;
  bottom: 65.99px;
}
