.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 0 40px 0;
  border-bottom: 1px solid #f2f1ed;
}
.legend,
.textField label {
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.06em;
  color: rgba(10, 10, 10, 0.4);
  margin-bottom: 20px;
  display: block;
}
.typesWrapper {
  display: flex;
}
.radioContainer {
  margin-right: 36px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-right: 38px;
}
.radioContainer label {
  font-family: var(--title-font-family);
  font-size: 26px;
  line-height: 30px;
  margin-left: 10px;
}
.radioContainer input {
  cursor: pointer;
  width: 28px;
  height: 28px;
  z-index: 1;
  opacity: 0;
}
.radioContainer input:disabled {
  cursor: default;
}
.radioContainer span {
  position: absolute;
  left: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid rgba(10, 10, 10, 0.39);
  background-color: transparent;
}

.checkMark {
  background-color: var(--black) !important;
}
.radioContainer input:checked ~ .checkMark {
  background-color: var(--black);
}
.radioContainer input:disabled ~ .checkMark {
  background-color: rgba(10, 10, 10, 0.4) !important;
}
.textField input {
  width: 100%;
  max-width: 662px;
  height: 56px;
  padding: 13px 0 15px 24px;
  border: 1.5px solid #0a0a0a;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: var(--title-font-family);
  font-size: 20px;
  line-height: 28px;
  background-color: transparent;
}
.textField input::placeholder {
  font-family: var(--title-font-family);
  font-size: 20px;
  line-height: 28px;
  color: var(--black);
}
.dropZone > label:last-of-type > div {
  max-width: 662px;
  margin: 0 0 30px 0;
}
.dropZone {
  width: 100%;
  max-width: 662px;
}
.addCategoryBtn {
  width: 235px;
  height: 68px;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid var(--black);
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--black);
  cursor: pointer;
}
.previewsContainer {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  gap: 10px;
  max-width: 700px;
}

.previewsContainer > .imageContainer {
  position: relative;
}

.productImg {
  width: 120px;
  height: 120px;
  border: 1px solid var(--black);
  cursor: pointer;
}

.imageInputWrapper {
  display: flex;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.layer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(250, 249, 245, 0.7),
    rgba(250, 249, 245, 0.7)
  );
  border: 3px solid #1977f3;
}
.remove {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 50px;
  height: 50px;
  z-index: 3;
  cursor: pointer;
}
.deleteBtnWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deleteItemBtn {
  width: 200px;
  border: 1px solid #0a0a0a;
  border-radius: 10px;
  margin: 40px 0;
  background-color: transparent;
  padding: 12px;
  cursor: pointer;
  font-family: "Arial";
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0a0a0a;
}
.selectSubCategoryError {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ee4655;
  margin: 8px 0;
}
.imgLoader::after {
  background-color: #ffffff;
}
