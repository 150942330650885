.countdown {
  width: 100%;
  position: relative;
  z-index: 6;
}
.container {
  background-color: #383837;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid var(--black);
}
.content {
  padding: 17px 32px 16px;
  display: flex;
  justify-content: center;
  max-width: 1440px;
  margin: auto;
}
div.primaryBg {
  background-color: #ff6e26;
}
@media (max-width: 800px) {
  .content {
    padding: 12px 10px 11px;
  }
}
