.confirmContainer {
  width: 100%;
  text-align: center;
}
.header {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: var(--black);
  padding: 128px 0 21px;
}
.subTitle {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #0a0a0a;
  padding-bottom: 99px;
  display: flex;
  justify-content: center;
}
.resent {
  position: relative;
  display: block;
  padding: 0 5px;
}
.inpContainer {
  width: 663px;
  max-width: 100%;
  margin: auto;
  text-align: left;
}
.inpContainer label {
  display: block;
  padding-bottom: 16px;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: rgba(1, 3, 2, 0.7);
}
.inpContainer input {
  width: 100%;
  height: 56px;
  outline: none;
  border: 1.5px solid #0a0a0a;
  box-sizing: border-box;
  border-radius: 10px;
  color: rgba(10, 10, 10, 0.4);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding-left: 24px;
  padding-bottom: 4px;
}
.inpContainer input::placeholder {
  color: rgba(10, 10, 10, 0.4);
}
.resendText {
  width: 663px;
  max-width: 100%;
  margin: auto;
  text-align: left;
  padding: 24px 0 211px;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
}
.resendBtn {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  text-decoration: underline;
}
invalid label {
  color: #ee4655;
}
.invalid input {
  margin-bottom: 8px;
  border: 1.5px solid #ee4655;
  color: #ee4655;
}
.invalid input::placeholder {
  color: #ee4655;
}
.finishBtn {
  width: 313px;
  max-width: 100%;
  height: 60px;
  border-radius: 50px;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 62px;
}
@media (max-width: 710px) {
  .header {
    font-size: 31px;
    line-height: 36px;
  }
  .confirmContainer {
    padding: 0 31px;
  }
  .subTitle,
  .resendText,
  .inpContainer input,
  .inpContainer label {
    font-size: 14px;
    line-height: 20px;
  }
  .subTitle {
    display: block;
  }
  .resent {
    display: initial;
    padding: 0;
  }
  .resendText {
    padding: 24px 0 111px;
  }
  .finishBtn {
    height: 52px;
  }
}
