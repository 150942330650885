.businessHoursContainer {
  display: flex;
  flex-direction: column;
}
.businessContainer {
  background-color: #faf9f5;
  border-radius: 0px 170px 0px 0px;
  padding: 72px 50px 24px 50px;
  margin-bottom: 4px;
}
.businessTitle {
  font-family: var(--title-font-family);
  font-size: 49px;
  line-height: 62px;
  margin-bottom: 32px;
}
.title {
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 24px;
  text-transform: uppercase;
}
.daysList {
  margin-bottom: 48px;
}
.day {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  max-width: 420px;
}
.day p {
  font-family: var(--title-font-family);
  font-size: 20.6805px;
  line-height: 32px;
}
.time {
  width: 183px;
  text-align: left;
}
@media (max-width: 650px) {
  .businessContainer {
    border-radius: 0;
    padding: 40px 20px 0px 20px;
    margin: 4px 0;
  }
  .day p {
    font-size: 16px;
  }
  .title {
    font-size: 12px;
  }
  .businessTitle {
    font-size: 28px;
    line-height: 34px;
  }
}
