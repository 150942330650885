.typesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 70px;
  gap: 20px;
}

.typesContainer input {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.typePill {
  position: relative;
  height: 84px;
  width: 320px;
  border: 1.43182px solid var(--black);
  box-sizing: border-box;
  border-radius: 15.2727px;
  color: var(--text);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
}
.loader {
  height: 84px;
  width: 320px;
  border-radius: 15.2727px;
}
.typePill > span:last-of-type {
  position: absolute;
  pointer-events: none;
  right: 100px;
  top: 25px;
}

.typePillChecked {
  color: white;
  background-color: var(--black);
}
