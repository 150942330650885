.onboardingContainer {
  cursor: auto;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
}
.onboardingContainer p {
  font-family: var(--title-font-family) !important;
  text-align: left;
  font-size: 17px;
  line-height: 19px;
}
.onboardingContainer p,
.onboardingContainer a {
  color: black !important;
}
.onboardingContainer svg {
  width: unset !important;
  height: unset !important;
}
.onboardingContainer p:nth-of-type(2) {
  margin: initial !important;
}
.onboardingContainer > p {
  margin: unset !important;
}
.onboardingHeading {
  margin-bottom: 9px !important;
  font-size: 20px !important;
  line-height: 23px;
}
.onboardingText {
  width: 264px;
  font-size: 16px !important;
  line-height: 24px;
  letter-spacing: -0.02em;
}
.buttonGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.buttonGroup > * {
  cursor: pointer;
}
.NextArrow {
  padding-top: 5%;
}
.buttonGroup > a {
  text-decoration: underline;
  font-family: var(--title-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-align: left;
}
.finishButton {
  width: 144px;
  height: 48px;
  border-radius: 50px;
  font-size: 12px;
  line-height: 19px;
  display: block;
}
