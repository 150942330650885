.termsContainer {
  width: 100%;
  text-align: center;
}
.btnContainer {
  width: 100%;
  text-align: left;
  margin-top: 40px;
}
.termsTextContainer {
  max-height: 300px;
  overflow-y: scroll;
}
.backBtn {
  width: 50px;
  height: 20px;
  cursor: pointer;
}
.header {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: var(--black);
  padding: 68px 0 39px;
}
.termsText {
  width: 1079px;
  max-width: 100%;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: left;
  margin: auto;
}
.termsSubtitle {
  width: 1079px;
  max-width: 100%;
  font-family: var(--text-font-family);
  font-weight: bold;
  font-size: 20px;
  text-decoration: underline;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: left;
  margin: auto;
  margin-top: 2%;
}
.termsList {
  max-width: 100%;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: left;
  margin: 2% 0 0 110px;
  
}
.inpContainer {
  width: 221px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  margin: 10px auto 10px;
  position: relative;
}
.inpContainer label {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
}
.inpContainer input {
  opacity: 0;
  width: 23px;
  height: 23px;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.inpContainer span {
  position: absolute;
  left: 0;
  width: 23px;
  height: 23px;
  border-radius: 4px;
  border: 1px solid rgba(10, 10, 10, 0.39);
  background-color: transparent;
}
.checkMark {
  background-color: var(--black) !important;
}
.checkMark::before {
  content: "";
  position: absolute;
  display: block;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.finishBtn {
  width: 313px;
  max-width: 100%;
  height: 60px;
  border-radius: 50px;
  font-size: 12px;
  line-height: 16.92px;
}
.errorMessage {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ee4655;
  padding-bottom: 8px;
}
@media (max-width: 700px) {
  .header {
    font-size: 28px;
    line-height: 32px;
  }
  .termsText {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
  .inpContainer {
    margin: 155px auto 20px;
  }
}
