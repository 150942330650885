.collectionsSection{
    width:100%;
    min-height: 400px;
    background: #FAF9F5;
    padding:78px 79px 114px 74px;
    margin-right: 9px;
    margin-top: 23px;
    
}
.header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title{
    font-family: var(--title-font-family);
    font-size: 47.9335px;
    line-height: 60px;
}
.noItemsText{
    font-size: 24px;
    font-family: var(--title-font-family);
    text-align: center;
    margin-top:100px;
   
}
.itemsList{
    display: flex;
    margin-top: 55px;
    flex-wrap: wrap;
}