.pick {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.product,
.business,
.price {
  font-family: var(--text-font-family);
  font-weight: 400;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}
.pickImg {
  width: 261px;
  height: 343px;
  overflow: hidden;
  object-fit: cover;
}
.business {
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  padding-top: 20px;
}
.product {
  padding: 8px 0;
  font-size: 20px;
  line-height: 27px;
  color: var(--black);
  text-transform: capitalize;
}
.price {
  color: var(--black);
  font-size: 20px;
  line-height: 21px;
}
.imgContainer {
  position: relative;
}
.upVote {
  width: 49px;
  height: 49px;
  border-radius: 56px;
  backdrop-filter: blur(4px);
  border: 1px solid #deddd9 !important;
  position: absolute;
  right: -10px;
  bottom: -9px;
}
.addToCollection {
  width: 28px;
  height: 28px;
  backdrop-filter: blur(1.64706px);
  border: 1px solid #deddd9 !important;
  border-radius: 20.5882px;
  border: 1px solid #deddd9;
  position: absolute;
  right: -10px;
  bottom: 48px;
}
@media (max-width: 720px) {
  .pickImg {
    width: 150px;
    height: 197px;
  }
  .business,
  .product,
  .price {
    width: 159px;
  }
  .business {
    padding-top: 9px;
  }
  .product {
    padding: 0;
    font-size: 16px;
    line-height: 27px;
  }
  .price {
    font-size: 14px;
    line-height: 21px;
  }
}
