.inpContainer {
  width: 309px;
  height: 215px;
  margin: auto;
}
.inpLabel {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #0a0a0a;
  padding-bottom: 48px;
}
.changeImg {
  display: none;
}
.inpContainer input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 215px;
  height: 215px;
  border-radius: 157.5px;
  cursor: pointer;
}
.inpContainer label {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 215px;
  height: 215px;
  border: 1px dashed transparent;
  background: linear-gradient(#f9f8f4, #f9f8f4) padding-box padding-box,
    repeating-conic-gradient(
        var(--c) 0 calc(360deg / var(--n) - var(--d)),
        transparent 0 calc(360deg / var(--n))
      )
      border-box;
  --d: 4deg;
  --n: 30;
  --c: #000;
  border-radius: 157.5px;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  color: #0a0a0a;
  cursor: pointer;
}
.invalid label {
  background: linear-gradient(#f9f8f4, #f9f8f4) padding-box padding-box,
    repeating-conic-gradient(
        var(--c) 0 calc(360deg / var(--n) - var(--d)),
        transparent 0 calc(360deg / var(--n))
      )
      border-box;
  --d: 4deg;
  --n: 30;
  --c: #ee4655;
  color: #ee4655;
}
.profileImage {
  margin: auto;
  width: 215px;
  height: 215px;
  border-radius: 157.5px;
  display: block;
  margin: 0 20px;
}
.avatarTooltip {
  position: absolute;
  top: -50px;
  left: 125%;
}
.avatarTooltip::before {
  left: -20px;
  top: 50px;
}
.profilePic {
  width: 39.94px;
  height: 39.94px;
  border-radius: 50%;
  margin-right: 7.99px;
}

.editImg {
  border-radius: 50%;
  z-index: 2;
  position: relative;
  cursor: pointer;
  background: #faf9f5;
}
.container {
  position: relative;
}
