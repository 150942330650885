.closeBtn {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.close {
  width: 100%;
  height: 100%;
}
