.listContainer {
  width: 100%;
  position: absolute;
  top: 110%;
  background: #f9f8f4;
  border: 1px solid #f2f1ed;
  box-shadow: 6px 6px 16px rgba(10, 10, 10, 0.1);
  border-radius: 24px;
  padding: 36px 0 42px;
  z-index: 2;
  list-style: none;
}
.link {
  padding: 10px 22px 10px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: var(--black);
}

.link:hover,
.active {
  background-color: #ffffff;
}
.searchLink {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: var(--black);
  padding: 32px 0 32px 32px;
  display: block;
}
.empty {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 20px;
  color: gray;
  line-height: 28px;
  padding: 0 0 10px 32px;
}
