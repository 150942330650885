.headerSection {
  background: #f2f1ed;
  position: relative;
  width: 100%;
}
.noOnboarding {
  overflow: hidden;
}
.editHeader {
  padding: 121px 0 49px;
}
.regHeader {
  padding: 62px 0 49px;
}
.wrapper {
  max-width: 1300px;
  margin: auto;
  position: relative;
  display: flex;
  gap: 65px;
  padding: 0 32px;
  align-items: flex-start;
}
.whiteBg {
  position: absolute;
  top: 0;
  left: -10%;
  width: 120%;
  height: 80%;
  border-bottom-left-radius: 50% 45%;
  border-bottom-right-radius: 50% 45%;
  background-color: var(--lighter-gray-color);
}
.editBtn {
  position: absolute;
  top: 30px;
  right: 30px;
}

@media (max-width: 1180px) {
  .wrapper {
    width: 1030px;
  }
}
@media (max-width: 1010px) {
  .regHeader {
    padding: 32px 0 49px;
  }
  .wrapper {
    flex-direction: column-reverse;
  }
}
