.header {
  padding: 0 68px 0 30px;
}
.secondRow {
  width: 100%;
  margin: 21px 0 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchInp {
  width: 523px;
  position: relative;
  display: flex;
  z-index: 3;
}
.searchInp input {
  width: 100%;
  height: 56px;
  border: 1.5px solid rgba(10, 10, 10, 0.25);
  border-radius: 200px;
  font-family: var(--title-font-family);
  font-weight: 400;
  color: #020202;
  padding-left: 56px;
  font-size: 20px;
}
.searchInp input:focus {
  border: 1.5px solid #0a0a0a;
}
.select {
  margin: 0 0 41px 21px;
}
