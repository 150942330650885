.judgesSection {
  max-width: 1440px;
  margin: 152px auto 0;
  padding-left: 64px;
}
.desc {
  font-family: var(--text-font-family);
  font-style: normal;
  margin-top: 120px;
}
.title {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(242, 241, 237, 0.7);
  font-weight: 300;
  margin-bottom: 40px;
}
.judgesContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 980px) {
  .judgesSection {
    padding: 0 0 0 20px;
  }
  .desc {
    margin-top: 40px;
  }
  .title {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 20px;
  }
}
@media (max-width: 630px) {
  .desc {
    margin-top: 0px;
  }
  .title {
    margin-bottom: 20px;
  }
}
