.navItems {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 29px 0 12px;
  list-style: none;
  margin: auto;
}
.item {
  position: relative;
}
.item a {
  text-decoration: none;
  color: rgba(1, 3, 2, 0.7);
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 12px;
  padding: 22.7px 30px 10px;
}
.link:hover {
  border-bottom: 1.6px solid #020202;
  color: #010302;
}
.active {
  border-bottom: 1.6px solid #020202;
  color: #010302 !important;
}
