.registerPage {
  background-color: #f9f8f4;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.registerContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 50px;
}

.progressBarContainer {
  height: 4px;
  width: 100%;
  background-color: "#c9c8c5";
}

.filledProgressBar {
  height: 100%;
  background-color: black;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.pageLoader {
  margin-top: 200px;
  width: 100px;
  height: 100px;
}
.pageLoader::after {
  background-color: #f2f1ed;
}
.onboardingBtnContainer {
  width: 100%;
  text-align: left;
  padding: 50px 50px 80px;
}
.onboardingBackBtn {
  width: 50px;
  height: 20px;
  cursor: pointer;
}
