.browseAllContainer {
  width: 100%;
  background-color: #f9f8f4;
  margin-bottom: 28px;
  border-radius: 0px 167px 0px 0px;
  padding: 89px 0 90.5px;
}
.browseAllSection {
  width: 100%;
  max-width: 1380px;
  margin: auto;
  padding: 0 31px;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 51px;
}
.sectionTitle,
.smallTitle {
  font-family: var(--title-font-family);
  font-size: 48px;
  color: var(--black);
  line-height: 72px;
  font-weight: 400;
}
.smallTitle {
  display: none;
}
.categories {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 26px;
}
.category {
  width: 309.83px;
  height: 180.89px;
  border-radius: 28.8659px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}
.categoryImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f2f1ed;
  transition: all 0.5s;
}
.categoryTitle {
  font-family: var(--text-font-family);
  font-size: 28px;
  line-height: 52px;
  color: white;
  position: relative;
  letter-spacing: -0.02em;
  padding-top: 12px;
  z-index: 1;
  font-weight: 400;
}
.loader {
  width: 100px !important;
  height: 100px !important;
  margin: 100px auto;
}
.bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 1397px) {
  .browseAllSection {
    max-width: 1300px;
  }
  .category {
    width: 285.83px;
    height: 170.89px;
  }
  .categoryTitle {
    font-size: 24px;
    line-height: 46px;
  }
}
@media (max-width: 1076px) {
  .browseAllContainer {
    text-align: center;
  }
  .categories {
    justify-content: center;
  }
  .sectionTitle {
    font-size: 32px;
  }
}
@media (max-width: 710px) {
  .sectionTitle {
    font-size: 22px;
  }
  .header {
    padding: 0 20px;
  }
  .browseAllContainer {
    border-radius: 0px 111px 0px 0px;
  }
}
@media (max-width: 539px) {
  .sectionTitle {
    display: none;
  }
  .smallTitle {
    display: initial;
    font-size: 32px;
  }
  .categories {
    padding: 0;
  }
}
