.alertContainer {
  padding: 34px 0 26px;
}
.title {
  font-family: "Arial";
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}
.text {
  font-family: "Arial";
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #0a0a0a;
  max-width: 248px;
  margin: 25px auto 5px;
}
.delete,
.cancel {
  width: 100%;
  outline: none;
  background-color: transparent;
  border-top: 1px solid #f2f1ed;
  border-right: none;
  border-left: none;
  border-bottom: none;
  font-family: "Arial";
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
}
.delete {
  padding: 22px 0 24px;
  color: #bf3e35;
}
.cancel {
  padding-top: 26px;
}
.errorMessage {
  padding: 0;
  font-size: 22px;
}
