.mapContainer {
  background-color: #f2f1ed;
  position: relative;
}
.containerElement {
  width: 100%;
  height: calc(100vh - 160px);
}
.loader {
  margin-top: 40vh;
  width: 100px;
  height: 100px;
}
@media (max-width: 998px) {
  .containerElement {
    height: calc(100vh - 130px);
  }
}
@media (max-width: 710px) {
  .containerElement {
    height: calc(100vh - 86px);
  }
}
