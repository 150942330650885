.popUp {
  width: 375px;
  top: 20%;
}
.lessTop {
  top: 5%;
}
.add {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.popUpHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 20px;
  line-height: 34px;
  font-family: var(--title-font-family);
  color: #020202;
  font-weight: 400;
}

.brdr {
  width: 100%;
  height: 1px;
  background: #f2f1ed;
  margin: 16px 0 34px;
}
.collections {
  width: 100%;
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
  min-height: 198px;
  max-height: 430px;
  overflow: auto;
}
.collection {
  text-align: center;
  cursor: pointer;
}
.singleItem {
  margin: auto;
}
.name {
  font-family: var(--text-font-family);
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: var(--black);
  padding-top: 7.75px;
}
.errorMessage {
  font-size: 22px;
}
.loader {
  width: 70px;
  height: 70px;
}
.collectionImage {
  width: 126px;
  height: 165.25px;
}
.update {
  display: block;
  margin: 30px auto 0;
  outline: none;
  border: none;
  cursor: pointer;
  color: #000000;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 14px;
  background-color: transparent;
}
@media (max-width: 500px) {
  .popUp {
    max-width: 90%;
  }
  .title {
    font-size: 28px;
    line-height: 34px;
  }
}
@media (max-width: 390px) {
  .collections {
    justify-content: center;
  }
}
