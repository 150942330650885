.controlsFormContainer {
  position: absolute;
  bottom: 23px;
  left: 33px;
  z-index: 2;
  display: flex;
  gap: 12px;
}
.controls {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.controls button {
  width: 53px;
  height: 53px;
  background: #faf9f5;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
}
.firstArrow,
.secondArrow {
  width: 15px;
  height: 19px;
  position: absolute;
}
.secondArrow {
  top: 9px;
  right: 9.2px;
}
.firstArrow {
  bottom: 10px;
  left: 11px;
}
.rotateIcon {
  transform: rotate(180deg);
}
.plus {
  width: 18px;
  height: 18px;
}
.minus {
  width: 18px;
  height: 3px;
}
.inpContainer {
  width: 226px;
  margin: auto;
  position: relative;
}
.formContainer {
  width: 264px;
  padding: 17px 0 25px;
  background: #faf9f5;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.inpContainer input {
  width: 100%;
  height: 48px;
  background-color: transparent;
  border: 1px solid #000000;
  border-radius: 8px;
  outline: none;
  padding-left: 15px;
}
.inpContainer input::placeholder {
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}
.submitBtn {
  width: 226px;
  height: 68px;
  border: 1px solid #000000;
  border-radius: 50px;
  outline: none;
  background-color: transparent;
  display: block;
  margin: 25px auto 0;
  cursor: pointer;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0a0a0a;
}
.placesContainerClass {
  width: 100%;
  position: absolute;
  top: 55px;
  left: 0;
  max-height: 130px;
  overflow: auto;
}
@media (max-width: 400px) {
  .controlsFormContainer {
    left: 3px;
  }
  .formContainer {
    width: 200px;
  }
  .inpContainer {
    width: 190px;
  }
  .submitBtn {
    width: 190px;
    height: 50px;
  }
  .controls {
    gap: 5px;
  }
  .inpContainer input::placeholder {
    font-size: 12px;
  }
}
