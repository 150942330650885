.title {
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.06em;
  font-weight: 400;
  color: rgba(10, 10, 10, 0.4);
}
.show {
  padding-bottom: 9px;
}
.hide {
  padding-bottom: 85px;
}
.businesses {
  padding-left: 64px;
  position: relative;
}
.showBtn {
  display: block;
  outline: none;
  border: none;
  background-color: transparent;
}
.mainBusiness {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 48px;
}
.arrow {
  margin: 7px 0 0 12px;
  width: 8.86px;
  height: 10.12px;
}
.mainText {
  font-family: var(--text-font-family);
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.06em;
  font-weight: 400;
  color: var(--black);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}
.loader {
  width: 30px;
  height: 30px;
  margin: 10px auto 40px;
}
