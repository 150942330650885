.navigation {
  width: 100%;
  background: #f9f8f4;
  box-shadow: 6px 6px 16px rgba(230, 229, 225, 0.5);
  transition: transform 0.4s ease-in-out;
  position: relative;
}
.regular {
  z-index: 3;
}
.navHeader {
  display: flex;
  min-width: 1080px;
  margin: auto;
  align-items: center;
  padding: 23px 31px 0;
}
.logo {
  width: 45px;
  height: 45px;
}
.forBusinessHeader {
  font-family: var(--title-font-family);
  font-size: 36px;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
  flex-grow: 1;
}
.inpContainer {
  width: 95%;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 58px 0 16px;
}
.searchBar {
  width: 100%;
  height: 43px;
  outline: none;
  border: 1.5px solid rgba(10, 10, 10, 0.25);
  border-radius: 200px;
  padding-left: 24px;
  padding-bottom: 4px;
  font-size: 21px;
  font-family: var(--title-font-family);
  font-weight: 400;
  color: var(--black);
}
.authState {
  min-width: 62px;
  text-align: right;
}
.loader {
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  background: #f2f1ed;
  float: right;
}
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100px);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
}
.collections {
  cursor: pointer;
  stroke: var(--black);
}
.bookmarked path {
  fill: var(--black);
}
.collections:hover path {
  fill: var(--black);
}
