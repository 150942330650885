.bodyContainer {
  width: 100%;
  min-height: 756px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.categoriesContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-block: 20px;
  flex-wrap: wrap;
}
.pillContainer {
  border: 0.75px solid var(--black);
  border-radius: 50px;
  font-family: var(--title-font-family);
  font-size: 16px;
  padding-inline: 5px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-align: left;
}
.typePill {
  background: var(--black);
  color: white;
}
.categoryPill {
  background: white;
  color: var(--black);
}
.businessTitle {
  font-family: var(--title-font-family);
  font-size: 45px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.08em;
  text-align: left;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.addressContainer {
  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}
.iconsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
}
.icon {
  outline: none;
  border: none;
  width: 50px;
  height: 50px;
  background-color: #faf9f5;
  border-radius: 50%;
}
.carouselContainer {
  position: relative;
  width: 100%;
  max-width: 345px;
}
.carouselContainer > div:first-of-type > a {
  display: none;
}
.websiteContainer {
  position: absolute;
  top: -40px;
  right: 0px;
  z-index: 5;
}
.upperBody {
  padding-left: 15px;
  padding-top: 45px;
  width: 100%;
}
.rightArrowContainer,
.leftArrowContainer {
  width: 30px !important;
  height: 30px !important;
  top: 252px !important;
}
.rightArrowContainer {
  right: 0px !important;
}
.leftArrowContainer {
  left: 0px !important;
}
.rightArrowContainer > svg,
.leftArrowContainer > svg {
  width: 11px !important;
  height: 12px !important;
}
.slideContainer {
  display: flex;
  overflow: hidden;
  justify-content: center;
}
.slideContainer > img {
  width: 100%;
  border-radius: 500px 500px 90px 90px;
  margin-top: 30px;
  height: 350px;
}
