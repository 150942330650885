.discoverPage {
  background-color: #f2f1ed;
  min-height: calc(100vh - 161px);
}
.overFlowContainer {
  position: relative;
  overflow: hidden;
}
.imgBtnContainer {
  position: relative;
  width: 671px;
  height: 1059px;
}
.adImg {
  width: 671px;
  height: 1059px;
  position: absolute;
  left: 0;
  bottom: -175px;
}
.learn {
  width: 180px;
  height: 180px;
  background-color: var(--black);
  color: #f9f8f4;
  text-decoration: none;
  position: absolute;
  font-size: 20px;
  z-index: 1;
  left: 595px;
  bottom: 333px;
  border-radius: 126.437px 82.1839px 139.08px;
  font-family: var(--text-font-family);
  border: none;
  outline: none;
  cursor: pointer;
}

.learn:hover {
  background-color: #ff6e26;
}
@media (max-width: 1400px) {
  .adImg {
    width: 601px;
    height: 979px;
  }
  .learn {
    left: 520px;
    width: 160px;
    height: 160px;
    font-size: 18px;
  }
}

@media (max-width: 1300px) {
  .adImg {
    width: 371px;
    height: 759px;
  }
  .learn {
    left: 295px;
    width: 130px;
    height: 130px;
    font-size: 14px;
  }
}
@media (max-width: 800px) {
  .adImg,
  .learn {
    display: none;
  }
}
