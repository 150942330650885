.reUploadContainer {
  width: 100%;
  position: relative;
}
.uploadWarning {
  position: absolute;
  font-size: 16px;
  font-family: var(--title-font-family);
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  background: rgba(245, 6, 6, 0.6);
  border-radius: 28px;
  padding: 17px 25px;
  right: 64px;
  top: 63px;
}
.loader::after {
  background-color: #f2f1ed;
}
