.uploadContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 850px;
  margin-top: 20px;
  padding: 0 50px;
}
.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 72px;
}
.title {
  font-family: var(--title-font-family);
  font-size: 48px;
  line-height: 52px;
  color: var(--black);
  margin-bottom: 18px;
  font-weight: unset;
}
.description {
  font-family: var(--title-font-family);
  font-size: 20px;
  line-height: 32px;
  max-width: 505px;
}
.legend {
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.06em;
  color: rgba(10, 10, 10, 0.4);

  margin-bottom: 24px;
}
.dropZone > label:last-of-type > div {
  max-width: 699px;
  margin: 0 0 30px 0;
}
.dropZone {
  width: 100%;
  max-width: 699px;
}
.previewsContainer {
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  gap: 10px;
}

.previewsContainer > .imageContainer {
  position: relative;
}

.previewsContainer > .imageContainer > img {
  width: 120px;
  height: 120px;
  border: 1px solid var(--black);
}

.previewsContainer > .imageContainer > svg {
  cursor: pointer;
  position: absolute;
  right: -25px;
  top: -20px;
}

.buttonsWrapper {
  margin: 49px 0;
}
.warningWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
}
.warning {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: var(--text-font-family);
  color: rgba(1, 3, 2, 0.7);
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  max-width: 400px;
}
.warningIcon {
  margin-right: 8px;
}
.verifyBtn {
  width: 235px;
  height: 68px;
  border: 1px solid #0a0a0a;
  border-radius: 10px;
  margin: 40px 0;
  background-color: transparent;
  cursor: pointer;
  font-family: "Arial";
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0a0a0a;
}
.verifyLoader::after {
  background-color: #ffffff;
}
.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  border-top: 4px solid #f2f1ed;
}
.imageInputWrapper {
  display: flex;
  max-width: 800px;
  justify-content: space-between;
}
.reUploadLoader::after {
  background-color: #f2f1ed;
}
