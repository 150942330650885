.footerSection {
  width: 100%;
  background-color: #f2f1ed;
}
.container {
  padding: 67px 20px 61px;
  width: 100%;
  border-radius: 0px 58.425px 0px 0px;
  background-color: var(--black);
}
.footerContainer {
  width: 100%;
  max-width: 1080px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.list {
  list-style: none;
}
.item a,
.listed {
  text-decoration: none;
  font-size: 20px;
  font-family: var(--title-font-family);
  color: var(--white);
  line-height: 36px;
  font-weight: 400;
}
.firstCol {
  display: flex;
  flex-direction: column;
  gap: 49px;
}

.listed {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.rightsContainer {
  width: 100%;
  margin-top: 90px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}
.rightsText {
  font-size: 16px;
  font-family: var(--title-font-family);
  line-height: 36px;
  color: var(--white);
  font-weight: 400;
}
.inpContainer {
  max-width: 417.43px;
  max-height: 148px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
}
.subscribeInp {
  width: 100%;
  height: 43px;
  background-color: transparent;
  outline: none;
  border: 1.5px solid var(--lighter-gray-color);
  border-radius: 200px;
  padding-left: 27px;
  font-size: 21.525px;
  line-height: 55px;
  color: var(--lighter-gray-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  padding-bottom: 4px;
}
.subscribeInp::placeholder {
  color: var(--lighter-gray-color);
  font-family: var(--title-font-family);
  font-weight: 400;
}
.subscribeLabel {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 36.9px;
  line-height: 55px;
  color: var(--lighter-gray-color);
  margin-bottom: 10px;
}
.subscribeBtn {
  outline: none;
  border: none;
  color: var(--lighter-gray-color);
  font-size: 12.3px;
  line-height: 15px;
  font-family: var(--text-font-family);
  font-weight: 400;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 28.44px;
  bottom: 53px;
}
.topLogo,
.bottomLogo {
  width: 111px;
  height: 111px;
}
