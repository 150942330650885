.edit {
  cursor: pointer;
  border: 1px dashed transparent;
  background: linear-gradient(#f9f8f4, #f9f8f4) padding-box padding-box,
    repeating-conic-gradient(
        var(--c) 0 calc(360deg / var(--n) - var(--d)),
        transparent 0 calc(360deg / var(--n))
      )
      border-box;
  --d: 4deg;
  --n: 45;
  --c: #ffffff;
}
