.header {
  width: 100%;
  background: #faf9f5;
}
.collectionHeader {
  padding: 163px 31px 47px;
  max-width: 1300px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.backLink {
  display: flex;
  align-items: center;
}
.backBtn {
  width: 39px;
  height: 17px;
  margin-right: 12px;
}
.backText {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
}
.name {
  text-decoration: none;
}
.toggle {
  width: 40px;
  height: 10px;
  cursor: pointer;
}
