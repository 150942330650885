.userProfileContainer {
  width: 100%;
  height: 100%;
  padding: 45px 0;
}
.viewingText {
  font-family: var(--title-font-family);
  font-size: 24px;
  line-height: 52px;
  color: var(--black);
  margin-left: 63px;
  margin-bottom: 22px;
}
.titleWrapper {
  display: flex;
  margin-left: 63px;
  align-items: center;
}
.avatar {
  width: 71px;
  height: 71px;
  margin-left: 30px;
}
.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 22px 0px 58px 63px;
  border-bottom: 8px solid #f2f1ed;
}
.group {
  display: flex;
  flex-direction: column;
  margin: 32px 0;
}
.textField label {
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(1, 3, 2, 0.7);
  margin-bottom: 16px;
  display: block;
}
.textField input {
  width: 100%;
  max-width: 662px;
  height: 56px;
  padding: 13px 0 15px 24px;
  border: 1.5px solid #0a0a0a;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: var(--title-font-family);
  font-size: 20px;
  line-height: 28px;
  background-color: transparent;
}
.buttonsWrapper {
  display: flex;
  padding: 63px;
}

.btn {
  margin-right: 16px;
  padding: 10px 16px 9px 17px;
  border-radius: 10px;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}

.saveBtn {
  background-color: var(--black);
  color: white;
}
.passwordBtn {
  background-color: #1977f3;
  color: white;
}
.removeBtn {
  background-color: transparent;
  border: 2px solid #ee4655;
  color: #ee4655;
}
.saveBtn:disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
}
