.signUpAd {
  width: 100%;
  height: 831px;
  overflow: hidden;
  background-color: #f2f1ed;
  position: relative;
}
.container {
  width: 100%;
  max-width: 1440px;
  height: 831px;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
}
.text {
  width: 479px;
  font-size: 80px;
  font-family: var(--title-font-family);
  color: #020202;
  font-weight: 400;
}
.adImg {
  width: 426px;
  height: 536px;
  display: none;
}
.largeAdImg {
  width: 671px;
  height: 1059px;
  position: absolute;
  left: 0;
  top: -53px;
}
.learn {
  width: 180px;
  height: 180px;
  background-color: var(--black);
  color: #f9f8f4;
  text-decoration: none;
  position: absolute;
  font-size: 20px;
  z-index: 1;
  left: 595px;
  bottom: 333px;
  border-radius: 126.437px 82.1839px 139.08px;
  font-family: var(--text-font-family);
  border: none;
  outline: none;
  cursor: pointer;
}

.learn:hover {
  background-color: #ff6e26;
}
@media (max-width: 1400px) {
  .signUpAd,
  .container {
    height: 761px;
  }
  .container {
    max-width: 1300px;
  }
  .text {
    font-size: 65px;
    max-width: 460px;
  }
  .largeAdImg {
    width: 601px;
    height: 979px;
  }
  .learn {
    left: 525px;
    width: 160px;
    height: 160px;
    font-size: 18px;
  }
}
@media (max-width: 1300px) {
  .signUpAd,
  .container {
    height: 551px;
  }
  .container {
    max-width: 980px;
  }
  .text {
    font-size: 50px;
    max-width: 340px;
  }
  .largeAdImg {
    width: 371px;
    height: 759px;
  }
  .learn {
    left: 295px;
    width: 130px;
    height: 130px;
    font-size: 14px;
  }
}
@media (max-width: 800px) {
  .signUpAd,
  .container {
    height: auto;
  }
  .signUpAd {
    position: initial;
  }
  .container {
    flex-direction: column;
    align-items: flex-start;
  }
  .overFlowContainer {
    display: initial;
    position: relative;
  }
  .largeAdImg {
    display: none;
  }
  .adImg {
    display: initial;
  }
  .learn {
    width: 108px;
    height: 108px;
    font-size: 14px;
    border-radius: 75.8621px 49.3103px 83.4483px;
    left: 300px;
    top: 14px;
  }

  .text {
    font-size: 40px;
    max-width: 340px;
    padding: 50px 0 41px 20px;
  }
}
@media (max-width: 470px) {
  .adImg {
    width: 270px;
    height: 386px;
  }
  .learn {
    left: 190px;
  }
}
