.mapContainer {
  background-color: #f2f1ed;
  width: 378px;
  height: 100vh;
  position: relative;
}
.fullOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  bottom: 0;
}
.fullMapContainer {
  background-color: #f2f1ed;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 11;
}
.checkContainer {
  position: absolute;
  z-index: 2;
  top: 31px;
  left: 33px;
  background: #faf9f5;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px 12px 17px 11px;
}
.containerElement {
  width: 100%;
  height: 100%;
}
.loader {
  margin-top: 40vh;
  width: 100px;
  height: 100px;
}
.labelPosition {
  display: block;
  transform: translateY(40px);
  text-transform: capitalize;
  backdrop-filter: blur(1px);
  text-shadow: 1px 1px 4px #ffffff;

  -webkit-backdrop-filter: blur(1px);
  letter-spacing: -0.3px;
}
@media (max-width: 720px) {
  .mapContainer {
    width: 100%;
  }
}
