.categoryRow {
  width: 100%;
  min-width: 650px;
  display: flex;
  flex-direction: column;
  padding: 32px 0px 40px 0px;
  border-bottom: 4px solid #f2f1ed;
}
.categoryName {
  font-family: var(--title-font-family);
  font-size: 20px;
  line-height: 19px;
  letter-spacing: 0.02em;
  margin-bottom: 32px;
  color: var(--black);
  text-transform: uppercase;
}
.selectCategory {
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.06em;
  color: rgba(10, 10, 10, 0.4);
  text-transform: uppercase;
}
.subCategoriesList {
  display: flex;
  margin: 8px 0 26px 0;
  max-width: 780px;
  flex-wrap: wrap;
}
.subCategoriesList button {
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--black);
  margin: 8px 16px 8px 0;
}
.viewMoreBtn {
  width: 111px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
}
.deleteBtnWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deleteCategoryBtn {
  width: 200px;
  border: 1px solid #0a0a0a;
  border-radius: 10px;
  margin: 40px 0;
  background-color: transparent;
  padding: 12px;
  cursor: pointer;
  font-family: "Arial";
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0a0a0a;
}
