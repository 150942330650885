.sidebarContainer {
  min-width: 333px;
  display: flex;
  flex-direction: column;
  border-right: 8px solid #f2f1ed;
  background-color: #f9f8f4;
  padding-top: 40px;
}

.sidebarHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: 72px;
  padding-left: 64px;
}

.status {
  font-family: var(--title-font-family);
  font-size: 16px;
  color: rgba(10, 10, 10, 0.4);
  margin-bottom: 9px;
}

.title {
  font-family: var(--text-font-family);
  font-size: 20px;
}

.stepsWrapper {
  display: flex;
  flex-direction: column;
}

.step {
  width: 100%;
  display: flex;
  padding: 10px 0 12px 64px;
  font-family: var(--title-font-family);
  font-size: 28px;
}

.activeStep {
  background-color: #f2f1ed;
}
