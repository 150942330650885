.sharePopup {
  max-width: 393px;
}
.popUpHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 32px;
  line-height: 60px;
  font-family: var(--title-font-family);
  color: #020202;
  font-weight: 400;
}
.close {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 17px 0 34px;
}
.options img {
  width: 82px;
  height: 82px;
  border-radius: 50%;
  cursor: pointer;
}
.customLink {
  width: 100%;
  height: 49px;
  background-color: rgba(10, 10, 10, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 16px;
  border-radius: 4px;
}
.link {
  font-size: 18px;
  font-family: var(--title-font-family);
  color: #000000;
  font-weight: 400;
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.copy {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  color: #1977f3;
  font-family: var(--title-font-family);
  font-weight: 400;
}

.notification {
  text-align: center;
  font-family: var(--text-font-family);
  font-weight: 400;
  transition: all 0.1s;
}
@media (max-width: 500px) {
  .sharePopup {
    max-width: 290px;
  }
  .options img {
    width: 60px;
    height: 60px;
  }
  .link {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
