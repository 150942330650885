.loginForm {
  max-width: 100%;
  width: 663px;
}
.header {
  font-size: 48px;
  line-height: 52px;
  color: var(--black);
  font-weight: 400;
  font-family: var(--title-font-family);
  width: 100%;
  padding: 196px 0 48px;
}

.inpContainer label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: var(--text-font-family);
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(1, 3, 2, 0.7);
  margin-top: 15px;
  display: block;
}
.inpContainer {
  width: 100%;
}

.inpContainer input {
  width: 100%;
  height: 56px;
  border: 1.5px solid #0a0a0a;
  border-radius: 10px;
  outline: none;
  padding-left: 24px;
  margin: 16px 0 9px;
  font-size: 20px;
  line-height: 28px;
  color: var(--black);
  font-family: var(--title-font-family);
  padding-bottom: 4px;
}
.continueBtn {
  width: 325px;
  height: 68px;
  border-radius: 50px;
  font-size: 16px;
  line-height: 23px;
}
.invalid label {
  color: #ee4655;
}
.invalid input {
  margin-bottom: 8px;
  border: 1.5px solid #ee4655;
  color: #ee4655;
}
.invalid input::placeholder {
  color: #ee4655;
}
.forgot,
.signup,
.verify {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--black);
}
.verify {
  border: none;
  background-color: transparent;
  outline: none;
  text-decoration: underline;
  cursor: pointer;
}
.forgot {
  max-width: 120px;
}
.signup {
  max-width: 350px;
  margin: 15px 0 58px;
}
.btnLinkContainer {
  display: flex;
  flex-direction: column;
}
.errorMessage {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ee4655;
  padding-bottom: 8px;
}

@media (max-width: 1300px) {
  .loginForm {
    margin: auto;
  }
  .header {
    padding-top: 90px;
  }
}
@media (max-width: 400px) {
  .header {
    font-size: 36px;
  }
  .continueBtn {
    width: 100%;
    height: 56px;
    font-size: 12px;
  }
}
