.title {
  font-family: var(--title-font-family);
  font-weight: 300;
  font-size: 48px;
  line-height: 46px;
  color: #020202;
  margin: 23px 0;
}
.edit {
  background: #faf9f5;
  border: 1px solid #000000;
  border-radius: 4px;
  z-index: 2;
  position: relative;
  cursor: pointer;
}
.highZ {
  z-index: 3 !important;
}

.tooltip {
  position: absolute;
  top: 65px;
  right: 20px;
}
.tooltip::before {
  top: -20px;
  right: 30px;
}
.inpContainer {
  width: 291px;
}
.inpContainer label {
  font-family: var(--title-font-family);
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: rgba(10, 10, 10, 0.4);
  padding-bottom: 4px;
  display: block;
}
.inpContainer input {
  width: 100%;
  height: 46px;
  outline: none;
  border: 1px solid #000000;
  border-radius: 4px;
  padding-left: 5px;
}

@media (max-width: 500px) {
  .title {
    font-size: 28px;
    line-height: 34px;
  }
}
