.sidebar {
  height: 100%;
  width: 333px;
  min-width: 333px;
  background-color: #f9f8f4;
  padding-top: 144px;
  display: flex;
  flex-direction: column;
  border-right: 8px solid #f2f1ed;
}
.upperSection {
  display: flex;
  flex-direction: column;
  padding: 0 64px;
}
.title {
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.06em;
  color: rgba(10, 10, 10, 0.4);
  margin-bottom: 9px;
}
.name {
  font-family: var(--text-font-family);
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.06em;
  color: var(--black);
  margin-bottom: 60px;
}

.itemsList {
  display: flex;
  flex-direction: column;
}
.item {
  display: block;
  text-decoration: none;
  font-family: var(--title-font-family);
  font-size: 28px;
  line-height: 33px;
  font-weight: 400;
  color: var(--black);
  padding: 11px 43px 11px 53px;
}
.active {
  background: #f2f1ed;
}
.link:hover {
  background: #f2f1ed;
}
.visitLink {
  margin-top: auto;
  padding: 20px 0 20px 64px;
}
.visitContainer {
  display: flex;
  align-items: center;
}
.visitText {
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-weight: 400;
  color: #1977f3;
  padding-right: 13px;
}
