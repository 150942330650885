.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 52px;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  border-bottom: 4px solid #f2f1ed;
}

.title {
  font-size: 48px;
  font-family: var(--title-font-family);
  font-weight: unset;
  color: var(--black);
  margin-bottom: 18px;
}

.description {
  font-size: 20px;
  font-family: var(--title-font-family);
  max-width: 568px;
  line-height: 32px;
}

.buttonsWrapper {
  padding: 48px 0;
  display: flex;
}

.daysList {
  margin-top: 34px;
  min-width: 1030px;
}

.haveEcommerceBtn {
  font-size: 16px;
  font-family: var(--title-font-family);
  color: var(--black);
  padding: 22px 35px;
  border-radius: 50px;
  background-color: #f2f1ed;
  border: none;
  margin-left: 26px;
  cursor: pointer;
}

.addCategoryRow {
  padding: 32px 0 40px 0;
  border-bottom: 4px solid #f2f1ed;
}
.addCategoryBtn {
  width: 235px;
  height: 68px;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid var(--black);
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--black);
  cursor: pointer;
}

.addDescTitle {
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: rgba(10, 10, 10, 0.4);
  margin: 46px 0 24px 0;
}

.productsHeader {
  border-bottom: none;
}

.addItemBtn {
  width: 235px;
  height: 68px;
  border: 1px solid #0a0a0a;
  border-radius: 10px;
  margin: 40px 0;
  background-color: transparent;
  padding: 23px 0 22px 0;
  cursor: pointer;
  font-family: "Arial";
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0a0a0a;
}
.error {
  padding-top: 20px;
  font-family: var(--title-font-family);
  letter-spacing: 0.2px;
  color: #ee4655;
  font-weight: 400;
  font-size: 18px;
}
.inpContainerClass {
  display: flex;
  flex-direction: column;
}
