.cardContainer {
  width: 375px;
  background: rgba(250, 249, 245, 0.95);
  backdrop-filter: blur(8px);
  padding: 49px 19.92px 30.78px;
  position: absolute;
  left: 40px;
  top: 30px;
  z-index: 2;
}
.title {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--black);
}
.container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.container img {
  margin-right: 7px;
}
.user {
  padding-left: 4px;
}
.address {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #536eff;
}
.btn {
  width: 260px;
  height: 66.22px;
  border-radius: 48.69px;
  font-size: 15.58px;
  line-height: 22px;
  margin-top: 36px;
}
.userIcon {
  width: 6px;
  height: 6px;
  margin-left: 2px;
}
.destinationIcon {
  width: 12px;
  height: 12px;
}
.errorMessage {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ee4655;
  padding-top: 8px;
}
@media (max-width: 710px) {
  .cardContainer {
    top: 10px;
    left: 10px;
    padding: 20px;
    width: auto;
    max-width: 100%;
  }
  .addressesContainer {
    display: none;
  }
  .btn {
    margin-top: 0;
    max-width: 100%;
  }
  .errorMessage {
    padding-top: 0;
    padding-bottom: 10px;
  }
}
