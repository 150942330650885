.popUp {
  width: 335px;
  text-align: center;
  padding: 0;
  top: 10%;
}
.addPopUp {
  width: 375px;
  text-align: center;
  padding: 0;
  top: 5%;
}
.marg {
  padding: 20px 0;
}
@media (max-width: 500px) {
  .popUp,
  .addPopUp {
    max-width: 90%;
  }
}
