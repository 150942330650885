.upVoteBtn {
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(250, 249, 245, 0.9);
}
.main {
  width: 50px;
  height: 50px;
  border-radius: 56px;
}
.vote {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.active {
  background-color: #ff6e26 !important;
}
.loadingBg {
  background-color: rgba(250, 249, 245, 0.9) !important;
}
.active p {
  color: var(--light-gray-color) !important;
}
.active path {
  stroke: var(--light-gray-color);
}
.loader {
  width: 10px !important;
  height: 10px !important;
}
.loader::after {
  background-color: rgba(250, 249, 245, 0.9) !important;
}
.loader div:after {
  width: 3px !important;
  height: 8px !important;
  top: 45px !important;
}
@media (hover: hover) and (pointer: fine) {
  .upVoteBtn:hover {
    background-color: #ff6e26;
  }
  .loadingBg:hover {
    background-color: rgba(250, 249, 245, 0.9) !important;
  }
  .upVoteBtn:hover .vote {
    color: var(--light-gray-color);
  }
  .upVoteBtn:hover path {
    stroke: var(--light-gray-color);
  }
  .active:hover {
    background-color: #ff6e26;
  }
}
