.heroHeader {
  background: #f2f1ed;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.whiteBg {
  position: absolute;
  top: 0;
  left: -10%;
  width: 120%;
  height: 60%;
  border-bottom-left-radius: 50% 45%;
  border-bottom-right-radius: 50% 45%;
  background-color: var(--lighter-gray-color);
}
.content {
  position: relative;
  margin: 55px auto;
  width: 80%;
}
.title {
  max-width: 100%;
  text-align: center;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 61.5px;
  line-height: 62px;
  padding-bottom: 44px;
  color: #020202;
}
.imgBtn {
  position: relative;
  max-width: 406px;
  margin: auto;
}
.heroImg {
  height: 533px;
  width: 406px;
  border-radius: 264.57px 221px 231px 264px;
  object-fit: cover;
}
.learn {
  width: 138px;
  height: 138px;
  background-color: var(--black);
  color: #f9f8f4;
  text-decoration: none;
  font-size: 15.3px;
  line-height: 18px;
  font-weight: 400;
  z-index: 2;
  border-radius: 126.437px 82.1839px 139.08px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  text-align: center;
  position: absolute;
  top: 128px;
  right: -62px;
  font-family: var(--title-font-family);
}
.learnHover:hover a {
  background-color: #ff6e26;
}
