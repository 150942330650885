.container {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.wrapper {
  width: 24px;
  height: 24px;
}
.text {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  text-align: right;
  letter-spacing: -0.02em;
}
.textBtnContainer {
  display: flex;
  column-gap: 8px;
}
.danger {
  color: #ee4655;
}
.black {
  color: #0a0a0a;
}
.tryBtn {
  text-decoration: underline;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}
.errorContainer {
  display: flex;
  column-gap: 16px;
}
