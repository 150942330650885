.headerContainer {
  width: 100%;
  padding: 22px 30px 25px 32px;
  background: #f9f8f4;
  box-shadow: 6px 6px 16px rgba(230, 229, 225, 0.3);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoContainer {
  display: flex;
  align-items: center;
}
.logo {
  width: 55px;

}
.title {
  font-family: var(--title-font-family);
  font-size: 36px;
  font-weight: 400;
  line-height: 72px;
  color: var(--black);
  padding-left: 16px;
}
.rightContainer {
  display: flex;
  align-items: center;
}
.addBtn {
  padding: 14px 23px 13px 22px;
  text-decoration: none;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border-radius: 10px;
  margin-right: 41px;
}
.dimmed {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  pointer-events: none;
}
.regBtn {
  background-color: var(--black);
  color: #faf9f5;
}
