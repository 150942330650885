.profileContainer {
  display: flex;
  flex-direction: column;
  background-color: #f2f1ed;
  height: 100%;
  min-height: calc(100vh - 721px);
}
.middleSection {
  display: flex;
}

@media (max-width: 1300px) {
  .middleSection {
    flex-direction: column;
  }
}
