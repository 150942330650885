.headerSection {
  background: #f2f1ed;
  position: relative;
  width: 100%;
  overflow-x: hidden;
}
.edit {
  padding-top: 30px;
}
.wrapper {
  max-width: 1440px;
  margin: auto;
  position: relative;
}
.whiteBg {
  position: absolute;
  top: 0;
  left: -10%;
  width: 120%;
  height: 60%;
  border-bottom-left-radius: 50% 45%;
  border-bottom-right-radius: 50% 45%;
  background-color: var(--lighter-gray-color);
}
.content {
  margin: 58px auto 86px;
  display: flex;
  gap: 80px;
}
.editContentView {
  max-width: 880px;
}
.regularContent {
  max-width: 970px;
}
.container {
  max-width: 458.52px;
  max-height: 662.74px;
  position: relative;
  margin: auto;
  display: block;
}
.element {
  width: 100%;
  text-align: center;
}
.busImg {
  width: 458.52px;
  height: 662.74px;
  border-radius: 385.312px;
  object-fit: cover;
}
.visit {
  width: 180px;
  height: 180px;
  border-radius: 126.437px 82.1839px 139.08px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 19.7015px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-decoration: none;
  position: absolute;
  font-family: var(--text-font-family);
  position: absolute;
  right: -72.67px;
  bottom: 61.67px;
}

.editBtn {
  position: absolute;
  top: 30px;
  right: 30px;
}

@media (max-width: 1080px) {
  .content {
    margin: 28px auto 86px;
    max-width: 458.52px;
    flex-direction: column-reverse;
    row-gap: 0;
  }
  .container {
    margin: 83px 0 0;
  }
}
@media (max-width: 866px) {
  .whiteBg {
    left: -40%;
    width: 180%;
    height: 70%;
  }
}
@media (max-width: 630px) {
  .container,
  .busImg {
    width: 280px;
    height: 400px;
  }
  .visit {
    width: 100px;
    height: 100px;
    font-size: 12px;
    line-height: 19px;
    top: -20px;
    right: 0px;
  }
}

@media (max-width: 500px) {
  .content {
    max-width: 320px;
  }
}
