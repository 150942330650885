.filtersSide {
  margin-top: 65px;
}
.brdr {
  margin: 33px 0 20px;
  height: 1px;
  background-color: #d6d5d2;
  width: 205px;
}
.drawer {
  margin-top: 0;
}
.apply {
  position: fixed;
  top: 50%;
  left: 110px;
  width: 129px;
  height: 40px;
  background: #0a0a0a;
  border-radius: 50px;
  border: none;
  outline: none;
  font-family: var(--title-font-family);
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #f9f8f4;
  cursor: pointer;
  z-index: 5;
}
.apply:disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
}

.filtersDrawer {
  width: 342px;
  height: 100%;
  background-color: #ffffff;
  position: fixed;
  flex-wrap: wrap;
  z-index: 4;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 24px 24px;
}
.filtersDrawer::-webkit-scrollbar {
  display: none;
}
.open {
  display: initial;
}
.close {
  display: none;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: var(--title-font-family);
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  color: rgba(10, 10, 10, 0.9);
}
.backBtn {
  width: 50px;
  height: 20px;
  cursor: pointer;
}
.filters {
  max-height: 90vh;
  overflow: auto;
  margin-top: 65px;
  padding-bottom: 80px;
}
@media (min-width: 1100px) {
  .filtersDrawer {
    display: none;
  }
}
@media (max-width: 1100px) {
  .drawer {
    padding: 24px 24px;
    display: initial;
    position: fixed;
    z-index: 4;
    top: 95px;
    left: 0;
    max-height: 80vh;
    overflow: auto;
    width: 322px;
  }
  .noDrawer {
    transform: translateX(-150%);
    display: none;
  }
}
@media (max-width: 450px) {
  .drawer {
    width: 94%;
  }
  .filtersDrawer {
    width: 100%;
  }
}
