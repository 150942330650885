.upVotedSection {
  min-height: 400px;
  background: #faf9f5;
  padding: 78px 79px 114px 74px;
  margin-right: 9px;
}
.fullWidth {
  width: 100%;
  border-radius: 0px 173.759px 0px 0px;
}
.partialWidth {
  width: 55%;
}
.content {
  max-width: 1440px;
  margin: auto;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-family: var(--title-font-family);
  font-size: 47.9335px;
  line-height: 60px;
}
.noItemsText {
  font-size: 24px;
  font-family: var(--title-font-family);
  text-align: center;
  margin-top: 100px;
}
.itemsList {
  display: flex;
  max-width: 1440px;
  column-gap: 45px;
  row-gap: 79px;
  flex-wrap: wrap;
  margin: 84px auto 0;
}
@media (max-width: 1300px) {
  .upVotedSection {
    width: 100%;
    padding: 78px 31px 114px 32px;
  }
}

@media (max-width: 740px) {
  .itemsList {
    justify-content: center;
  }
  .title {
    font-size: 38px;
  }
}
