.headerSection {
  width: 100%;
  height: 535px;
  overflow: hidden;
  background-color: #f2f1ed;
  position: relative;
}
.headerContainer {
  width: 100%;
  max-width: 1440px;
  height: 535px;
  margin: auto;
  display: flex;
  align-items: center;
}
.headerImg {
  width: 601px;
  max-width: 100%;
  height: 690px;
  position: absolute !important;
  right: 0;
  top: -59px;
}
.headerLoader {
  border-radius: 430.392px 117.626px 0px 470.505px;
  background: #e9e8e8 !important;
}
.smallerImg {
  display: none;
}
.text {
  font-family: var(--title-font-family);
  font-size: 61px;
  width: 416px;
  font-weight: 400;
  margin-left: 84px;
}
.hideText {
  display: none;
}
