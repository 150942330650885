.exploreSection {
  width: 100%;
  background-color: #f9f8f4;
  margin-bottom: 28px;
  border-radius: 0px 199px 0px 221px;
  padding: 89px 0 90.5px;
}
.exploreContainer {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 0 31px;
}
.sectionTitle {
  font-family: var(--title-font-family);
  font-size: 48px;
  color: var(--black);
  padding-bottom: 51px;
  line-height: 72px;
  font-weight: 400;
}
.items {
  max-width: 1279px;
  height: 1000px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.item {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.item0 {
  order: 1;
  flex: 0 0 100%;
  margin-right: 60px;
}
.item1 {
  order: 2;
  flex: 0 0 50%;
}
.item2 {
  order: 2;
  flex: 0 0 50%;
}
.item3 {
  order: 3;
  flex: 0 0 50%;
}
.item4 {
  order: 3;
  flex: 0 0 50%;
}

.itemImg {
  width: 262px;
  height: 341px;
  margin-bottom: 20px;
}
.item1,
.item2,
.item3,
.item4 {
  width: 319px;
  height: 341px;
}
.firstItemImg,
.item0 {
  width: 527px;
  height: 692.13px;
}

.title {
  color: var(--black);
  font-size: 28px;
  line-height: 38px;
  font-family: var(--title-font-family);
}
.subTitle {
  max-width: 316px;
  padding: 8px 0;
  font-size: 20px;
  line-height: 29px;
  color: rgba(10, 10, 10, 0.6);
  font-family: var(--text-font-family);
  margin: auto;
  letter-spacing: -0.02em;
}
.imgContainer {
  position: relative;
}
@media (max-width: 1300px) {
  .exploreContainer {
    max-width: 1100px;
  }
  .items {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 26px;
    height: auto;
  }
  .item0 {
    margin-right: 0;
  }
  .firstItemImg {
    width: 262px;
    height: 341px;
    margin-bottom: 20px;
  }
  .item0,
  .item1,
  .item2,
  .item3,
  .item4 {
    width: initial;
    height: initial;
    flex: initial;
  }
}
@media (max-width: 1074px) {
  .items {
    justify-content: center;
  }
}
@media (max-width: 720px) {
  .exploreSection {
    border-radius: 0px 111px 0px 0px;
  }
}
@media (max-width: 520px) {
  .sectionTitle {
    font-size: 29px;
  }
}
