.loading {
  position: relative;
  overflow: hidden;
}
.regBg {
  background: #f2f1ed;
}
.customBg {
  background-color: #e9e8e8;
}
.loading:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100px);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  animation: loading 0.8s infinite;
}
@keyframes loading {
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
