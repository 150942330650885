.arrowIcon {
  margin-left: 15px;
}
.alignCenter {
  text-align: center;
}
.column:nth-child(4) {
  text-align: center;
}
.rotateArrow {
  transform: rotate(180deg);
}
.table {
  width: 100%;
  text-align: left;
  padding: 0 62px 0 26px;
}

.row,
.header {
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  width: 100%;
}
.header td:nth-child(1),
.row td:nth-child(1) {
  margin-right: 15px;
}
.row {
  border-bottom: 4px solid #f2f1ed;
  padding: 30px 0 14px;
}
.row td {
  width: 25%;
}
.drag {
  width: 15px;
  height: 26px;
  cursor: pointer;
}
.nameCol {
  display: flex;
  align-items: center;
  gap: 15px;
}
.ownerContainer {
  display: flex;
  align-items: center;
  gap: 8.48px;
}
.avatar {
  width: 36.99px;
  height: 36.99px;
  border-radius: 50%;
}
.types {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6.16px;
}
.itemName {
  font-family: var(--text-font-family);
  font-size: 28px;
  line-height: 46px;
  font-weight: 400;
  color: #020202;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}
.name {
  font-family: var(--title-font-family);
  font-size: 13.8712px;
  line-height: 16px;
  font-weight: 400;
  color: #000000;
}
.title {
  font-size: 13.8712px;
  line-height: 16px;
  font-family: var(--title-font-family);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.64);
}
.btnsContainer {
  display: flex;
  gap: 22px;
  align-items: center;
  justify-content: flex-end;
}
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 233px;
  height: 42px;
  border: 1px solid #1977f3;
  border-radius: 10px;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #1977f3;
}
.delete {
  width: 18.89px;
  height: 21.26px;
  cursor: pointer;
}
.pageLoader {
  width: 80px;
  height: 80px;
  margin: 50px auto;
}
