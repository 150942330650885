.supportForm {
  margin-left: 10px;
  background-color: #f9f8f4;
  width: 90%;
  min-width: 900px;
  padding: 63px 0 0 63px;
}
.inpContainer {
  margin-bottom: 43px;
  width: 662px;
}
.inpContainer label {
  font-family: var(--title-font-family);
  font-size: 48px;
  line-height: 52px;
  color: var(--black);
  padding-bottom: 43px;
  display: block;
}
.inpContainer textarea {
  display: block;
  width: 100%;
  height: 245px;
  background-color: transparent;
  resize: none;
  outline: none;
}
.sendBtn {
  width: 235px;
  height: 68px;
  border-radius: 50px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
}
.regularBg {
  background-color: var(--black);
  border: none;
}
.loadingBg {
  border: 1px solid var(--black);
  background-color: var(--lighter-gray-color);
}

.sendBtn:disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
}
.successMessage {
  padding: 63px 0 0 63px;
  font-size: 32px;
  line-height: 45px;
  font-family: var(--title-font-family);
  font-weight: 400;
  color: var(--black);
  max-width: 800px;
}
