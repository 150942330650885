.resetPage {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 1059px;
}
.resetImg {
  width: 565px;
  height: 1059px;
  position: absolute;
  left: 0;
  top: 0;
}
.logo {
  width: 200px;
  height: 200px;
}
.content {
  max-width: 663px;
  margin: 168px 40px 0 645px;
}
.header {
  font-size: 48px;
  line-height: 52px;
  color: var(--black);
  font-weight: 400;
  font-family: var(--title-font-family);
  width: 100%;
  padding: 60px 0 63px;
}

.inpContainer label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: var(--text-font-family);
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(1, 3, 2, 0.7);
}
.inpContainer {
  width: 100%;
}
.inpContainer input {
  width: 100%;
  height: 56px;
  border: 1.5px solid #0a0a0a;
  border-radius: 10px;
  outline: none;
  padding-left: 24px;
  margin: 16px 0 24px;
  font-size: 20px;
  line-height: 28px;
  color: rgba(2, 2, 2, 0.4);
  font-family: var(--title-font-family);
  padding-bottom: 4px;
}
.continueBtn {
  width: 325px;
  height: 68px;
  border-radius: 50px;
  font-size: 16px;
  line-height: 23px;
}
.invalid label {
  color: #ee4655;
}
.invalid input {
  margin-bottom: 8px;
  border: 1.5px solid #ee4655;
  color: #ee4655;
}
.invalid input::placeholder {
  color: #ee4655;
}
.errorMessage {
  font-family: var(--title-font-family);
  font-size: 48px;
  line-height: 52px;
  color: var(--black);
  padding: 60px 0 191px;
}
.loader {
  width: 100px;
  height: 100px;
  margin: 60px auto 191px;
}
@media (max-width: 1194px) {
  .resetPage {
    height: 750px;
  }
  .resetImg {
    width: 300px;
    height: 750px;
  }
  .content {
    margin: 50px 40px 0 350px;
  }
  .header {
    font-size: 32px;
    line-height: 36px;
    padding: 30px 0 30px;
  }
  .continueBtn {
    width: 200px;
    height: 52px;
    font-size: 14px;
  }
}
@media (max-width: 750px) {
  .resetImg {
    display: none;
  }
  .content {
    margin: 44px auto;
    padding: 0 32px;
  }
  .logo {
    width: 72px;
    height: 72px;
  }
  .header {
    padding: 91px 0 63px;
  }
}
