.row {
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 4px solid #f2f1ed;
  padding: 30px 0 14px;
}
.row td {
  width: 25%;
}
.ownerContainer {
  display: flex;
  align-items: center;
  gap: 8.48px;
}
.avatar {
  width: 36.99px;
  height: 36.99px;
  border-radius: 50%;
}
.types {
  display: flex;
  align-items: center;
  gap: 6.16px;
}
.itemName {
  width: 200px;
  height: 10px;
  border-radius: 5px;
}
.name {
  width: 80px;
  margin-bottom: 5px;
  height: 10px;
  border-radius: 5px;
}
.title {
  width: 60px;
  height: 10px;
  border-radius: 5px;
}
.btnsContainer {
  display: flex;
  gap: 22px;
  align-items: center;
  justify-content: flex-end;
}
.link {
  width: 233px;
  height: 42px;
  border-radius: 10px;
}
.chip {
  width: 120px;
  height: 30px;
  border-radius: 25px;
}
