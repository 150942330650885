.pagination {
  max-width: 312px;
  margin-top: 56px;
  margin-left: calc(100% - 312px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.rightArrow {
  transform: rotate(180deg);
}
.page {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--title-font-family);
  color: #887e70;
  cursor: pointer;
}
.selected {
  background: #fc986d;
  color: #fefdfd;
}
