.carouselContainer {
  display: flex;
}
.productImages {
  display: flex;
  flex-direction: column;
  margin-right: 41px;
  max-height: 740px;
  max-width: 100%;
  overflow: auto;
}
.productImages::-webkit-scrollbar {
  display: none;
}
.carousalImg {
  width: 84px;
  height: 111px;
  margin-right: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 190.804px 326.161px 96.2176px 173.748px;
  object-fit: cover;
}
.edit {
  z-index: 2;
  position: relative;
}
.highlighted {
  z-index: 5;
  position: relative;
}

.mainImg {
  width: 431px;
  height: 564px;
  cursor: pointer;
  border-radius: 190.804px 326.161px 96.2176px 173.748px;
  object-fit: cover;
}
.mainImageContainer {
  position: relative;
}
.buy {
  border: none;
  outline: none;
  width: 137px;
  height: 137px;
  font-size: 12px;
  border-radius: 126.437px 82.1839px 139.08px;
  cursor: pointer;
  color: var(--white);
  font-family: var(--text-font-family);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 400;
  position: absolute;
  right: -60px;
  bottom: 90px;
}
.enabled:hover {
  background-color: #ff6e26;
}

.onBoardingBtn {
  z-index: 11;
  pointer-events: none;
}
.enabled {
  background-color: var(--black);
  color: #ffffff;
  cursor: pointer;
}
.disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
}
@media (max-width: 1180px) {
  .buy {
    width: 137px;
    height: 137px;
    font-size: 12px;
  }
}

@media (max-width: 870px) {
  .carouselContainer {
    flex-wrap: wrap;
  }
  .productImages {
    width: 100%;
    flex-direction: row;
    margin-right: 0;
  }
  .mainImg {
    margin-top: 10px;
  }
  .carousalImg {
    margin-right: 20px;
  }
  .productImages::-webkit-scrollbar {
    display: initial;
  }
  .productImages::-webkit-scrollbar {
    height: 6px;
  }

  .productImages::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .productImages::-webkit-scrollbar-thumb {
    background: #888;
  }

  .productImages::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media (max-width: 690px) {
  .carousalImg {
    width: 80px;
    height: 116px;
    margin-right: 10px;
  }
  .mainImg {
    width: 270px;
    height: 390px;
  }
  .buy {
    width: 100px;
    height: 100px;
    right: -30px;
    top: 5px;
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  .carousalImg {
    width: 60px;
    height: 96px;
    margin-right: 10px;
  }
  .mainImg {
    width: 230px;
    height: 327px;
  }
}
.productTooltip {
  top: 140px;
  left: -240px;
}
