.editorPicksContainer {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 0 31px;
}
.sectionClass {
  width: 100%;
  background-color: #faf9f5;
  padding: 115px 0 265px;
  margin-top: 9px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 51px;
}

.sectionTitle {
  font-family: var(--title-font-family);
  font-size: 48px;
  color: var(--black);
  line-height: 72px;
  font-weight: 400;
  text-transform: capitalize;
}
.picks {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
}
.pick {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  max-width: 259.64px;
}
.product,
.business,
.price {
  font-family: var(--text-font-family);
  font-weight: 400;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.pickImg {
  width: 262px;
  height: 341px;
  object-fit: cover;
}
.business {
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  font-family: var(--title-font-family) !important;
  padding-top: 20px;
}
.product {
  padding: 8px 0 5px;
  font-size: 20px;
  line-height: 27px;
  color: var(--black);
  text-transform: capitalize;
}
.price {
  color: var(--black);
  font-size: 20px;
  line-height: 25px;
}
.imgContainer {
  position: relative;
}
.upVote {
  width: 49px;
  height: 49px;
  border-radius: 56px;
  backdrop-filter: blur(4px);
  border: 1px solid #deddd9 !important;
  position: absolute;
  right: -10px;
  bottom: -9px;
}
.addToCollection {
  width: 28px;
  height: 28px;
  backdrop-filter: blur(1.64706px);
  border: 1px solid #deddd9 !important;
  border-radius: 20.5882px;
  border: 1px solid #deddd9;

  position: absolute;
  right: -10px;
  bottom: 48px;
}
.loader {
  width: 100px !important;
  height: 100px !important;
  margin: 100px auto;
}
.empty {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}
@media (max-width: 1300px) {
  .picks {
    justify-content: flex-start;
    gap: 70px;
  }
  .editorPicksContainer {
    max-width: 1100px;
  }
}
@media (max-width: 850px) {
  .picks {
    column-gap: 25px;
    row-gap: 24px;
  }
  .pickImg {
    width: 150px;
    height: 197px;
  }
  .business,
  .product,
  .price {
    width: 159px;
  }
  .business {
    padding-top: 9px;
  }
  .product {
    padding: 0;
    font-size: 16px;
    line-height: 27px;
  }
  .price {
    font-size: 14px;
    line-height: 21px;
  }
}

@media (max-width: 720px) {
  .sectionTitle {
    font-size: 32px;
  }
}
@media (max-width: 605px) {
  .picks {
    justify-content: center;
  }
}
