.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
}
.catLink {
  cursor: pointer;
}
.tag img {
  margin-right: 4px;
}
.tag span {
  font-family: var(--text-font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.02em;
}
.product {
  width: 85px;
  height: 27px;
  background-color: rgba(34, 111, 84, 0.12);
  color: #226f54;
  border-radius: 50px;
}
.service {
  width: 78px;
  height: 27px;
  background-color: rgba(10, 132, 255, 0.12);
  color: #0a84ff;
  border-radius: 50px;
}
.eCommerce {
  width: 114px;
  height: 27px;
  background-color: rgba(253, 110, 50, 0.12);
  color: #fd6e32;
  border-radius: 50px;
}
.brick {
  width: 131px;
  height: 27px;
  background-color: rgba(147, 29, 39, 0.12);
  color: #931d27;
  border-radius: 50px;
}
.food {
  width: 63px;
  height: 27px;
  background-color: rgba(253, 184, 50, 0.12);
  color: #fdb832;
  border-radius: 50px;
}
.restaurant {
  width: 107px;
  height: 27px;
  background-color: rgba(142, 80, 243, 0.12);
  color: #8e50f3;
  border-radius: 50px;
}
.category {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--black);
  padding: 3.08px 9.25px;
  border: 0.75px solid var(--black);
  border-radius: 50px;
  text-decoration: none;
  background-color: transparent;
}
.category span {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.chip {
  padding: 8px 16px;
  border-radius: 50px;
  border: 0.75px solid var(--black);
  background-color: transparent;
  cursor: pointer;
}
.selectedChip {
  background-color: var(--black);
  color: white !important;
}
