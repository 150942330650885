.layout {
  overflow: auto;
}
.marginDev {
  width: 100%;
  background-color: #f9f8f4;
}
@media (max-width: 998px) {
  .marginDev {
    width: 100%;
    height: 129.76px;
  }
}
@media (max-width: 710px) {
  .marginDev {
    width: 100%;
    height: 85.76px;
  }
}
