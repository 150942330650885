.collectionsPage {
  background-color: #f2f1ed;
}
.listingSection {
  margin-top: 9px;
  background-color: #faf9f5;
  padding: 111px 0 74px;
}
.header {
  width: 100%;
  background: #faf9f5;
}
.title {
  font-family: var(--title-font-family);
  font-weight: 400;
  max-width: 1300px;
  margin: auto;
  font-size: 80px;
  line-height: 80px;
  color: #020202;
  padding: 60px 0 78px 31px;
}
.backBtnWrapper{
  width: 100%;
  max-width: 1300px;
  margin:auto;
  padding: 50px 0 0 31px;
}
.backBtn {
  width: 50px;
  height: 20px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .title {
    font-size: 45px;
    line-height: 45px;
    padding: 71px 0 78px 31px;
  }
}
