.dayContainer {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 24px 0;
}
.brdrContainer {
  border-bottom: 4px solid #f2f1ed;
}
.dayName {
  font-size: 28px;
  font-family: var(--title-font-family);
  width: 133px;
}

.fieldsWrapper {
  display: flex;
}

.intervalsField input {
  width: 206px;
  height: 80px;
  border: 2px solid #e6e5e1;
  box-sizing: border-box;
  border-radius: 19px;
  background-color: white;
  margin: 0 18px;
  padding: 0 15px 0 26px;
  font-family: var(--inter-semi-bold);
  color: black;
  cursor: pointer;
}
.intervalsField input ::placeholder {
  font-family: var(--inter-semi-bold);
  color: black;
}

.intervalsField a {
  top: 27px;
  right: 30px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  margin-bottom: 5px;
  width: 140px;
}

.firstCheckboxContainer {
  margin-right: 10px;
}

.checkboxContainer label {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.02em;
  position: absolute;
  left: 20px;
  color: rgba(10, 10, 10, 0.5);
}
.checkboxContainer input {
  opacity: 0;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.checkboxContainer span {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid rgba(10, 10, 10, 0.39);
  background-color: transparent;
}

.checkMark {
  background-color: var(--black) !important;
}
.inpContainer {
  position: relative;
}
.arrow,
.close {
  position: absolute;
  right: 36px;
  top: 31px;
  width: 14px;
  height: 19px;
  cursor: pointer;
}
.arrow {
  pointer-events: none;
}
.error {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ee4655;
}
