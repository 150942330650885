.container {
  display: flex;
  flex-direction: column;
  gap: 21.5px;
}
.containerTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  font-family: var(--text-font-family);
  color: #faf9f5;
}
.socials {
  display: flex;
  gap: 20.07px;
}
.socials img {
  width: 30px;
}
.stores {
  flex-wrap: wrap;
  display: flex;
  gap: 30px;
  align-items: center;
}
@media (max-width: 896px) {
  .container {
    margin-bottom: 50px;
  }
}
@media (max-width: 635px) {
  .stores {
    gap: 15px;
  }
  .stores img {
    width: 130px;
  }
}
