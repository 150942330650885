.images {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 150px;
  width: 874px;
  height: 100vh;
}
.slick-list > div {
  height: 556px !important;
}
.judgeImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-1 {
  mask-image: url(../../../../assets/images/testEllipse.svg);
  object-position: 0 0px;
}
.img-2 {
  mask-image: url(../../../../assets/images/secondMask.svg);
  object-position: 20% 20%;
}
.wrapper::-webkit-scrollbar,
.images::-webkit-scrollbar {
  display: none;
}
.customScrollbar {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
}
.scrollbarText {
  font-family: "Halyard Text";
  transform: rotate(90deg);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #f2f1ed;
}
.track {
  background-color: rgba(242, 241, 237, 0.2);
  height: 437px;
  width: 2px;
}
.thumb {
  background-color: #f2f1ed;
  height: 25%;
  width: 2px;
  transition: all 0.2s;
}
.loaderStyle {
  border-radius: 62px 132px 76px;
}
div.imgContainer {
  position: relative;
  width: 774px !important;
  height: 527px;
  margin-bottom: 32px;
}
.sponsor {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 110px;
  height: 110px;
}
@media (max-width: 1275px) {
  .images {
    width: 574px;
  }

  div.imgContainer {
    width: 474px !important;
    height: 337px;
  }
  .slick-list > div {
    height: 376px !important;
  }
  .sponsor {
    width: 70px;
    height: 70px;
  }
  .img-1,
  .img-2 {
    mask-size: 474px 337px;
  }
}
@media (max-width: 980px) {
  .images {
    width: 324px;
  }

  div.imgContainer {
    width: 274px !important;
    height: 187px;
  }
  .slick-list > div {
    height: 206px !important;
  }
  .track {
    background-color: rgba(242, 241, 237, 0.2);
    height: 237px;
    width: 2px;
  }
  .img-1,
  .img-2 {
    mask-size: 274px 187px;
  }
  .sponsor {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 530px) {
  .images {
    width: 164px;
  }

  div.imgContainer {
    width: 124px !important;
    height: 87px;
    margin-bottom: 5px;
  }
  .slick-list > div {
    height: 96px !important;
  }
  .track {
    background-color: rgba(242, 241, 237, 0.2);
    height: 87px;
    width: 2px;
  }
  .scrollbarText {
    font-size: 10px;
    line-height: 16px;
  }
  .sponsor {
    width: 20px;
    height: 20px;
  }
  .img-1,
  .img-2 {
    mask-size: 124px 87px;
  }
}
