.layoutContainer {
  background-color: #f2f1ed;
  height: auto;
  min-width: 100%;
}
.container {
  display: flex;

  min-height: calc(100vh - 119px);
  width: 100%;
}
@media (max-width: 1360px) {
  .layoutContainer {
    display: table;
  }
}
