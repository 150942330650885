.listing {
  width: 100%;
  padding: 111px 0 74px;
  margin-top: 9px;
  background-color: #faf9f5;
}
.title {
  padding: 0 0 71px 31px;
  font-family: var(--title-font-family);
  font-weight: 400;
  max-width: 1300px;
  margin: auto;
  font-size: 48px;
  line-height: 60px;
  color: #020202;
}
.collections {
  max-width: 1300px;
  margin: auto;
  padding: 0 31px;
  display: flex;
  gap: 60px;
  flex-wrap: wrap;
}
.collections a {
  text-decoration: none;
}
.collection {
  text-align: center;
  cursor: pointer;
}
.singleItem {
  margin: auto;
}
.name {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: var(--black);
  padding-top: 7.75px;
}
.errorMessage {
  font-size: 22px;
}
.collectionImage {
  width: 260px;
  height: 341px;
  object-fit: cover;
}
.addBtn {
  max-width: 173px;
  text-align: center;
  justify-content: center;
  margin: 95px auto 0;
}
.btn {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: var(--black);
  border: none;
  outline: none;
  cursor: pointer;
}
.btnText {
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0a0a0a;
  padding-top: 17px;
  cursor: pointer;
}
@media (max-width: 850px) {
  .collections {
    column-gap: 25px;
    row-gap: 24px;
  }
  .collectionImage {
    width: 150px;
    height: 197px;
  }
}

@media (max-width: 605px) {
  .collections {
    justify-content: center;
  }
  .listing {
    padding: 111px 0 31px;
  }
  .title {
    font-size: 32px;
    line-height: 45px;
    padding: 0 0 71px 31px;
  }
}
