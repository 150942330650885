.gettingStartedContainer {
  width: 100%;
  display: flex;
}

.content {
  width: 100%;
  padding-right: 10px;
  overflow: hidden;
}
.btnContainer {
  width: 100%;
  text-align: left;
  padding: 50px 50px 20px;
}
.backBtn {
  width: 50px;
  height: 20px;
  cursor: pointer;
}
