.btnsContainer {
  z-index: 2;
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.editBtn,
.workingHours {
  height: 68px;
  border-radius: 10px;
  border: 1px solid #1977f3;
  outline: none;
  cursor: pointer;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.editBtn {
  width: 107px;
}
.workingHours {
  display: flex;
  width: 180px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}
.active {
  background-color: #1977f3;
  color: #faf9f5;
}
.notActive {
  color: #1977f3;
  background-color: #faf9f5;
}
.notActive:hover {
  background-color: #1977f3;
  color: #faf9f5;
}
