.infoContainer {
  position: relative;
  max-width: 350px;
  margin-top: 64px;
}
.tagsContainer {
  display: flex;
  gap: 6.16px;
  flex-wrap: wrap;
  margin-bottom: 11.26px;
}
.phoneContainer,
.emailContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.icon,
.emailContainer svg {
  width: 25px;
  height: 25px;
}
.phone {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 15.4125px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: rgba(10, 10, 10, 0.5);
}
.btnContainer {
  display: flex;
  align-items: center;
  gap: 24.97px;
  margin-top: 30px;
}
.upVote,
.addToCollection,
.mapBtn {
  width: 86.03px;
  height: 86.03px;
  background-color: #f2f1ed;
  border: 1.2652px solid #deddd9 !important;
  border-radius: 70.8515px;
  cursor: pointer;
}
.mapDisabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
}
.mapDisabled > .mapIcon path {
  fill: #f9f8f4;
  stroke: #f9f8f4;
}
.loadingBg {
  border: 1px solid var(--black);
  background-color: var(--lighter-gray-color);
}
.btnLoader {
  width: 20px;
  height: 20px;
}
.upVote p {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 20.1033px;
  line-height: 28px;
  color: #0a0a0a;
}
.mapIcon {
  width: 28.04px;
  height: 28.04px;
}
.addToCollection svg {
  width: 32px;
  height: 32px;
}
@media (hover: hover) and (pointer: fine) {
  .mapBtn:not(.loadingBg, .mapDisabled):hover {
    background-color: rgba(10, 10, 10, 0.9);
  }
  .mapBtn:hover .mapIcon path {
    fill: var(--light-gray-color);
    stroke: var(--light-gray-color);
  }
}
@media (max-width: 500px) {
  .infoContainer {
    padding: 0 30px;
  }
  .btnContainer {
    gap: 12px;
  }
  .btnContainer button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .upVote p {
    font-size: 15.88px;
  }
  .mapIcon {
    width: 19px;
    height: 19px;
  }
  .addToCollection svg {
    width: 19px;
    height: 19px;
  }
}
