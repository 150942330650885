.phoneContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.icon {
  width: 25px;
  height: 25px;
}
.phone {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 15.4125px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: rgba(10, 10, 10, 0.5);
  width: 92%;
}
.edit {
  background: #faf9f5;
  border: 1px solid #000000;
  border-radius: 4px;
  z-index: 2;
  position: relative;
  cursor: pointer;
}
.highZ {
  z-index: 3 !important;
}

.tooltip {
  position: absolute;
  top: 45px;
  right: 20px;
}
.tooltip::before {
  top: -20px;
  right: 30px;
}
.inpContainer {
  width: 291px;
}
.inpContainer label {
  font-family: var(--title-font-family);
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: rgba(10, 10, 10, 0.4);
  padding: 0 0 4px 40px;
  display: block;
}
.inpContainer input {
  width: 100%;
  height: 46px;
  outline: none;
  border: 1px solid #000000;
  border-radius: 4px;
  padding-left: 5px;
}
