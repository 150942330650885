.container {
  padding: 60px 24px 24px 20px;
  background-color: #faf9f5;
  border-radius: 39px;
  z-index: 3;
}
.body {
  position: relative;
  max-height: 400px;
  overflow: auto;
}
.body::-webkit-scrollbar {
  display: none;
}
.closeIcon {
  position: absolute;
  right: 24px;
  top: 30px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  z-index: 2;
}
.top:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 30px solid #faf9f5;
}
.right:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid #faf9f5;
}
.left:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 30px solid #faf9f5;
}
