.loginPage {
  background-color: #f9f8f4;
  width: 100%;
  min-height: calc(100vh - 161px);
}
.loginContainer {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 31px 151px 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.brdr {
  width: 1px;
  height: 788px;
  margin-top: 17px;
  background-color: rgba(10, 10, 10, 0.1);
}
.authOptions {
  margin-top: 280px;
}
@media (max-width: 1300px) {
  .loginContainer {
    flex-direction: column;
  }
  .brdr {
    display: none;
  }
  .authOptions {
    margin-top: 70px;
  }
}
