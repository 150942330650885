.card {
  width: 384px;
  min-height: 688px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 64px 24px 24px;
  border-radius: 50px;
  background-color: rgba(250, 249, 245, 0.97);
  position: relative;
}
.selectedCard p {
  color: white;
}
.selectedCard {
  background-color: var(--black);
  border: 3px solid #32d071;
}

.planName {
  font-family: var(--title-font-family);
  font-weight: 300;
  font-size: 32px;
  height: 63px;
  line-height: 44px;
  margin-bottom: 16px;
  text-align: center;
}
.amount {
  font-family: var(--title-font-family);
  font-size: 125px;
  font-weight: 700;
  line-height: 150px;
  margin-bottom: 39px;
  text-align: center;
}
.amount::before {
  content: "$";
  font-size: 48px;
  font-family: var(--title-font-family);
  line-height: 80px;
  font-weight: 700;
  vertical-align: top;
}
.list {
  list-style: none;
  margin-left: 24px;
}
.planDescription {
  font-family: var(--title-font-family);
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: rgba(10, 10, 10, 0.6);
  margin-bottom: 16px;
  position: relative;
  padding-left: 30px;
}
.planDescription::before,
.faqLink::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(45deg);
  height: 17px;
  width: 8px;
  border-bottom: 2.5px solid var(--black);
  border-right: 2.5px solid var(--black);
}

.selectedCard .planDescription {
  color: white;
}

.popularIcon {
  position: absolute;
  width: 68px;
  height: 80px;
  top: -40px;
  left: 158px;
}
.one-time {
  font-size: 28px;
  font-weight: 700;
  line-height: 72px;
}
.recurring {
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
}
.hintText {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #0a0a0a;
  margin-bottom: 16px;
  margin-left: 20px;
}
.faqLink {
  position: relative;
  padding-left: 30px;
}
.faqLink a {
  font-family: var(--text-font-family);
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: var(--black);
}
.selectBtn {
  width: 336px;
  margin: 0 auto;
  border-radius: 50px;
  height: 68px;
  outline: none;
  background-color: transparent;
  border: 1px solid var(--black);
  font-family: var(--text-font-family);
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--black);
  cursor: pointer;
}
.card:hover .selectBtn {
  background-color: var(--black);
  color: #ffffff;
}
.loadingBg {
  background-color: transparent !important;
  color: var(--black) !important;
  cursor: not-allowed;
}
