.container {
  width: 100%;
  background: #faf9f5;
  border-radius: 0px 170px 0px 0px;
  padding: 72px 72px 104px;
}
.content {
  max-width: 1296px;
  margin: auto;
}
.sectionTitle {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 72px;
  color: var(--black);
  margin-bottom: 32px;
}
.faqContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.firstCol,
.secondCol {
  max-width: 564px;
}
.faq {
  margin-bottom: 56px;
}
.question,
.answer {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.02em;
}
.question {
  color: var(--black);
  margin-bottom: 16px;
}
.answer {
  color: rgba(10, 10, 10, 0.6);
  white-space: pre-wrap;
}
