.header {
  width: 100%;
  background-color: var(--lighter-gray-color);
  margin-bottom: 9px;
}
.headerContent {
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 71px 61px 69px 69px;
}
.term {
  font-family: var(--text-font-family);
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  color: #020202;
  padding-bottom: 10px;
  text-transform: capitalize;
}
.count {
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(1, 3, 2, 0.7);
}
.btnsContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.location,
.filters {
  width: 299px;
  height: 68px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.notActive,
.filters {
  background: #f2f1ed;
  color: var(--black);
}
.active {
  background: #536eff;
  color: #ffffff;
}
.filters {
  display: none;
}
@media (max-width: 1100px) {
  .headerContent {
    display: block;
    padding: 71px 61px 49px 31px;
  }
  .btnsContainer {
    margin-top: 30px;
  }
  .location,
  .filters {
    width: 199px;
    height: 48px;
    font-size: 14px;
    line-height: 17px;
  }
  .term {
    font-size: 20px;
    line-height: 30px;
  }
  .count {
    font-size: 12px;
    line-height: 16px;
  }
  .filters {
    display: initial;
  }
}
@media (max-width: 1000px) {
  .headerContent {
    padding: 41px 61px 49px 31px;
  }
}
