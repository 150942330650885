.content {
  position: relative;
  margin: 50px auto 80px;
  max-width: 970px;
  display: flex;
}
.info {
  margin-top: 80px;
}
.title,
.desc,
.add {
  height: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.title {
  width: 204px;
}
.desc {
  width: 254px;
}
.add {
  width: 300px;
}
.imgBtn {
  position: relative;
  max-width: 529.14px;
  margin: auto;
}
.heroImg {
  width: 529.14px;
  height: 693.57px;
  border-radius: 264.569px 221.353px 231.894px 264.569px;
}
.learn {
  width: 180px;
  height: 180px;
  z-index: 2;
  border-radius: 126.437px 82.1839px 139.08px;
  position: absolute !important;
  bottom: 167px;
  right: -90px;
}
.loading {
  position: relative;
  background: #f2f1ed;
}
.learnLoading {
  position: relative;
  background: #f2f1ed;
}
.learnLoading::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 126.437px 82.1839px 139.08px;

  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-20px);

  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  animation: loading 0.8s 2s infinite;
}
.loading:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100px);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  animation: loading 0.8s infinite;
}
@keyframes loading {
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
@media (max-width: 1080px) {
  .content {
    flex-direction: column-reverse;
  }
  .info {
    max-width: 320px;
    margin: 0 auto 40px;
  }
}

@media (max-width: 866px) {
  .content {
    max-width: 100%;
    text-align: center;
  }
  .title {
    width: 144px;
    border-radius: 15px;
  }
  .desc {
    width: 244px;
    border-radius: 15px;
  }
  .imgBtn {
    max-width: 251px;
  }
  .heroImg {
    width: 221px;
    height: 319px;
    border-radius: 125.5px 105px 110px 125.5px;
  }
  .learn {
    width: 70px;
    height: 70px;
    font-size: 11px;
    z-index: 2;
    text-align: center;
    top: 12px;
    right: -20px;
  }
}
