.addToCollectionBtn {
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(250, 249, 245, 0.9);
}
.main {
  width: 32px;
  height: 32px;
  border-radius: 50px;
}
.active {
  background-color: #ff6e26 !important;
}
.activePath {
  display: initial;
}
.notActivePath {
  display: none;
}
.active path {
  stroke: #f9f8f4;
  fill: #f9f8f4;
}
.loader {
  width: 10px !important;
  height: 10px !important;
}
.loadingBg {
  background-color: rgba(250, 249, 245, 0.9) !important;
}
@media (hover: hover) and (pointer: fine) {
  .addToCollectionBtn:hover {
    background-color: #ff6e26;
  }
  .loadingBg:hover {
    background-color: rgba(250, 249, 245, 0.9) !important;
  }
  .addToCollectionBtn:hover .notActivePath {
    display: initial;
  }
  .addToCollectionBtn:hover path:not(.plusPath) {
    stroke-width: 0;
    fill: #f9f8f4;
  }
  .addToCollectionBtn:hover .plusPath {
    fill: #f9f8f4;
    stroke: #f9f8f4;
  }
}
