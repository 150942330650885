.popUp {
  width: 355px;
}
.close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-top: 10px;
}
.confirm,
.cancel {
  height: 46.13px;
  width: 145.48px;
  background: #0a0a0a;
  border-radius: 50px;
  cursor: pointer;
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--lighter-gray-color);
  border: none;
  outline: none;
}
.btnsContainer {
  display: flex;
  align-items: center;
}
.confirm {
  margin-right: 13.14px;
  background-color: #bf3e35;
}
.message {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  padding: 30px 0 18px;
}
.loading {
  border: 1px solid var(--black);
  background-color: var(--lighter-gray-color);
  cursor: not-allowed;
}
.loader {
  width: 30px !important;
  height: 30px !important;
}
