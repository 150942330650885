.registerForm {
  max-width: 663px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header {
  font-size: 48px;
  line-height: 52px;
  color: var(--black);
  font-weight: 400;
  font-family: var(--title-font-family);
  width: 100%;
  padding: 10px 0 57px;
}
.altHeader {
  font-family: var(--title-font-family);
  width: 100%;
  padding: 10px 0 57px;
  font-family: Halyard Display;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
}
.altHeaderWithDescription {
  padding: 10px 0 20px;
}
.halfWidthContainer label,
.fullWidthContainer label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: var(--text-font-family);
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(1, 3, 2, 0.7);
}
.halfWidthContainer {
  width: 323.5px;
}
.fullWidthContainer {
  width: 100%;
}
.halfWidthContainer input,
.fullWidthContainer input,
.halfWidthContainer select,
.fullWidthContainer select {
  width: 100%;
  height: 56px;
  border: 1.5px solid #0a0a0a;
  border-radius: 10px;
  outline: none;
  padding-left: 24px;
  margin: 16px 0 24px;
  font-size: 20px;
  line-height: 28px;
  color: var(--black);
  font-family: var(--title-font-family);
  padding-bottom: 4px;
}
.continueBtn {
  width: 325px;
  height: 68px;
  border-radius: 50px;
  font-size: 16px;
  line-height: 23px;
}
.invalid label {
  color: #ee4655;
}
.invalid input,
.invalid select {
  margin-bottom: 8px;
  border: 1.5px solid #ee4655;
  color: #ee4655;
}
.invalid input::placeholder,
.invalid select::placeholder {
  color: #ee4655;
}
.termsContainer {
  width: 100%;
  margin-bottom: 20px;
}
.checkContainer {
  width: 281px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.checkContainer label,
.checkContainer label a {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
}
.checkContainer input {
  opacity: 0;
  width: 23px;
  height: 23px;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.checkContainer span {
  position: absolute;
  left: 0;
  width: 23px;
  height: 23px;
  border-radius: 4px;
  border: 1px solid rgba(10, 10, 10, 0.39);
  background-color: transparent;
}
.checkMark {
  background-color: var(--black) !important;
}
.checkMark::before {
  content: "";
  position: absolute;
  display: block;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 735px) {
  .halfWidthContainer {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .continueBtn {
    width: 100%;
    height: 56px;
    font-size: 12px;
  }
}
