.newCommentContainer {
  width: 100%;
  margin: 6.99px auto 0;
  background-color: var(--lighter-gray-color);
  padding: 20.97px 0 20.96px;
  text-align: center;
}
.newComment {
  display: flex;
  align-items: center;
  max-width: 1440px;
  padding: 0 64px;
  margin: auto;
}

.commentInp {
  width: 85%;
}
.profilePic {
  width: 39.94px;
  height: 39.94px;
  border-radius: 50%;
  margin-right: 11.98px;
}
.commentInp input {
  width: 100%;
  border: none;
  outline: none;
  height: 39.94px;
  background-color: transparent;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 19.9723px;
  line-height: 27px;
}
.commentInp input::placeholder {
  letter-spacing: -0.02em;
  color: #acaca9;
}
.submitBtn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.submitBtn svg {
  width: 23.47px;
  height: 19.97px;
}
.signInBtn {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 18.9723px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: var(--black);
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.loader {
  width: 30px;
  height: 30px;
}
@media (max-width: 650px) {
  .newCommentContainer {
    padding: 20.97px 0 20.96px;
  }
  .title {
    font-size: 32px;
    line-height: 36px;
  }
  .newComment {
    max-width: 100%;
    padding: 0 32px;
  }
}
