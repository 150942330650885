.filtersList,
.appliedList {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}
.filtersList {
  max-width: 139px;
}
.filter,
.appliedFilter {
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 12px 0 12.5px;
}
.filter {
  height: 27px;
  border: solid 0.75px var(--black);
}
.appliedFilter {
  background-color: #f2f1ed;
  border: none;
  height: 50px;
}
.icon {
  margin-right: 3.81px;
}
.remove {
  width: 13.95px;
  height: 13.95px;
  margin: 0 0 4px 7px;
}
.filterName {
  font-size: 12px;
  font-family: var(--title-font-family);
  font-weight: 400;
  color: #000000;
}
.appliedName {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0a0a0a;
}
