.adminUserManagement {
  padding: 63px 82px 0 63px;
  height: 100%;
  min-width: 900px;
  overflow: auto;
}
.pageLoader {
  width: 80px;
  height: 80px;
  margin-top: 100px;
}
.paginatorRef {
  width: 100%;
  height: 20px;
}
.table {
  width: 100%;
}
