.paymentMethodContainer {
  width: 100%;
  min-width: 600px;
  min-height: calc(100vh - 119px);
  display: flex;
  flex-direction: column;
  padding: 50px;
}
.title {
  font-size: 48px;
  font-family: var(--title-font-family);
  margin-bottom: 46px;
  font-weight: unset;
}
