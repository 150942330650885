.discoverPage {
  background-color: #f2f1ed;
  min-height: calc(100vh - 161px);
}
.overFlowContainer {
  position: relative;
  overflow: hidden;
}
.imgBtnContainer {
  position: relative;
  width: 671px;
  height: 1059px;
}
.adImg {
  width: 515.83px;
  height: 814.11px;
  position: absolute;
  left: 0;
  bottom: -175px;
}
.learn {
  width: 138.38px;
  height: 138.38px;
  background-color: var(--black);
  color: #f9f8f4;
  text-decoration: none;
  position: absolute;
  font-size: 15.375px;
  line-height: 18px;
  z-index: 1;
  left: 461.42px;
  bottom: 263.81px;
  border-radius: 97.1983px 63.1789px 106.918px;
  font-family: var(--text-font-family);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
}
.learn:hover {
  background-color: #ff6e26;
}
