.nextBtn {
  background-color: #f2f1ed;
  position: absolute;
  right: 13.22px;
  top: 331px;
  cursor: pointer;
  border: 0.770625px solid #deddd9;
  border-radius: 43.155px;
  width: 49.32px;
  height: 49.32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.nextBtn svg {
  width: 18.08px;
  height: 19.73px;
}
@media (max-width: 630px) {
  .nextBtn {
    width: 30px;
    height: 30px;
    top: 50%;
  }
  .nextBtn svg {
    width: 12px;
    height: 15px;
  }
}
