.header {
  padding: 0 68px 0 30px;
}
.secondRow {
  width: 100%;
  margin: 21px 0 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
