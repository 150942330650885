.winnersSection {
  width: 100%;
  margin: 152px auto 0;
  background: rgba(242, 241, 237, 0.2);
  border-radius: 0px 170px 0px 0px;
}
.desc {
  font-family: var(--text-font-family);
  font-style: normal;
  margin-top: 120px;
}
.title {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(242, 241, 237, 0.7);
  font-weight: 300;
  margin-bottom: 40px;
}
.winnersContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding-left: 32px;
  padding-top: 100px;
}
@media (max-width: 980px) {
  .winnersSection {
    padding: 0 0 0 20px;
  }
  .desc {
    margin-top: 40px;
  }
  .title {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 20px;
  }
  .winnersSection {
    border-radius: 0px 70px 0px 0px;
  }
}
@media (max-width: 630px) {
  .desc {
    margin-top: 0px;
  }
  .title {
    margin-bottom: 20px;
  }
  .winnersContainer {
    padding-left: 0;
  }
}
