.footerSection {
  width: 100%;
}
.regFooter {
  background-color: #f2f1ed;
}
.landingFooter {
  background-color: var(--black);
}
.container {
  padding: 88px 0 61px;
  width: 100%;
  border-radius: 0px 76px 0px 0px;
}
.regFooter .container {
  background-color: var(--black);
}
.landingFooter .container {
  background-color: rgba(242, 241, 237, 0.2);
}
.firstCol {
  display: flex;
  flex-direction: column;
  gap: 70px;
}
.footerContainer {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.list {
  list-style: none;
}
.item a,
.listed {
  text-decoration: none;
  font-size: 20px;
  font-family: var(--title-font-family);
  color: var(--white);
  line-height: 36px;
  font-weight: 400;
}
.listed {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.rightsContainer {
  width: 100%;
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.rightsText {
  font-size: 16px;
  font-family: var(--title-font-family);
  line-height: 36px;
  color: var(--white);
  font-weight: 400;
}
.topLogo,
.bottomLogo {
  width: 111px;
  height: 111px;
}
@media (max-width: 1500px) {
  .container {
    padding: 88px 63px 61px 64px;
  }
}
@media (max-width: 1270px) {
  .list {
    width: 28%;
  }
  .list:not(:nth-child(3)) {
    margin-bottom: 50px;
  }
  .topLogo {
    width: 45%;
  }
}
@media (max-width: 747px) {
  .container {
    padding: 88px 30px 61px 31px;
  }
  .list {
    width: 100%;
  }
  .list:not(:nth-child(4)) {
    margin-bottom: 50px;
  }
  .topLogo {
    margin-bottom: 50px;
  }
}
@media (max-width: 553px) {
  .bottomLogo {
    margin-bottom: 50px;
  }
}
@media (max-width: 450px) {
  .rightsText {
    font-size: 12px;
  }
}
