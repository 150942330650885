.registerPage {
  background: #f9f8f4;
  min-width: 100%;
  min-height: calc(100vh - 161px);
}
.registerContainer {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.btnContainer {
  width: 100%;
  text-align: left;
  padding: 50px 50px 0px;
}
.backBtn {
  width: 50px;
  height: 20px;
  cursor: pointer;
}

@media (max-width: 1370px) {
  .registerContainer {
    flex-direction: column;
    justify-content: center;
  }
}

.progressBarContainer {
  height: 4px;
  width: 100%;
  background-color: "#c9c8c5";
}

.filledProgressBar {
  height: 100%;
  background-color: black;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.loader {
  margin-top: 200px;
}
.gettingStartedContainer {
  width: 100%;
  display: flex;
  overflow-y: hidden;
}

.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
}
.restSteps {
  width: 100%;
}
.tierStep {
  width: 100%;
  background-color: #f2f1ed;
}
.pageLoader {
  width: 80px;
  height: 80px;
}
.pageLoader::after {
  background-color: #f2f1ed;
}
.support {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--black);
}
