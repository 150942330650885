.title {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: rgba(10, 10, 10, 0.9);
  padding-bottom: 20px;
}
.inpContainer {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  margin-bottom: 5px;
}
.inpContainer label {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  position: absolute;
  left: 26px;
}
.inpContainer input {
  opacity: 0;
  width: 23px;
  height: 23px;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.inpContainer span {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid rgba(10, 10, 10, 0.39);
  background-color: transparent;
}
.checkMark {
  background-color: var(--black) !important;
}
.loader {
  margin: 20px 0 0 30px;
}
.errorMessage {
  padding-left: 0;
  font-size: 14px;
}
