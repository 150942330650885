.container {
  padding: 71.9px 64px 32px;
  max-width: 1440px;
  margin: auto;
}
.title {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 47.9335px;
  line-height: 60px;
  color: #020202;
  padding-bottom: 60.92px;
}
.comments {
  max-height: 300px;
  overflow: auto;
}
.comment {
  display: flex;
  align-items: flex-start;
}
.comment:not(:last-child) {
  padding-bottom: 36.95px;
}
.profilePic {
  width: 39.94px;
  height: 39.94px;
  border-radius: 50%;
  margin-right: 7.99px;
}
.userName {
  font-family: var(--text-font-family);
  font-weight: 600;
  font-size: 19.9723px;
  line-height: 21px;
  color: #0a0a0a;
  padding-bottom: 3.99px;
}
.userComment {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 19.9723px;
  line-height: 21px;
  color: #0a0a0a;
  padding-left: 6.99px;
}
.createdSince {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 15.9778px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(10, 10, 10, 0.5);
}
.viewContainer {
  text-align: center;
  padding: 20px 0 10px;
}
.viewAll {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 18.9723px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: var(--black);
  text-align: center;
  border: none;
  outline: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}
.errorMessage {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ee4655;
  padding-bottom: 8px;
}
.spinner {
  width: 100px !important;
  height: 100px !important;
  margin: 100px auto;
}
.loader {
  width: 80px;
  height: 80px;
}

@media (max-width: 650px) {
  .sectionContainer {
    border-radius: 0px 90px 0px 0px;
  }
  .container {
    padding: 71.9px 32px 32px;
  }
  .comments {
    padding-bottom: 20px;
  }
  .title {
    font-size: 32px;
    line-height: 36px;
  }
  .userName {
    font-size: 14px;
    line-height: 19px;
  }
  .userComment {
    font-size: 14px;
    line-height: 19px;
  }
  .createdSince {
    font-size: 12px;
    line-height: 16px;
  }
  .viewContainer {
    padding-bottom: 18px;
  }
  .viewAll {
    font-size: 14px;
    line-height: 21px;
  }
}
