.title {
  font-size: 48px;
  font-family: var(--title-font-family);
  color: #f9f8f4;
  padding-bottom: 70px;
  text-align: center;
  font-weight: 400;
}
.optionsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.option {
  display: flex;
  align-items: center;
}
.optionImg {
  width: 198px;
  height: 257px;
  margin-right: 42px;
}
.desc {
  text-align: center;
  max-width: 316px;
}
.optionTitle {
  font-size: 32px;
  font-family: var(--text-font-family);
  color: #f9f8f4;
}
.optionDesc {
  font-size: 20px;
  font-family: var(--text-font-family);
  color: #f9f8f4;
  padding: 19px 0 20px;
}
.optionLink {
  outline: none;
  border: none;
  width: 267px;
  height: 68px;
  background-color: var(--white);
  border-radius: 50px;
  color: var(--black);
  font-size: 16px;
  font-family: var(--text-font-family);
  cursor: pointer;
}
@media (max-width: 1200px) {
  .optionsContainer {
    justify-content: center;
  }
  .option:nth-child(1) {
    margin-bottom: 60px;
  }
}
@media (max-width: 803px) {
  .option {
    flex-direction: column;
  }
  .optionImg {
    margin-right: 0;
  }
  .title {
    font-size: 36px;
  }
}
