.inpContainer {
  display: block;
  width: 500px;
}
.inpContainer input,
.inpContainer select {
  width: 100%;
  outline: none;
}
.errorMessage {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ee4655;
  padding-bottom: 8px;
}

.suggestionItem {
  cursor: pointer;
  width: 100%;
  background-color: white;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 10px;
}
.activeSuggestion {
  background-color: #fafafa;
}
.notActiveSuggestion {
  background-color: #ffffff;
}
.suggestionItem > hr {
  margin-top: 16px;
  border-top: 1px solid var(--black);
}

.multipleLabelContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
}
.z3 {
  z-index: 2;
}
.z4 {
  z-index: 3;
}
.minRange,
.maxRange {
  position: absolute;
  top: 13px;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.textArea {
  padding: 13px 24px;
  width: 662px;
  height: 245px;
  border: 1.5px solid #0a0a0a;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 20px;
  background-color: transparent;
}

.textArea::placeholder {
  font-family: "Arial";
  font-size: 20px;
  color: var(--black);
}
.invalid label {
  color: #ee4655 !important;
}
.invalid input,
.invalid textarea,
.invalid select {
  margin-bottom: 8px;
  border: 1.5px solid #ee4655 !important;
  color: #ee4655;
}
.invalid input::placeholder {
  color: #ee4655;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.searchIcon{
  width: 23px;
  position: absolute;
  height: initial;
  left:22px;
}