.hero {
  max-width: 1440px;
  margin: auto;
  padding: 104px 61px 152px 57px;
  position: relative;
}
.title,
.presentedText,
.descText {
  font-family: var(--title-font-family);
  font-style: normal;
  color: #f2f1ed;
  z-index: 2;
  position: relative;
  font-weight: 300;
}
.third {
  display: block;
  font-size: 20px;
  line-height: 0px;
}
.main,
.entrepreneur {
  display: block;
  font-size: 125px;
  line-height: 115px;
}
.resposiveTitle,
.resPresented,
.resAwardYear {
  display: none;
}
.titlePad {
  padding-left: 100px;
}
.heroContainer {
  display: flex;
  column-gap: 10px;
  margin-left: 25%;
}
.desc {
  text-align: right;
  position: relative;
}
.heroImg {
  width: 444px;
  height: 600px;
  box-shadow: -10px -30px 300px 20px #ff6e26;
  border-radius: 154px 93px 148px 222px;
  object-fit: cover;
}
.awardYear {
  position: absolute;
  top: -48px;
  right: 92px;
}
.sponsers {
  display: flex;
  align-items: center;
  column-gap: 16px;
  position: relative;
  z-index: 2;
}
.brdr {
  width: 1px;
  height: 25px;
  background: rgba(242, 241, 237, 0.2);
}
.presented,
.resPresented {
  align-items: center;
  column-gap: 8px;
  margin: auto;
  margin-right: 130px;
  position: relative;
  z-index: 2;
}
.presented {
  display: flex;
  flex-direction: row-reverse;
}
.presentedText {
  font-size: 20px;
  line-height: 27px;
  display: inline-block;
}
.descText {
  max-width: 520px;
  text-align: left;
  font-size: 28px;
  line-height: 39px;
  margin-top: 260px;
}
@media (max-width: 1440px) {
  .heroContainer {
    max-width: 977px;
    margin-left: auto;
    margin-right: 0;
  }
}
@media (max-width: 1250px) {
  .restTitle {
    display: none;
  }
  .title {
    display: inline-block;
  }
  .heroContainer {
    column-gap: 30px;
  }
  .descText {
    margin-top: 50px;
  }
  .main,
  .entrepreneur {
    display: inline-block;
    font-size: 75px;
    line-height: 95px;
  }
  .titlePad {
    padding-left: 0;
  }
  .resposiveTitle,
  .resAwardYear {
    display: block;
  }
  .resPresented {
    display: flex;
    max-width: 110px;
    align-items: center;
    margin: 0px 0 20px auto;
    column-gap: 2px;
  }
  .sponsers {
    column-gap: 8px;
  }
  .presentedText {
    font-size: 10px;
    line-height: 15px;
    display: inline-block;
    padding-top: 10px;
  }
  .clover {
    width: 50px;
  }
  .brdr {
    margin-top: 4px;
    height: 20px;
  }
  .comcast {
    width: 50px;
  }
  .resposiveTitle {
    font-size: 85px;
    line-height: 65px;
  }
  .heroImg {
    width: 344px;
    height: auto;
    box-shadow: -10px -30px 300px 20px #ff6e26;
    border-radius: 154px 93px 148px 222px;
  }
}
@media (max-width: 875px) {
  .hero {
    padding: 64px 21px 112px 22px;
  }

  .heroImg {
    width: 244px;
  }
  .main,
  .entrepreneur,
  .resposiveTitle {
    font-size: 40px;
    line-height: 45px;
  }
  .third {
    font-size: 18px;
    line-height: 15px;
  }
  .descText {
    font-size: 20px;
    line-height: 26px;
    margin-top: 40px;
  }
  .resPresented {
    height: 10px;
  }
}
@media (max-width: 675px) {
  .heroImg {
    width: 144px;
    box-shadow: -10px -30px 300px 10px #ff6e26;
  }
  .main,
  .entrepreneur,
  .resposiveTitle {
    font-size: 30px;
    line-height: 35px;
  }
  .third {
    font-size: 12px;
  }
  .descText {
    font-size: 14px;
    line-height: 19px;
    margin-top: 30px;
    max-width: 250px;
  }
}
