.pickImg {
  width: 261px;
  height: 343px;
  margin-bottom: 20px;
  overflow: hidden;
}
.pick {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.business,
.name {
  height: 10px;
  border-radius: 20px;
  margin: auto;
}
.business {
  width: 200px;
}
.name {
  width: 150px;
  margin-top: 10px;
}
@media (max-width: 720px) {
  .pickImg {
    width: 150px;
    height: 197px;
  }
  .business {
    width: 150px;
  }
  .name {
    width: 110px;
  }
}
