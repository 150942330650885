.popUp {
  width: 375px;
}
.close {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.popUpHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 32px;
  line-height: 60px;
  font-family: var(--title-font-family);
  color: #020202;
  font-weight: 400;
}
.text {
  font-family: var(--text-font-family);
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: var(--black);
  padding: 13px 0 35px;
}
.login,
.signup {
  width: 313px;
  height: 60px;
  outline: none;
  border-radius: 50px;
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.02em;
  cursor: pointer;
}
.login {
  color: #f9f8f4;
  background-color: var(--black);
  margin-bottom: 20px;
  border: none;
}
.signup {
  color: var(--black);
  background-color: #f9f8f4;
  border: 1px solid #0a0a0a;
}
@media (max-width: 500px) {
  .popUp {
    max-width: 90%;
  }
  .login,
  .signup {
    max-width: 100%;
  }
  .title {
    font-size: 28px;
    line-height: 34px;
  }
  .text {
    font-size: 16px;
    line-height: 27px;
  }
}
