.forgotPage {
  background-color: #f9f8f4;
  width: 100%;
  min-height: calc(100vh - 161px);
}
.forgotContainer {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 196px 31px 151px 32px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 999px) {
  .forgotPage {
    min-height: calc(100vh - 131px);
  }
}
@media (max-width: 710px) {
  .forgotPage {
    min-height: calc(100vh - 90px);
  }
}
