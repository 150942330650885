.title {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: rgba(10, 10, 10, 0.9);
  padding-bottom: 41px;
}

.inpContainer {
  position: relative;
  pointer-events: none;
  width: 183px;
}
.inpContainer input::-webkit-slider-thumb {
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #000000;
  cursor: pointer;
  height: 21px;
  width: 21px;
  pointer-events: all;
  position: relative;
}
.inpContainer input::-moz-range-thumb {
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #000000;
  cursor: pointer;
  height: 21px;
  width: 21px;
  pointer-events: all;
  position: relative;
}
.inpContainer input,
.inpContainer input::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.inpContainer input::-moz-range-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.inpContainer input {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 183px;
  outline: none;
}
.slider {
  width: 100%;
  position: absolute;
  top: 0;
  height: 2px;
  background-color: #000000;
}
.container {
  display: flex;
  align-items: center;
  gap: 3px;
}
.mark {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
.loader {
  margin: 20px 0 0 30px;
}
.errorMessage {
  padding: 0;
  font-size: 14px;
}
