.enabled {
  background-color: var(--black);
  color: #ffffff;
  cursor: pointer;
  outline: none;
  border: none;
}
.disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
  outline: none;
  border: none;
}
.edit {
  z-index: 2;
}
.enabled:hover {
  background-color: #ff6e26;
}
