.navigation {
  width: 100%;
  background: #f9f8f4;
  box-shadow: 6px 6px 16px rgba(230, 229, 225, 0.5);
  position: relative;
}
.landing {
  background: var(--black);
  box-shadow: none;
}
.businessOnboardingNav {
  position: unset;
  transition: unset;
}
.onBoarding {
  z-index: 11;
  pointer-events: none;
}
.regular {
  z-index: 7;
}
.navHeader {
  display: flex;
  max-width: 1440px;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  flex: auto;
  padding: 30px 32px 0 32px;
}
.logo {
  width: 63px;
  height: 60px;
  margin-right: 32px;
}
.forBusinessHeader {
  font-family: var(--title-font-family);
  font-size: 36px;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
  flex-grow: 1;
}
.inpContainer,
.landingInpContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.inpContainer {
  width: 100%;
}
.landingInpContainer {
  width: 100%;
}
.searchBar,
.landingSearch {
  width: 100%;
  height: 56px;
  outline: none;
  border-radius: 200px;
  padding-left: 32px;
  padding-bottom: 4px;
  font-size: 24px;
  font-family: var(--title-font-family);
  font-weight: 400;
}
.searchBar {
  border: 1.5px solid rgba(10, 10, 10, 0.25);
  color: var(--black);
}
.landingSearch {
  border: 1.5px solid rgba(242, 241, 237, 0.2);
  color: #fff;
  background-color: transparent;
}
.authState {
  text-align: right;
}
.loader {
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  background: #f2f1ed;
  float: right;
}
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100px);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  animation: loading 0.8s infinite;
}
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
.collections {
  cursor: pointer;
}
.regCollection {
  stroke: #0a0a0a;
}
.landingCollection {
  stroke: #f2f1ed;
}
.bookmarked {
  stroke: #ff6e26;
}
.bookmarked path {
  fill: #ff6e26;
}
.collections:hover path {
  fill: #ff6e26;
}
.collections:hover {
  stroke: #ff6e26;
}
@media (max-width: 998px) {
  .navItems,
  .authState,
  .collections {
    display: none;
  }
  .onBoarding {
    pointer-events: initial;
  }
  .navHeader {
    padding: 31px 32px 34px;
  }
}
@media (max-width: 710px) {
  .navHeader {
    justify-content: space-between;
    padding: 10px 32px 11px;
  }

  .inpContainer,
  .landingInpContainer {
    display: none;
  }
}
@media (max-width: 450px) {
  .navigation {
    z-index: 4;
  }
  nav.landing {
    background: var(--black);
    box-shadow: none;
  }

  .drawerBg {
    background: #ffffff;
    box-shadow: none;
  }
  .normalBg {
    background: #f9f8f4;
    box-shadow: 6px 6px 16px rgba(230, 229, 225, 0.5);
  }
}
