.signUpAd {
  width: 100%;
  height: 600px;
  overflow: hidden;
  background-color: #f2f1ed;
  position: relative;
}
.container {
  width: 100%;
  max-width: 1440px;
  height: 600px;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
}
.text {
  width: 479px;
  font-size: 61.5px;
  line-height: 62px;
  font-family: var(--title-font-family);
  color: #020202;
  font-weight: 400;
}
.adImg {
  display: none;
}
.largeAdImg {
  width: 515.83px;
  height: 814.11px;
  position: absolute;
  left: 0;
  top: -53px;
}
.learn {
  width: 138.38px;
  height: 138.38px;
  background-color: var(--black);
  color: #f9f8f4;
  text-decoration: none;
  position: absolute;
  font-size: 15.375px;
  line-height: 18px;
  z-index: 1;
  left: 461.42px;
  bottom: 263.81px;
  border-radius: 126.437px 82.1839px 139.08px;
  display: flex;
  align-items: center;
  font-family: var(--text-font-family);
  justify-content: center;
}
.learn:hover {
  background-color: #ff6e26;
}
