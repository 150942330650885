@import "react-toastify/dist/ReactToastify.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: "halyard text";
  src: url("./fonts/Halyard\ Text\ Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "halyard display";
  src: url("./fonts/Halyard\ Display\ Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "inter sm";
  src: url("./fonts/Inter-SemiBold.woff ");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  /* Colors */
  --black: #0a0a0a;
  --brand-color: #ff6e26;
  --lighter-gray-color: #faf9f5;
  --light-gray-color: #f8f7f3;
  --white: #ffffff;
  /* Fonts */
  --title-font-family: "halyard display";
  --text-font-family: "halyard text";
  --inter-semi-bold: "inter sm";
  --toastify-color-success: var(--brand-color) !important;
  --toastify-color-error: #ee4655 !important;
  --toastify-color-info: var(--brand-color) !important;
  --toastify-font-family: "halyard display" !important;
  --toastify-toast-width: auto !important;
}
.Toastify__progress-bar {
  height: 3px !important;
}

.Toastify__toast-container {
  max-width: 80%;
}

.notifyAnimation {
  animation: notify 0.3s forwards;
}
@keyframes notify {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.showPopup {
  transform: translateY(0);
}
.hidePopup {
  transform: translateY(-180%);
}

div::-webkit-scrollbar {
  width: 4px;
}

div::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb:hover {
  background: #555;
}
