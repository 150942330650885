.sideToggle {
  width: 18px;
  height: 16.5px;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: flex-end;
  cursor: pointer;
  z-index: 5;
  position: relative;
}
.sideToggle div {
  width: 18px;
  height: 1.5px;
  background-color: #6b7280;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 999px) {
  .sideToggle {
    display: none;
  }
}
