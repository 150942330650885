.bizContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #f9f8f4;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  padding: 16px 0;
}
:global(.gm-style .gm-style-iw-d) {
  overflow: auto !important;
}
:global(.gm-style .gm-style-iw-c) {
  padding: 0;
}
:global(.gm-style .gm-style-iw-tc::after) {
  background: #f9f8f4;
}

.row {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0 16px;
}
.bizImg {
  border-radius: 51px 31px 146px 112px;
  width: 48px;
  height: 62px;
  object-fit: cover;
}
.headerInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.bizContainer a {
  font-size: 16px;
  color: var(--black);
  font-weight: 300;
  font-family: var(--title-font-family);
}
.bizContainer h6 {
  font-size: 12px;
  color: rgba(1, 3, 2, 0.7);
  font-weight: 400;
  letter-spacing: 0.02em;
  font-family: var(--title-font-family);
}
.bizContainer span {
  font-size: 16px;
  color: var(--black);
  font-weight: 400;
  font-family: var(--title-font-family);
}
.brdr {
  background: #d6d5d2;
  height: 1px;
  width: 100%;
}
.itmImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.center {
  text-align: center;
}
.directionsBtn {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: #ff6e26;
  font-weight: 400;
  font-family: var(--title-font-family);
  letter-spacing: 0.02em;
  cursor: pointer;
}
.noUnderline {
  text-decoration: none;
}
