.tooltip {
  position: absolute;
  top: 80px;
  left: 0px;
  border-radius: 20px !important;
}
.tooltip::before {
  left: 30%;
  top: -20px;
}
.imagesForm {
  max-width: 700px;
  margin: auto;
}

.dropzoneContainer {
  position: relative;
}
.imagesForm {
  width: 700px;
  margin: auto;
}
.dropzoneContainer > label:last-of-type > div {
  width: 100%;
  height: 244px;
  border: 2px dashed rgba(10, 10, 10, 0.1);
  box-sizing: border-box;
  margin-top: 14px;
}
.chosenContainer > label:last-of-type > div {
  border: 1px solid rgba(10, 10, 10, 0.1);
}
.dropzoneContainer input {
  display: none;
}

.previewsContainer {
  position: absolute;
  top: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  gap: 10px;
}

.inpContainer {
  width: 100%;
}
.inpContainer input {
  width: 100%;
}
.inpContainer label {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: rgba(10, 10, 10, 0.4);
  padding-bottom: 4px;
  display: block;
}

.imageContainer {
  position: relative;
  cursor: pointer;
}
.remove {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 50px;
  height: 50px;
  z-index: 3;
}
.businessImage {
  width: 160px;
  height: 160px;
  border: 1px solid var(--black);
}

.selectable {
  cursor: pointer;
}

.layer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(250, 249, 245, 0.7),
    rgba(250, 249, 245, 0.7)
  );
  border: 3px solid #1977f3;
}
