.page {
  background-color: #f2f1ed;
  min-height: calc(100vh - 161px);
}
.middleContainer {
  display: flex;
  gap: 10px;
}
.aboutSection {
  max-width: 860px;
  padding: 71.9px 64px 102.17px;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 28px;
}

.rightContainer {
  width: 43%;
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
}
.aboutOwner {
  width: 56%;
}
.comments {
  background-color: var(--lighter-gray-color);
  width: 100%;
  margin-top: 10px;
}
.moreClass {
  width: 100%;
  background-color: #f9f8f4;
  padding: 89px 0 90.5px;
  border-radius: 0px 181px 0px 0px;
}
.overFlowContainer {
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}
.imgBtnContainer {
  position: relative;
  width: 671px;
  height: 1059px;
}
.adImg {
  width: 671px;
  height: 1059px;
  position: absolute;
  left: 0;
  bottom: -175px;
}
.learn {
  width: 180px;
  height: 180px;
  background-color: var(--black);
  color: #f9f8f4;
  text-decoration: none;
  position: absolute;
  font-size: 20px;
  z-index: 1;
  left: 595px;
  bottom: 333px;
  border-radius: 126.437px 82.1839px 139.08px;
  font-family: var(--text-font-family);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1400px) {
  .adImg {
    width: 601px;
    height: 979px;
  }
  .learn {
    left: 520px;
    width: 160px;
    height: 160px;
    font-size: 18px;
  }
}

@media (max-width: 1300px) {
  .adImg {
    width: 371px;
    height: 759px;
  }
  .learn {
    left: 295px;
    width: 130px;
    height: 130px;
    font-size: 14px;
  }
}

@media (max-width: 1180px) {
  .aboutOwner,
  .rightContainer {
    width: 100%;
  }
  .aboutSection {
    margin: 0 auto 28px;
    max-width: 1440px;
    padding: 71.9px 64px 102.17px;
  }
  .middleContainer {
    flex-wrap: wrap;
    gap: 0;
  }
  .comments {
    margin-top: 28px;
  }
}
@media (max-width: 800px) {
  .adImg,
  .learn {
    display: none;
  }
}
@media (max-width: 650px) {
  .aboutSection {
    padding: 71.9px 32px 102.17px;
  }
  .moreClass {
    border-radius: 0px 90px 0px 0px;
  }
}
