.backBtn {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: transparent;
  cursor: pointer;
}
.backArrow {
  width: 39px;
  height: 17px;
}
.backText {
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  font-weight: 400;
}
.editStyle {
  position: absolute;
  left: 42px;
  top: 44px;
  z-index: 2;
}
