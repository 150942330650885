.editorPicksSection {
  width: 100%;
  background-color: #f9f8f4;
  border-radius: 0px 0px 261px 0px;
  padding: 89px 0 90.5px;
}
.recentlyListed {
  width: 100%;
  background-color: #f9f8f4;
  border-radius: 0px 199px 0 199px;
  padding: 89px 0 90.5px;
  margin: 28px 0;
}
.editorPicksContainer {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 0 31px;
}
.sectionTitle {
  font-family: var(--title-font-family);
  font-size: 48px;
  color: var(--black);
  line-height: 72px;
  font-weight: 400;
}
.header {
  margin-bottom: 51px;
}

.picks {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
}
.pick {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  max-width: 259.64px;
}
.onBoarding {
  z-index: 11;
  pointer-events: none;
}
.product,
.business,
.price {
  font-family: var(--text-font-family);
  font-weight: 400;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
}

.pickImg {
  width: 259.64px;
  height: 341px;
  object-fit: cover;
}
.business {
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  font-family: var(--title-font-family) !important;
  padding-top: 20px;
}
.product {
  padding: 8px 0 5px;
  font-size: 20px;
  line-height: 27px;
  text-transform: capitalize;
  color: var(--black);
}
.price {
  color: var(--black);
  font-size: 20px;
  line-height: 25px;
}
.imgContainer {
  position: relative;
}
.upVote {
  width: 49px;
  height: 49px;
  border-radius: 56px;
  backdrop-filter: blur(4px);
  border: 1px solid #deddd9 !important;
  position: absolute;
  right: -10px;
  bottom: -9px;
}
.addToCollection {
  width: 28px;
  height: 28px;
  backdrop-filter: blur(1.64706px);
  border: 1px solid #deddd9 !important;
  border-radius: 20.5882px;
  border: 1px solid #deddd9;

  position: absolute;
  right: -10px;
  bottom: 48px;
}
.loader {
  width: 100px !important;
  height: 100px !important;
  margin: 100px auto;
}
@media (max-width: 1300px) {
  .picks {
    justify-content: flex-start;
    gap: 70px;
  }
  .editorPicksContainer {
    max-width: 1100px;
  }
}
@media (max-width: 850px) {
  .picks {
    column-gap: 25px;
    row-gap: 24px;
  }
  .pickImg {
    width: 150px;
    height: 197px;
  }
  .business,
  .product,
  .price {
    width: 159px;
  }
  .business {
    padding-top: 9px;
  }
  .product {
    padding: 0;
    font-size: 16px;
    line-height: 27px;
  }
  .price {
    font-size: 14px;
    line-height: 21px;
  }
}
@media (max-width: 720px) {
  .editorPicksSection {
    border-radius: 0px 0px 111px 0px;
  }
  .recentlyListed {
    border-radius: 0px 90px 0 90px;
  }
  .sectionTitle {
    font-size: 36px;
  }
}
.upVoteTooltip {
  top: 375px;
  left: 85px;
}
.addToCollectionTooltip {
  top: 320px;
  left: 95px;
}
@media (max-width: 605px) {
  .picks {
    justify-content: center;
  }
}
