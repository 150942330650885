.adminManageTeam{
    padding: 62px 63px 20px 57px;
    height: 100%;
    min-width: 800px;
    overflow: auto;
    flex: 1;
}
.adminTableWrapper{
    margin-top: 76px;
  
}
.addMemberBtn{
    width: 233px;
    height: 42px;
    border: 1px solid var(--black);
    border-radius: 10px;
    margin-top: 26px;
    font-size: 16px;
    font-family: var(--title-font-family);
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color:var(--black);
    background-color: transparent;
    cursor: pointer;
}
.paginatorRef {
    width: 100%;
    height: 20px;
 }