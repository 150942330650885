.sidebar {
  width: 329px;
  overflow: auto;
  background: #f9f8f4;
  padding: 114px 0;
  min-width: 300px;
}
.link,
.active {
  display: block;
  text-decoration: none;
  font-family: var(--title-font-family);
  font-size: 28px;
  line-height: 33px;
  font-weight: 400;
  color: var(--black);
  padding: 11px 64px;
}
.active {
  background: #f2f1ed;
}
.link:hover {
  background: #f2f1ed;
}
.visitLink {
  display: block;
  margin-top: 300px;
  padding-left: 64px;
}
.visitContainer {
  display: flex;
  align-items: center;
}
.visitText {
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-weight: 400;
  color: #1977f3;
  padding-right: 13px;
}
