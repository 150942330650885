.images {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 150px;
  width: 874px;
  height: 100vh;
}
.slick-list > div {
  height: 1086px !important;
}
.judgeImg {
  width: 774px;
  height: 527px;
  margin-bottom: 32px;
}
.wrapper::-webkit-scrollbar,
.images::-webkit-scrollbar {
  display: none;
}
.customScrollbar {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
}
.scrollbarText {
  font-family: "Halyard Text";
  transform: rotate(90deg);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #f2f1ed;
}
.track {
  background-color: rgba(242, 241, 237, 0.2);
  height: 437px;
  width: 2px;
}
.thumb {
  background-color: #f2f1ed;
  height: 25%;
  width: 2px;
  transition: all 0.2s;
}
.loaderStyle {
  border-radius: 62px 132px 76px;
}
@media (max-width: 1275px) {
  .images {
    width: 574px;
  }

  .judgeImg {
    width: 474px;
    height: 337px;
  }
  .slick-list > div {
    height: 486px !important;
  }
}
@media (max-width: 980px) {
  .images {
    width: 324px;
  }

  .judgeImg {
    width: 274px;
    height: 187px;
  }
  .slick-list > div {
    height: 286px !important;
  }
  .track {
    background-color: rgba(242, 241, 237, 0.2);
    height: 237px;
    width: 2px;
  }
}
@media (max-width: 530px) {
  .images {
    width: 164px;
  }

  .judgeImg {
    width: 124px;
    height: 87px;
    margin-bottom: 5px;
  }
  .slick-list > div {
    height: 106px !important;
  }
  .track {
    background-color: rgba(242, 241, 237, 0.2);
    height: 87px;
    width: 2px;
  }
  .scrollbarText {
    font-size: 10px;
    line-height: 16px;
  }
}
