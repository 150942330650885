.businessStepsContainer {
  width: 100%;
  display: flex;
}
.phoneContainer {
  background-color: #f2f1ed;
  min-height: 1023px;
  width: 617px;
  padding-top: 80px;
}
.btnContainer {
  max-width: 663px;
  margin: auto;
}
.backBtn {
  width: 50px;
  height: 20px;
  cursor: pointer;
  margin: 50px 0 20px;
}
.phone {
  min-height: 800px;
  width: 370px;
  margin: auto;
  padding-inline: 10px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../assets/images/businessPhone.svg");
  position: relative;
}

.businessStepsContainer {
  width: 100%;
}
.businessStepContainer {
  position: relative;
  width: 823px;
}
.noBack {
  padding-top: 147px;
}
