.headerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.column {
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.06em;
  color: rgba(10, 10, 10, 0.4);
  text-transform: uppercase;
  width: calc(100% / 4);
}
.sortByBtn {
  font-family: var(--title-font-family);
  font-size: 28px;
  line-height: 33px;
  width: calc(100% / 4);
  color: rgba(10, 10, 10, 0.9);
  text-align: end;
  cursor: pointer;
}
.arrowIcon {
  margin-left: 15px;
}
.rotateArrow {
  transform: rotate(180deg);
}
.alignCenter {
  text-align: center;
}
.column:nth-child(4) {
  text-align: center;
  margin-right: 12px;
}
.dragHeader {
  padding-left: 28px;
}
.rotateArrow {
  transform: rotate(180deg);
}
