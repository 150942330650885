.seeAllBtn {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.seeAllBtn:hover .text,
.seeAllBtn:hover .customText {
  color: var(--brand-color);
}
.seeAllBtn:hover .arrow path,
.seeAllBtn:hover .customTextArrow path {
  stroke: var(--brand-color);
}
.text {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  font-family: var(--text-font-family);
  padding-right: 12px;
}
.arrow {
  width: 39px;
  height: 17px;
}
@media (max-width: 650px) {
  .seeText,
  .customTextArrow {
    display: none;
  }
}
