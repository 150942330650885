.navItems {
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 29px 31px 12px 32px;
  list-style: none;
  margin: auto;
}
.item {
  position: relative;
}
.item a {
  text-decoration: none;
  font-family: var(--text-font-family);
  font-weight: 400;
}
.regColor a {
  color: rgba(1, 3, 2, 0.7);
}
.landingColor a {
  color: rgba(242, 241, 237, 0.7);
}
.regColor a:hover {
  color: #010302;
}
.active {
  color: #010302 !important;
}
@media (max-width: 998px) {
  .navItems {
    flex-direction: column;
    align-items: initial;
    padding: 0 0 0 16px;
  }
  .item {
    padding-bottom: 24px;
  }
}
