.resendContainer {
  max-width: 663px;
}
.header {
  font-size: 48px;
  line-height: 52px;
  color: var(--black);
  font-weight: 400;
  font-family: var(--title-font-family);
  width: 100%;
  padding-bottom: 16px;
}
.subTitle {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: var(--black);
  padding-bottom: 23px;
  display: flex;
  gap: 5px;
}
.resendBtn {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.resendBtn {
  font-family: var(--text-font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #000000;
  margin-bottom: 286px;
}
.loginLink {
  text-decoration: none;
}
.continueBtn {
  width: 325px;
  height: 68px;
  border-radius: 50px;
  font-size: 16px;
  line-height: 23px;
  display: block;
}
@media (max-width: 500px) {
  .header {
    font-size: 26px;
  }
  .subTitle {
    font-size: 16px;
    line-height: 26px;
  }
  .continueBtn {
    width: 100%;
    height: 56px;
    font-size: 12px;
  }
  .resendText,
  .resendBtn {
    font-size: 14px;
  }
}
