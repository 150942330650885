.UserManagementTableContainer{
    margin-top: 79px;
}
.radioContainer {
    margin-right: 36px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-right: 38px;
  }
  .radioContainer label {
    font-family: var(--title-font-family);
    font-size: 26px;
    line-height: 30px;
    margin-left: 10px;
  }
  .radioContainer input {
    cursor: pointer;
    width: 28px;
    height: 28px;
    z-index: 1;
    opacity: 0;
  }
  .radioContainer span {
    position: absolute;
    left: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid rgba(10, 10, 10, 0.39);
    background-color: transparent;
  }
  
  .checkMark {
    background-color: var(--black) !important;
  }
  .radioContainer input:checked ~ .checkMark {
    background-color: var(--black);
  }
  .headerWrapper{
      width: 100%;
      display: flex;
      justify-content: space-between;
  }
  .filtersContainer{
    width:100%;
    display:flex;
    justify-content: space-between;
  }
  .filtersWrapper{
      display: flex;
      margin: 45px 0;
  }
  .searchField{
    width: 100%;
    max-width: 523px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 56px;
    
  }
  .searchField input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding-left: 55px;
    font-size: 20px;
    font-family: var(--title-font-family);
    font-weight: 400;
    color: var(--black);
    border: 1.5px solid rgba(10, 10, 10, 0.25);
    border-radius: 200px;
  }
  .searchField input ::placeholder{
    font-size: 20px;
    font-family: var(--title-font-family);
    font-weight: 400;
    color: rgba(2, 2, 2, 0.4);
    }