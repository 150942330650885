.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 28px 0;
  border-bottom: 4px solid #f2f1ed;
  align-items: center;
}
.column {
  font-family: var(--text-font-family);
  font-size: 13.8712px;
  line-height: 16px;
  color: var(--black);
  width: 25%;
}
.name {
  font-family: var(--title-font-family);
  font-size: 28px;
  line-height: 46px;
  color: var(--black);
  width: 25%;
}
.name p{
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.status {
  text-transform: uppercase;
  text-align: center;
}
.viewButton {
  width: 167px;
  height: 42px;
  border: 1px solid #1977f3;
  border-radius: 10px;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #1977f3;
  cursor: pointer;
  background-color: transparent;
  margin-left: 22px;
}
.alignEnd {
  text-align: end;
  display: flex;
  justify-content: flex-end;
}
.buttonsWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.removeButton {
  width: 129px;
  height: 42px;
  background: #ee4655;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-family: var(--title-font-family);
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #f9f8f4;
  cursor: pointer;
  margin-left: 22px;
}
.ownerColumn {
  display: flex;
}
.avatar {
  width: 37px;
  height: 37px;
  margin-right: 9px;
}
.ownerTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ownerName {
  font-family: var(--title-font-family);
  font-size: 13.8712px;
  line-height: 16px;
  color: var(--black);
}
.ownerDesc {
  font-family: var(--title-font-family);
  font-size: 13.8712px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.64);
}
.tagsList {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
}
.categoryTag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  height: 21.17px;
  border: 0.75px solid var(--black);
  border-radius: 50px;
  font-family: var(--title-font-family);
  font-size: 9.2475px;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--black);
  margin: 4px 0;
}
.typeTag {
  background-color: var(--black);
  border-radius: 38.5312px;
  font-family: var(--title-font-family);
  font-size: 9.2475px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #faf9f5;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
}
.typeText {
  margin-left: 4px;
}
.approveButton {
  width: 129px;
  height: 42px;
  background-color: #1977f3;
  border-radius: 10px;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #f9f8f4;
  border: none;
  cursor: pointer;
}
.flexCell {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.threeColumnsCell {
  width:calc(100% / 3)
}