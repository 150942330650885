.editorPicksSection {
  width: 100%;
  background-color: #f9f8f4;
  border-radius: 0px 0px 200.6px 0px;
  padding: 70px 0 113px;
}
.editorPicksContainer {
  width: 100%;
  max-width: 1040px;
  margin: auto;
  padding: 0 31px;
}
.sectionTitle {
  font-family: var(--title-font-family);
  font-size: 36.9px;
  color: var(--black);
  line-height: 55px;
  font-weight: 400;
}
.picks {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 53px;
}
.pick {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  max-width: 259.64px;
}
.onBoarding {
  z-index: 11;
  pointer-events: none;
}
.product,
.business,
.price {
  font-family: var(--text-font-family);
  font-weight: 400;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
}

.pickImg {
  width: 201px;
  height: 262px;
  object-fit: cover;
}
.business {
  color: #000000;
  font-size: 12.3px;
  line-height: 15px;
  text-transform: uppercase;
  font-family: var(--title-font-family) !important;
  padding-top: 20px;
}
.product {
  padding: 8px 0 5px;
  font-size: 15.5px;
  line-height: 21px;
  text-transform: capitalize;
  color: var(--black);
}
.price {
  color: var(--black);
  font-size: 15.3px;
  line-height: 19px;
}
.imgContainer {
  position: relative;
}
.upVote {
  width: 37.67px;
  height: 37.67px;
  border-radius: 56px;
  backdrop-filter: blur(4px);
  border: 1px solid #deddd9 !important;
  position: absolute;
  right: -7.69px;
  bottom: -6.92px;
}
.addToCollection {
  width: 21.52px;
  height: 21.52px;
  backdrop-filter: blur(1.64706px);
  border: 1px solid #deddd9 !important;
  border-radius: 20.5882px;
  border: 1px solid #deddd9;
  position: absolute;
  right: -7.69px;
  bottom: 36.9px;
}
.loader {
  width: 100px !important;
  height: 100px !important;
  margin: 100px auto;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 51px;
}
