.middleContainer {
  width: 100%;
  background-color: var(--lighter-gray-color);
}
.content {
  max-width: 1440px;
  margin: auto;
  padding: 0 3px 152px 69px;
  display: flex;
  justify-content: space-between;
}
.leftSide {
  display: flex;
  column-gap: 60px;
}
@media (max-width: 1100px) {
  .content {
    padding: 0 3px 152px 31px;
  }
}
@media (max-width: 720px) {
  .content {
    flex-direction: column-reverse;
    padding: 0 0 152px 0;
  }
}
