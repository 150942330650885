.btn {
  outline: none;
  font-weight: 400;
  font-family: var(--text-font-family);
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}
.btn:disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
}
.regularBg {
  background-color: var(--black);
  border: none;
}
.loadingBg {
  border: 1px solid var(--black);
  background-color: var(--lighter-gray-color);
  pointer-events: none;
}
