.adminLayout {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.header {
  height: 110px;
}
.adminContainer {
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
}
.mainContent {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #f9f8f4;
}
@media (max-width: 1000px) {
  .adminLayout {
    display: table;
  }
}
