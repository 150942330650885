.businessTooltip,
.itemTooltip {
  position: absolute;
  border-radius: 20px !important;
}
.itemTooltip {
  top: 250px;
  right: -390px;
}
.businessTooltip {
  top: 380px;
  right: -370px;
}
.itemTooltip::before,
.businessTooltip::before {
  top: 100px;
  left: -20px;
}
.inpContainer {
  width: 291px;
}
.inpContainer label {
  font-family: var(--title-font-family);
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: rgba(10, 10, 10, 0.4);
  padding-bottom: 4px;
  display: block;
}
.inpContainer input {
  width: 100%;
  height: 46px;
  outline: none;
  border: 1px solid #000000;
  border-radius: 4px;
  padding-left: 5px;
}
