.menu {
  background: #f9f8f4;
  border: 1px solid #f2f1ed;
  box-shadow: 6px 6px 16px rgba(10, 10, 10, 0.1);
  border-radius: 24px;
  padding: 32px;
  position: absolute;
  list-style: none;
  z-index: 7;
  text-align: left;
}
.item a,
.item {
  text-decoration: none;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: rgba(1, 3, 2, 0.7);
  font-family: var(--text-font-family);
  font-weight: 400;
  cursor: pointer;
}
.business {
  width: 177px;
}
.consumer {
  width: 135px;
}
.item:not(:last-of-type) {
  padding-bottom: 20px;
}
.item:hover,
.item:hover a {
  color: #010302;
}

.admin{
  width: 170px;
}