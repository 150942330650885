.signUpAd {
  width: 100%;
  height: 600px;
  overflow: hidden;
  background-color: #f2f1ed;
}
.container {
  width: 100%;
  max-width: 1440px;
  height: 600px;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
}
.text {
  width: 479px;
  font-size: 61.5px;
  line-height: 62px;
  font-family: var(--title-font-family);
  color: #020202;
  font-weight: 400;
}
.adImg {
  width: 426px;
  height: 536px;
}
.learn {
  width: 108px;
  height: 108px;
  background-color: var(--black);
  color: #f9f8f4;
  text-decoration: none;
  font-size: 14px;
  z-index: 2;
  border-radius: 75.8621px 49.3103px 83.4483px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.overFlowContainer {
  display: none;
}
