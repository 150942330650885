.categoriesContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-height: 420px;
  margin-top: 40px;
  margin-bottom: 70px;
  overflow-y: auto;
  gap: 20px;
}

.categoriesContainer input {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.categoryPill {
  position: relative;
  height: 58px;
  width: 205px;
  border: 0.984594px solid var(--black);
  box-sizing: border-box;
  border-radius: 10.5023px;
  color: var(--text);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
}
.loader {
  height: 58px;
  width: 205px;
  border-radius: 10.5023px;
}
.categoryPill > span:last-of-type {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 15px;
}

.categoryPillChecked {
  color: white;
  background-color: var(--black);
}
