.supportPage {
  width: 100%;
  background-color: #f9f8f4;
}
.supportForm {
  margin-left: 10px;
  min-width: 900px;
  padding: 63px 0 60px 63px;
  max-width: 1440px;
  margin: auto;
}
.inpContainer {
  margin-bottom: 43px;
  width: 662px;
}
.header {
  font-family: var(--title-font-family);
  font-size: 48px;
  line-height: 52px;
  color: var(--black);
  padding-bottom: 43px;
  display: block;
}
.inpContainer textarea {
  display: block;
  width: 100%;
  height: 245px;
  background-color: transparent;
  resize: none;
  outline: none;
}
.sendBtn {
  width: 235px;
  height: 68px;
  border-radius: 50px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: var(--title-font-family);
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
}
.regularBg {
  background-color: var(--black);
  border: none;
}
.loadingBg {
  border: 1px solid var(--black);
  background-color: var(--lighter-gray-color);
}

.sendBtn:disabled {
  background-color: rgba(10, 10, 10, 0.2);
  color: #f9f8f4;
  cursor: not-allowed;
}
.successMessage {
  padding: 63px 0 0 63px;
  font-size: 32px;
  line-height: 45px;
  font-family: var(--title-font-family);
  font-weight: 400;
  color: var(--black);
  max-width: 800px;
}
.halfWidthContainer label,
.fullWidthContainer label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: var(--text-font-family);
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(1, 3, 2, 0.7);
}
.halfWidthContainer {
  width: 662px;
}

.halfWidthContainer input,
.fullWidthContainer input,
.halfWidthContainer select,
.fullWidthContainer select {
  width: 100%;
  height: 56px;
  border: 1.5px solid #0a0a0a;
  border-radius: 10px;
  outline: none;
  padding-left: 24px;
  margin: 16px 0 24px;
  font-size: 20px;
  line-height: 28px;
  color: rgba(2, 2, 2, 0.4);
  font-family: var(--title-font-family);
  padding-bottom: 4px;
}
