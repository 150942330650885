.emailInp {
  max-width: 663px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header {
  font-size: 48px;
  line-height: 52px;
  color: var(--black);
  font-weight: 400;
  font-family: var(--title-font-family);
  width: 100%;
  padding-bottom: 16px;
}
.subTitle {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 38px;
  line-height: 42px;
  color: var(--black);
  padding-bottom: 48px;
}
.inpContainer label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  font-family: var(--text-font-family);
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(1, 3, 2, 0.7);
}
.inpContainer {
  width: 100%;
}
.inpContainer input {
  width: 100%;
  height: 56px;
  border: 1.5px solid #0a0a0a;
  border-radius: 10px;
  outline: none;
  padding-left: 24px;
  margin: 16px 0 24px;
  font-size: 20px;
  line-height: 28px;
  color: rgba(2, 2, 2, 0.4);
  font-family: var(--title-font-family);
  padding-bottom: 4px;
}
.continueBtn {
  width: 325px;
  height: 68px;
  border-radius: 50px;
  font-size: 16px;
  line-height: 23px;
}
.invalid label {
  color: #ee4655;
}
.invalid input {
  margin-bottom: 8px;
  border: 1.5px solid #ee4655;
  color: #ee4655;
}
.invalid input::placeholder {
  color: #ee4655;
}
@media (max-width: 400px) {
  .header {
    font-size: 36px;
  }
  .subTitle {
    font-size: 20px;
    line-height: 26px;
  }
  .continueBtn {
    width: 100%;
    height: 56px;
    font-size: 12px;
  }
}
