.item {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  max-width: 262px;
}

.product,
.business,
.price {
  font-family: var(--text-font-family);
  font-weight: 400;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
}

.itemImg {
  width: 262px;
  height: 341px;
  object-fit: cover;
}
.item1,
.item2,
.item3,
.item4 {
  width: 262px;
  height: 341px;
}
.business {
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  padding-top: 20px;
}
.product {
  padding: 8px 0;
  font-size: 20px;
  line-height: 27px;
  color: var(--black);
  text-transform: capitalize;
}
.price {
  color: var(--black);
  font-size: 20px;
  line-height: 21px;
}
.imgContainer {
  position: relative;
}
.upVote {
  width: 49px;
  height: 49px;
  border-radius: 56px;
  backdrop-filter: blur(4px);
  border: 1px solid #deddd9 !important;
  position: absolute;
  right: -10px;
  bottom: -9px;
}
.addToCollection {
  width: 28px;
  height: 28px;
  backdrop-filter: blur(1.64706px);
  border-radius: 20.5882px;
  border: 1px solid #deddd9;
  position: absolute;
  right: -10px;
  bottom: 48px;
}
