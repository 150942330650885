.name {
  font-size: 37px;
  line-height: 51px;
  color: #f2f1ed;
  font-weight: 300;
  margin-bottom: 8px;
}
.judgeTitle {
  display: block;
}
.judgeTitle,
.link {
  font-size: 20px;
  line-height: 27px;
  color: rgba(242, 241, 237, 0.7);
  font-weight: 300;
}
.aboutJudge {
  margin-bottom: 80vh;
}
@media (max-width: 980px) {
  .name {
    font-size: 28px;
    line-height: 41px;
  }
  .judgeTitle,
  .link {
    font-size: 16px;
    line-height: 19px;
  }
  .aboutContainer {
    max-width: 216px;
  }
}
@media (max-width: 630px) {
  .name {
    font-size: 16px;
    line-height: 26px;
  }
  .judgeTitle,
  .link {
    font-size: 11px;
    line-height: 12px;
  }
  .aboutContainer {
    max-width: 186px;
  }
}
