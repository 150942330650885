.paymentMethodForm{
    width: 100%;
    flex-grow: 1;
}
.flexWrapper{
    display: flex;
    width: 100%;
    max-width: 662px;
}
.field{
    padding:32px 0 44px 0;
    border-bottom:4px solid  #F2F1ED;   
    max-width:662px ; 
    width: 100%;
}
.elementStyle{
    height: 56px; 
    border: 1.5px solid #0A0A0A;
    border-radius: 10px;
    padding:13px 24px;
    font-size: 20px;
    line-height: 28px;  
    width: 100%;
    max-width:662px ;  
}

.label{
    font-family: var(--text-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.06em;
    color: rgba(10, 10, 10, 0.4);
    margin-bottom: 20px;
}
.smallWidthElement{
    max-width: 172px;
}
.buttonsWrapper{
    margin: 46px 0;
}
.error{
    color:red;
    font-size: 16px;
    font-family: var(--title-font-family);
    margin-top: 32px;
  }