.page {
    margin-left: 10px;
    background-color: #f9f8f4;
    width: 90%;
    min-width: 900px;
    padding: 63px 0 0 63px;
  }
.title {
    font-weight: 400;
    font-family: var(--title-font-family);
    font-size: 48px;
    line-height: 52px;
    padding-bottom: 3%;
} 

.paragraph {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    width: 568px;
    font-family: 'Arial';
}
.formContainer {
    width: 50%;
    margin-top: 2%;
}
.subscription {
    border: 1px solid #0A0A0A;
    border-radius: 10px;
    background-color: transparent;
    color: #000000;
    width: 268px;
    padding: 3%;
    left: 386px;
    top: 854px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    font-family: var(--title-font-family);
    text-transform: uppercase;
    margin-top: 4%;
    text-decoration: none;
    margin-bottom: 4%;
}
.inpContainer {
    width: 100%;
  }
  .inpContainer label {
    font-family: var(--title-font-family);
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: rgba(10, 10, 10, 0.9);
    padding: 20px 0 20px 0;
    display: block;
  }
  .inpContainer input {
    width: 100%;
    height: 46px;
    outline: none;
    border: 1px solid #000000;
    border-radius: 4px;
    padding-left: 5px;
  }
  

  .apply {
    border-radius: 50px;
    outline: none;
    width: 200px;
    height: 48px;
    font-family: var(--title-font-family);
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #faf9f5;
    cursor: pointer;
    display: block;
    margin: 10px 10px 10px 10px;
  }
  .CloseAccountBtn {
    border-radius: 50px;
    outline: none;
    width: 200px;
    height: 48px;
    font-family: var(--title-font-family);
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #EE4655;
    background-color: #faf9f5;
    border: #EE4655;
    cursor: pointer;
    display: block;
    margin: 10px 10px 10px 10px;
      
  }
  .apply:disabled {
    background-color: rgba(10, 10, 10, 0.2);
    color: #f9f8f4;
    cursor: not-allowed;
  }
  .regularBg {
    background-color: var(--black);
    border: none;
  }
  .regularCloseBg {
    background-color: transparent;
    border:3px solid #EE4655;
  }
  .loadingBg {
    border: 1px solid var(--black);
    background-color: var(--lighter-gray-color);
  }
  .loader {
    width: 25px;
    height: 25px;
  }

  .horizontalLine {
    background-color: #E5E5E5;
    width: 170%;
    border: none;
    margin-top: 6%;
    margin-bottom: 6%;
    height: 5px;
  }

  .btnContainer {
      display: flex;
  }
  