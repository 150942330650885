.criteriaSection {
  max-width: 1312px;
  min-height: 898px;
  position: relative;
  margin: 152px auto;
}
.title {
  font-family: var(--text-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(242, 241, 237, 0.7);
  margin-left: 775px;
}
.criteriaSection p,
.criteriaSection span {
  font-family: var(--title-font-family);
  font-style: normal;
  font-weight: 300;
  color: #f2f1ed;
}
.text {
  display: block;
  font-size: 80px;
  line-height: 80px;
}
.firstContainer,
.secondContainer,
.thirdContainer,
.fourthContainer,
.fifthContainer {
  position: absolute;
}
.firstContainer {
  right: 0;
  top: 43px;
}
.secondContainer {
  left: 0;
  top: 120px;
}
.thirdContainer {
  right: 0;
  top: 361px;
}
.fourthContainer {
  right: 0;
  bottom: 80px;
}
.fifthContainer {
  left: 0;
  bottom: 0;
}
.first,
.second,
.third,
.fourth,
.fifth {
  border: 1px solid #f2f1ed;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.first {
  width: 537px;
  height: 302px;
  border-radius: 0px 150px 0px 0px;
}
.second {
  position: relative;
  width: 759px;
  height: 371px;
  border-radius: 0px 112.5px 0px 150px;
  clip-path: polygon(0 0, 100% 0, 100% 226px, 538px 226px, 538px 100%, 0 100%);
}
.second::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #f2f1ed;
  height: 143px;
  width: 219px;
  z-index: 2;
}

.third {
  width: 759px;
  height: 240px;
  border-radius: 0px 0px 120px 0px;
}
.fourth {
  width: 648px;
  height: 201px;
  border-radius: 150px 0px 0px 0px;
}
.fifth {
  width: 648px;
  height: 390px;
  border-radius: 100.5px 0px 0px 0px;
  position: relative;
  clip-path: polygon(0 0, 537px 0, 537px 110px, 100% 110px, 100% 100%, 0 100%);
}
.fifth::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #f2f1ed;
  height: 108px;
  width: 109px;
  z-index: 2;
}

.upside {
  position: absolute;
  top: 16px;
  left: 24px;
}
.downside {
  position: absolute;
  bottom: 16px;
  left: 24px;
}
.order {
  font-size: 48px;
  line-height: 56px;
}
.register {
  font-family: var(--text-font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: rgba(242, 241, 237, 0.7);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 16px;
}
@media (max-width: 1340px) {
  .criteriaSection {
    max-width: 680px;
    min-height: 472px;
  }
  .text {
    display: block;
    font-size: 30px;
    line-height: 30px;
  }
  .order {
    font-size: 18px;
    line-height: 26px;
  }
  .register {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
  }

  .upside {
    top: 10px;
    left: 18px;
  }
  .downside {
    bottom: 10px;
    left: 18px;
  }
  .first {
    width: 308px;
    height: 140px;
    border-radius: 0px 70px 0px 0px;
  }
  .second {
    width: 359px;
    height: 191px;
    border-radius: 0px 70px 0px 70px;
    clip-path: polygon(
      0 0,
      100% 0,
      100% 104px,
      280px 104px,
      280px 100%,
      0 100%
    );
  }
  .second::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid #f2f1ed;
    height: 85px;
    width: 77px;
    z-index: 2;
  }
  .secondContainer {
    left: 0;
    top: 78px;
  }

  .third {
    width: 389px;
    height: 120px;
    border-radius: 0px 0px 70px 0px;
  }
  .thirdContainer {
    right: 0;
    top: 194px;
  }

  .fourth {
    width: 334px;
    height: 120px;
    border-radius: 70px 0px 0px 0px;
  }
  .fourthContainer {
    right: 0;
    bottom: 27px;
  }

  .fifth {
    width: 334px;
    padding-left: 15px;
    height: 190px;
    border-radius: 70px 0px 0px 0px;
    position: relative;
    clip-path: polygon(0 0, 280px 0, 280px 42px, 100% 42px, 100% 100%, 0 100%);
  }
  .fifth::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #f2f1ed;
    height: 40px;
    width: 52px;
    z-index: 2;
  }
  .title {
    margin-left: 375px;
  }
}
@media (max-width: 700px) {
  .criteriaSection {
    max-width: 360px;
    min-height: 278px;
  }
  .text {
    display: block;
    font-size: 16px;
    line-height: 16px;
  }
  .order {
    font-size: 10px;
    line-height: 16px;
  }
  .register {
    font-size: 10px;
    line-height: 14px;
    margin-top: 6px;
  }

  .first {
    width: 158px;
    height: 80px;
    border-radius: 0px 30px 0px 0px;
  }
  .firstContainer {
    right: 0;
    top: 23px;
  }

  .second {
    width: 199px;
    height: 101px;
    border-radius: 0px 30px 0px 30px;
    clip-path: polygon(0 0, 100% 0, 100% 56px, 160px 56px, 160px 100%, 0 100%);
  }
  .second::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid #f2f1ed;
    height: 43px;
    width: 37px;
    z-index: 2;
  }
  .secondContainer {
    left: 0;
    top: 48px;
  }

  .third {
    width: 194px;
    height: 80px;
    border-radius: 0px 0px 30px 0px;
  }
  .thirdContainer {
    right: 0;
    top: 110px;
  }

  .fourth {
    width: 158px;
    height: 80px;
    border-radius: 30px 0px 0px 0px;
  }
  .fourthContainer {
    right: 0;
    bottom: 0px;
  }

  .fifth {
    width: 197px;
    padding-left: 15px;
    height: 123px;
    border-radius: 30px 0px 0px 0px;
    position: relative;
    clip-path: polygon(0 0, 160px 0, 160px 42px, 100% 42px, 100% 100%, 0 100%);
  }
  .fifth::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #f2f1ed;
    height: 40px;
    width: 35px;
    z-index: 2;
  }
  .title {
    margin-left: 213px;
    font-size: 10px;
    line-height: 16px;
  }
}
